import React, { useState, useContext, useEffect } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

//functions
import { getCookie } from "../functions/Functions";

import { UserContext } from "./User";

//3rd party packages

// creating context api
const RestaurantContext = React.createContext();


const RestaurantProvider = ({ children }) => {
  // State hooks  here
  //loading
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataPaginating, setDataPaginating] = useState(false);

  //website
  const [branchListWeb, setBranchListWeb] = useState(null);

  //delivery user
  const [pendingOrdersDelivery, setPendingOrdersDelivery] = useState(null);
  const [pendingOrdersDeliveryForSearch, setPendingOrdersDeliveryForSearch] =
    useState(null);
  const [deliveredOrdersDelivery, setDeliveredOrdersDelivery] = useState(null);
  const [
    deliveredOrdersDeliveryForSearch,
    setDeliveredOrdersDeliveryForSearch,
  ] = useState(null);

  //ingredientGroup
  const [ingredientGroupList, setIngredientGroupList] = useState(null);
  const [ingredientGroupForSearch, setIngredientGroupForSearch] =
    useState(null);

  //ingredientItem
  const [ingredientItemList, setIngredientItemList] = useState(null);
  const [ingredientItemForSearch, setIngredientItemForSearch] = useState(null);
  const [ingredientItemStock, setIngredientItemStock] = useState(null);
  const [ingredientPurchaseHistory, setIngredientPurchaseHistory] =
    useState(null);
  const [
    ingredientPurchaseHistoryForSearch,
    setIngredientPurchaseHistoryForSearch,
  ] = useState(null);

  //branch
  const [branchList, setBranchList] = useState(null);
  const [branchForSearch, setBranchforSearch] = useState(null);

  //table
  const [tableList, setTableList] = useState(null);
  const [tableForSearch, setTableforSearch] = useState(null);

  //table
  const [deptTagList, setDeptTagList] = useState(null);
  const [deptTagForSearch, setDeptTagForSearch] = useState(null);

  //Payment Type
  const [paymentTypeList, setPaymentTypeList] = useState(null);
  const [paymentTypeForSearch, setPaymentTypeforSearch] = useState(null);

  //work period
  const [workPeriodList, setWorkPeriodList] = useState(null);
  const [workPeriodForSearch, setWorkPeriodListForSearch] = useState(null);

  //table sections
  const [tableSectionList, setTableSectionList] = useState(null);
  const [tableSectionForSearch, setTableSectionListForSearch] = useState(null);

  //booking slots
  const [bookingSlotList, setBookingSlotList] = useState(null);
  const [bookingSlotForSearch, setBookingSlotListForSearch] = useState(null);

  //reservationlist 
  const [reservationList, setReservationList] = useState(null);
  const [reservationListForSearch, setReservationListForSearch] = useState(null);

  //submitted orders
  const [submittedOrders, setSubmittedOrders] = useState(null);
  const [submittedOrdersForSearch, setSubmittedOrdersForSearch] =
    useState(null);

  //settled orders
  const [settledOrders, setSettledOrders] = useState(null);
  const [settledOrdersForSearch, setSettledOrdersForSearch] = useState(null);

  //kitchen new orders
  const [kithcenNewOrders, setKithcenNewOrders] = useState(null);
  const [kithcenNewOrdersOnline, setKithcenNewOrdersOnline] = useState(null);

  //all orders
  const [allOrders, setAllOrders] = useState(null);
  const [allOrdersForSearch, setAllOrdersForSearch] = useState(null);

  //all orders
  const [allOnlineOrders, setAllOnlineOrders] = useState(null);
  const [allOnlineOrdersForSearch, setAllOnlineOrdersForSearch] =
    useState(null);

  const { authUserInfo } = useContext(UserContext);

  //useEffect- to get data on render
  useEffect(() => {
    //call- unauthenticated
    getBranchWeb();
    //call if authenticated
    if (getCookie() !== undefined) {
      getBranch();
      getTable();
      getDeptTag();
      getPaymentType();
      getWorkPeriod();
      getBookingSlots();
      getTableSections();
      getReservations();
      getDeliveryPendingOrders();
      getDeliveryDeliveredOrders();
    }
  }, []);

  //get deliveryman pending orders
  const getDeliveryPendingOrders = async () => {
    setLoading(true);
    const ordersUrl = BASE_URL + "/settings/get-assigned-orders";
    const res = await axios
      .get(ordersUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setPendingOrdersDelivery(res.data[0]);
    setPendingOrdersDeliveryForSearch(res.data[1]);
    setLoading(false);
  };

  // get paginated deliveryman pending orders
  const setPaginatedDeliveryPendingOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-assigned-orders?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setPendingOrdersDelivery(res.data[0]);
      setPendingOrdersDeliveryForSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  //get deliveryman delivered orders
  const getDeliveryDeliveredOrders = async () => {
    setLoading(true);
    const ordersUrl = BASE_URL + "/settings/get-delivered-orders";
    const res = await axios
      .get(ordersUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setDeliveredOrdersDelivery(res.data[0]);
    setDeliveredOrdersDeliveryForSearch(res.data[1]);
    setLoading(false);
  };

  // get paginated deliveryman delivered orders
  const setPaginatedDeliveryDeliveredOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-delivered-orders?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setDeliveredOrdersDelivery(res.data[0]);
      setDeliveredOrdersDeliveryForSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  //get ingredient group
  const getIngredientGroup = async () => {
    setLoading(true);
    const supplierUrl = BASE_URL + "/settings/get-ingredient_group";
    const res = await axios
      .get(supplierUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setIngredientGroupList(res.data[0]);
    setIngredientGroupForSearch(res.data[1]);
    setLoading(false);
  };

  // get paginated ingredient group
  const setPaginatedIngredientGroup = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-ingredient_group?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setIngredientGroupList(res.data[0]);
      setIngredientGroupForSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  //get ingredient item
  const getIngredientItem = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-ingredient_item";
    const res = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setIngredientItemList(res.data[0]);
    setIngredientItemForSearch(res.data[1]);
    setIngredientItemStock(res.data[2]);
    setLoading(false);
  };

  // get paginated ingredient item
  const setPaginatedIngredientItem = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-ingredient_item?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setIngredientItemList(res.data[0]);
      setIngredientItemForSearch(res.data[1]);
      setIngredientItemStock(res.data[2]);
      setDataPaginating(false);
    } catch { }
  };

  //get branchWeb
  const getBranchWeb = async () => {
    setLoading(true);
    const branchUrl = BASE_URL + "/website/get-branch-web";
    const res = await axios.get(branchUrl);
    setBranchListWeb(res.data);
    setLoading(false);
  };

  //get branch
  const getBranch = async () => {
    setLoading(true);
    const branchUrl = BASE_URL + "/settings/get-branch";
    const res = await axios
      .get(branchUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setBranchList(res.data[0]);
    setBranchforSearch(res.data[1]);
    setLoading(false);
  };

  // get paginated branch
  const setPaginatedBranch = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-branch?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setBranchList(res.data[0]);
      setBranchforSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  //get tables
  const getTable = async () => {
    setDataLoading(true);
    const branchUrl = BASE_URL + "/settings/get-table";
    console.log('table api');
    console.log(branchUrl);
    const res = await axios
      .get(branchUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      if(res != null) {
        setTableList(res.data[0]);
        setTableforSearch(res.data[1]);
        setDataLoading(false);
      }    
  };

  // get paginated tables
  const setPaginatedTable = async (pageNo) => {
    setDataPaginating(true); 
    const url = BASE_URL + "/settings/get-table?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setTableList(res.data[0]);
      setTableforSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  //get payment types
  const getPaymentType = async () => {
    setLoading(true);
    const paymentTypeUrl = BASE_URL + "/settings/get-payment-type";
    const res = await axios
      .get(paymentTypeUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setPaymentTypeList(res.data[0]);
    setPaymentTypeforSearch(res.data[1]);
    setLoading(false);
  };

  // get paginated payment types
  const setPaginatedPaymentType = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-payment-type?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setPaymentTypeList(res.data[0]);
      setPaymentTypeforSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  //get dept Tag
  const getDeptTag = async () => {
    setLoading(true);
    const deptTagUrl = BASE_URL + "/settings/get-dept-tag";
    const res = await axios
      .get(deptTagUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setDeptTagList(res.data[0]);
    setDeptTagForSearch(res.data[1]);
    setLoading(false);
  };

  // get paginated dept Tag
  const setPaginatedDeptTag = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-dept-tag?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setDeptTagList(res.data[0]);
      setDeptTagForSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  //get work period
  const getWorkPeriod = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-work-period";
    const res = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setWorkPeriodList(res.data[0]);
    setWorkPeriodListForSearch(res.data[1]);
    setLoading(false);
  };

  //get table sections
  const getTableSections = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/all-table_section";
    const res = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setTableSectionList(res.data[0]);
    setTableSectionListForSearch(res.data);
    setLoading(false);
  };

  //get booking slots
  const getBookingSlots = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-slots";
    const res = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setBookingSlotList(res.data[0]);
    setBookingSlotListForSearch(res.data[1]);
    setLoading(false);
  };

  //get booking slots
  const getReservations = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-reservation-pos";
    const res = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    console.log('reservation list api');
    console.log(res);
    setReservationList(res.data[0]);
    setReservationListForSearch(res.data[1]);
    setLoading(false);
  };

  // get paginated work period
  const setPaginatedWorkPeriod = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-work-period?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setWorkPeriodList(res.data[0]);
      setWorkPeriodListForSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  // get paginated table sections
  const setPaginatedTableSections = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/all-table_section?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setTableSectionList(res.data[0]);
      setTableSectionListForSearch(res.data);
      setDataPaginating(false);
    } catch { }
  };

  // get paginated booking slot
  const setPaginatedBookingSlot = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-slots?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setBookingSlotList(res.data[0]);
      setBookingSlotListForSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  // get paginated reservation
  const setPaginatedReservations = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-reservation-pos?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setReservationList(res.data[0]);
      setReservationListForSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  //get submitted orders- not settled
  const getSubmittedOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-submitted-orders";
    const res = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setSubmittedOrders(res.data[0]);
    setSubmittedOrdersForSearch(res.data[1]);
    setLoading(false);
  };

  // get paginated submitted orders- not settled
  const setPaginatedSubmittedOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-submitted-orders?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setSubmittedOrders(res.data[0]);
      setSubmittedOrdersForSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  //get settled orders
  const getSettledOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-settled-orders";
    const res = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setSettledOrders(res.data[0]);
    setSettledOrdersForSearch(res.data[1]);
    setLoading(false);
  };

  // get paginated settled orders
  const setPaginatedSettledOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-settled-orders?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      console.log('ordersss list');
      console.log(url);
      console.log(getCookie());
      setSettledOrders(res.data[0]);
      setSettledOrdersForSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  //get kithcen new orders
  const getKitchenNewOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-new-orders";
    const res = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setKithcenNewOrders(res.data[0]);
    setLoading(false);
  };

  // kitchen online orders
  const getKitchenNewOrdersOnline = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-new-orders-online";
    const res = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setKithcenNewOrdersOnline(res.data[0]);
    setLoading(false);
  };

  //get all orders for order history
  const getAllOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-order-history-all";
    const res = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setAllOrdersForSearch(res.data);
    setLoading(false);
  };

  // get paginated settled orders
  const setPaginatedAllOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-order-history?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setAllOrders(res.data);
      setDataPaginating(false);
      setLoading(false);
    } catch { }
  };

  //get all  online orders for order history
  const getAllOnlineOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-online-order-history-all";
    const res = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
    setAllOnlineOrdersForSearch(res.data);
    setLoading(false);
  };

  // get paginated online  orders
  const setPaginatedAllOnlineOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-online-order-history?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setAllOnlineOrders(res.data);
      setDataPaginating(false);
      setLoading(false);
    } catch { }
  };

  //get food purchases
  const getIngredientPurchase = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-ingredient_purchase";
    const res = await axios.get(url);
    setIngredientPurchaseHistory(res.data[0]);
    setIngredientPurchaseHistoryForSearch(res.data[1]);
    setLoading(false);
  };

  // get paginated purchases
  const setPaginatedIngredientPurchase = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-ingredient_purchase?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setIngredientPurchaseHistory(res.data[0]);
      setIngredientPurchaseHistoryForSearch(res.data[1]);
      setDataPaginating(false);
    } catch { }
  };

  return (
    <RestaurantContext.Provider
      value={{
        //common
        dataLoading,
        loading,
        setLoading,
        //delvery pending
        getDeliveryPendingOrders,
        pendingOrdersDelivery,
        setPendingOrdersDelivery,
        pendingOrdersDeliveryForSearch,
        setPendingOrdersDeliveryForSearch,
        setPaginatedDeliveryPendingOrders,

        //delivery delivered
        getDeliveryDeliveredOrders,
        deliveredOrdersDelivery,
        setDeliveredOrdersDelivery,
        deliveredOrdersDeliveryForSearch,
        setDeliveredOrdersDeliveryForSearch,
        setPaginatedDeliveryDeliveredOrders,

        //ingredient group
        getIngredientGroup,
        ingredientGroupList,
        setIngredientGroupList,
        setPaginatedIngredientGroup,
        ingredientGroupForSearch,
        setIngredientGroupForSearch,

        //ingredient item
        getIngredientItem,
        ingredientItemList,
        setIngredientItemList,
        setPaginatedIngredientItem,
        ingredientItemForSearch,
        setIngredientItemForSearch,
        ingredientItemStock,
        setIngredientItemStock,

        //ingredient purchases
        getIngredientPurchase,
        ingredientPurchaseHistory,
        setIngredientPurchaseHistory,
        setPaginatedIngredientPurchase,
        ingredientPurchaseHistoryForSearch,
        setIngredientPurchaseHistoryForSearch,

        //website
        getBranchWeb,
        branchListWeb,
        setBranchListWeb,
        //branch
        getBranch,
        branchList,
        setBranchList,
        setPaginatedBranch,
        branchForSearch,
        setBranchforSearch,

        //table
        getTable,
        tableList,
        setTableList,
        setPaginatedTable,
        tableForSearch,
        setTableforSearch,

        //dept-tag
        getDeptTag,
        deptTagList,
        setDeptTagList,
        setPaginatedDeptTag,
        deptTagForSearch,
        setDeptTagForSearch,

        //payment types
        getPaymentType,
        paymentTypeList,
        setPaymentTypeList,
        setPaginatedPaymentType,
        paymentTypeForSearch,
        setPaymentTypeforSearch,

        //work period
        getWorkPeriod,
        workPeriodList,
        setWorkPeriodList,
        setPaginatedWorkPeriod,
        workPeriodForSearch,
        setWorkPeriodListForSearch,

        // table sections
        getTableSections,
        tableSectionList,
        setTableSectionList,
        setPaginatedTableSections,
        tableSectionForSearch,
        setTableSectionListForSearch,

        // booking slots
        getBookingSlots,
        bookingSlotList,
        setBookingSlotList,
        setPaginatedBookingSlot,
        bookingSlotForSearch,
        setBookingSlotListForSearch,

        // reservations
        getReservations,
        reservationList,
        setReservationList,
        setPaginatedReservations,
        reservationListForSearch,
        setReservationListForSearch,

        //submitted orders
        getSubmittedOrders,
        submittedOrders,
        setSubmittedOrders,
        setPaginatedSubmittedOrders,
        submittedOrdersForSearch,
        setSubmittedOrdersForSearch,

        //settled orders
        getSettledOrders,
        settledOrders,
        setSettledOrders,
        setPaginatedSettledOrders,
        settledOrdersForSearch,
        setSettledOrdersForSearch,

        //kitchen dashboard
        getKitchenNewOrders,
        kithcenNewOrders,
        setKithcenNewOrders,
        getKitchenNewOrdersOnline,
        kithcenNewOrdersOnline,
        setKithcenNewOrdersOnline,

        //order histories
        getAllOrders,
        allOrders,
        setAllOrders,
        setPaginatedAllOrders,
        allOrdersForSearch,
        setAllOrdersForSearch,

        //onlineOrder histories
        getAllOnlineOrders,
        allOnlineOrders,
        setAllOnlineOrders,
        setPaginatedAllOnlineOrders,
        allOnlineOrdersForSearch,
        setAllOnlineOrdersForSearch,

        //pagination
        dataPaginating,
        setDataPaginating,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
};

export { RestaurantContext, RestaurantProvider };
