import { BASE_URL } from "../../../../BaseUrl";
import { getCookie } from "../../../../functions/Functions";
import axios from "axios";


export const NEW_CATEGORY_URL = `${BASE_URL}/settings/new-category`;
export const GET_CATEGORY_URL = `${BASE_URL}/settings/get-category`;
export const UPDATE_CATEGORY_URL = `${BASE_URL}/settings/update-category`;
export const DELETE_CATEGORY_URL = `${BASE_URL}/settings/delete-category`;


export function saveCategories(obj) {
    return axios.post(NEW_CATEGORY_URL, obj, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}

export function getCategories() {
    return axios.get(GET_CATEGORY_URL, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}

export function updateCategories(obj) {
    return axios.post(UPDATE_CATEGORY_URL, obj, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}

export function deleteCategories(obj) {
    return axios.get(DELETE_CATEGORY_URL+'/'+obj, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}