import React, { useEffect, useState, useContext } from "react";
import { BASE_URL } from "../../../../../BaseUrl";
import { _t, getCookie } from "../../../../../functions/Functions";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import Stopwatch from "./stopwatch/Stopwatch";
import {
  Box,
  Button,
  Typography,
  Modal,
  textFieldClasses,
} from "@mui/material";
import { Link } from "react-router-dom";
import ReactStopwatch from "react-stopwatch";
import { ReservationContext } from "../../../../../contexts/Reservation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
};

const AssignTable = () => {
  // getting context value
  const {
    todayList,
    upcommingList,
    settodayList,
    setupcommingList,
    loading,
    setLoading,
    allTables,
    setallTables,
    getAllTables,
  } = useContext(ReservationContext);

  // translation
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [showAssign, setshowAssign] = useState();
  const [showNotify, setshowNotify] = useState(false);
  const [showCheck, setshowCheck] = useState(false);

  // selected table info
  const [tableInfo, settableInfo] = useState({});

  // handle instant table status
  const handleOptiontableStatus = (status) => {
    // here stopwatch function
    if (status === "booked") {
      setRunning(true);
    } else {
      setRunning(false);
    }

    // don't touch below code
    const tableUrl = BASE_URL + `/settings/instant-table-status`;
    const formData = new FormData();
    formData.append("status", status);
    formData.append("table_id", tableInfo.id);
    return axios
      .post(tableUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallTables(res.data[1]);
        toast.success(`${_t(t("Table status saved successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handle group table status
  const handleOptionTablleForAll = (status, tableInfo) => {
    const tableUrl = BASE_URL + `/settings/group-table-status-remove`;
    const formData = new FormData();
    formData.append("status", status);
    formData.append(
      "table_group_id",
      tableInfo.reservation_infos.table_group_id
    );
    return axios
      .post(tableUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallTables(res.data[1]);
        toast.success(`${_t(t("Table status saved successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handleTableStatus function  to change table status
  const handleTableStatus = (table) => {
    settableInfo(table);
    handleOpen(true);
  };

  // handle booked table function
  const handleBookedTable = () => {
    toast.info(`${_t(t("Table is already booked!!"))}`, {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
    // setLoading(true);
  };

  // handle handletabledirty function
  const handletabledirty = () => {
    console.log("send dirty status to server");
  };

  // table reset
  const resetTables = () => {
    const tableUrl = BASE_URL + `/settings/reset-old-table`;
    const formdata = [];
    return axios
      .post(tableUrl, formdata, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log(res);

        setallTables(res.data[1]);
        toast.success(`${_t(t("Table reset successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`${_t(t("Try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  // handle handleSendToWaitList (uncomment it if test not work)
  const handleSendToWaitList = (rsv_info) => {
    const rsvUrl = BASE_URL + `/settings/rsv-send-to-waitlist`;
    const formData = new FormData();
    formData.append("rsv_id", rsv_info.id);
    return axios
      .post(rsvUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log(res);
        setallTables(res.data.table[1]);
        settodayList(res.data.waitlist["today"]);
        setupcommingList(res.data.waitlist["upcomming"]);
        toast.success(`${_t(t("Send to waitlist successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ::::::::::::::::::::::::::::::::::::::::::::::::::::::: stopewatch start
  // stopwatch function
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);
  // useEffect
  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else if (!running) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [running]);

  //functions
  const handleStart = () => {
    setRunning(true);
  };
  const handleStop = () => {
    setRunning(false);
    console.log(time);
  };
  const handleReset = () => {
    setTime(0);
    console.log(time);
  };
  const stopwatch = (time, status) => {
    // let interval;
  };

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::: stopwatch end

  // useEffect
  useEffect(() => {
    getAllTables();
  }, []);

  // stopwatch useEffect

  return (
    <>
      {/* table option modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* modal test start */}

          <div className="party_rsv_option table-book-option">
            {/* menu counter */}
            <span className="text-center d-block mb-3">
              {showNotify == true ? 2 : showCheck == true ? 3 : 1} of 3
            </span>
            {/* title box */}
            <div className="title-box">party option</div>
            {/* menu option */}
            <div className="party-menu-wrapper mt-4 d-flex justify-content-center">
              <div
                onClick={() => {
                  tableInfo.reservation_infos != null
                    ? handleOptionTablleForAll("open", tableInfo) //for all groupd table
                    : handleOptiontableStatus("open"); //for single table
                }}
                className={`single-table-option `}
              >
                <span
                  className={`icon d-block ${
                    tableInfo.status == "open" ? "bg-green" : ""
                  }`}
                >
                  <i className="fas fa-laptop"></i>
                </span>
                <span className="title d-block">open</span>
              </div>
              <div
                onClick={() => handleOptiontableStatus("booked")}
                className={`single-table-option  mx-4`}
              >
                <span
                  className={`icon d-block ${
                    tableInfo.status == "booked" ? "bg-green" : ""
                  }`}
                >
                  <i className="fas fa-wifi"></i>
                </span>
                <span className="title d-block">seated</span>
              </div>
              {/* <div
                onClick={() => handletabledirty()}
                className={`single-table-option`}
              >
                <span className="icon d-block">
                  <i className="fas fa-chair"></i>
                </span>
                <span className="title d-block">dirty</span>
              </div> */}
            </div>
            {/* open */}
            <div className="assign-box-wrapper mt-3 p-4">
              {/* title info */}
              <div className="title-box">guest information</div>
              {tableInfo.reservation_infos && (
                <div>
                  <h5>
                    {tableInfo.reservation_infos &&
                      tableInfo.reservation_infos.name}
                  </h5>
                  {/* info */}
                  <div className="d-flex flex-wrap py-4">
                    <span className="d-inline-block mr-3">
                      <i className="fas fa-clipboard-list"></i>
                    </span>
                    <p>
                      {tableInfo.reservation_infos &&
                        tableInfo.reservation_infos.note}
                    </p>
                  </div>
                  {/* today button */}
                  {/* <span className="day">Guest</span> */}
                  {/* details */}
                  <div className="info-wrapper py-3 mb-2">
                    <span className="d-inline-block mr-3">
                      <i className="fas fa-circle"></i>
                    </span>
                    <span>
                      Enail :{" "}
                      {tableInfo.reservation_infos &&
                        tableInfo.reservation_infos.email}
                    </span>
                  </div>
                  <div className="info-wrapper py-3 mb-2">
                    <span className="d-inline-block mr-3">
                      <i className="fas fa-circle"></i>
                    </span>
                    <span>
                      Date :{" "}
                      {tableInfo.reservation_infos &&
                        tableInfo.reservation_infos.date}
                    </span>
                  </div>
                  <div className="info-wrapper py-3 mb-2">
                    <span className="d-inline-block mr-3">
                      <i className="fas fa-circle"></i>
                    </span>
                    <span>
                      Scheduled at :{" "}
                      {tableInfo.reservation_infos &&
                        tableInfo.reservation_infos.startTime}
                    </span>
                  </div>
                  {tableInfo.waiters && (
                    <div className="text-capitalize border d-flex justify-content-between px-4 py-2 mt-2">
                      <h6>Server name : </h6>
                      <h6>{tableInfo.waiters.name}</h6>
                    </div>
                  )}
                  {/* action button */}
                  <div className="d-flex justify-content-between mt-3">
                    <Link
                      to={`/dashboard/reservation/assign-tables-customer/${tableInfo.reservation_infos.id}`}
                      className="btn btn-green"
                    >
                      change table
                    </Link>

                    <button
                      className="btn btn-green"
                      onClick={() =>
                        handleSendToWaitList(tableInfo.reservation_infos)
                      }
                    >
                      send to waitlist
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* modal test end */}
        </Box>
      </Modal>

      <div className="rsv_right_table_plan_wrapper overflow-y-scroll w-100 height-100vh">
        <div className="table-reset-container d-flex align-items-center">
          <button
            onClick={resetTables}
            className="btn btn-info text-capitalize"
          >
            reset old table
          </button>
        </div>
        {allTables &&
          allTables.map((table, index) => {
            return (
              <>
                <div
                  onClick={
                    (e) => handleTableStatus(table)
                    // table.status === "booked"
                    //   ? handleBookedTable(table)
                    //   : handleTableStatus(table)
                  }
                  className={`single-table-layout mt-4 
                  ${
                    table.capacity > 10
                      ? "large-table"
                      : table.capacity > 6
                      ? "traingle-table mx-4"
                      : "circle-table"
                  }
                  ${table.status === "booked" ? "booked" : ""}
                  m-1`}
                  key={index}
                >
                  <span
                    className={
                      table.reservation_infos &&
                      JSON.parse(table.reservation_infos.table_group_id)
                        .length > 1
                        ? "d-inline-block"
                        : "d-none"
                    }
                  >
                    <i class="fas fa-link"></i>
                  </span>
                  <span className="title">{table.name}</span>
                  {
                    // if table booked then show else hide
                    table && table.status === "booked"
                      ? [
                          <div className="numbers">
                            <span className="d-inline-block fz-12">
                              {("0" + Math.floor((time / 60000) % 60)).slice(
                                -2
                              )}
                              :
                            </span>
                            <span className="d-inline-block fz-12">
                              {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
                            </span>
                          </div>,
                        ]
                      : ""
                  }

                  {/* {table.status == "booked" ? (
                    <span className="d-inline-block text-white stopwatch-style">
                      {`${time.min < 10 ? 0 : ""}${time.min} : ${
                        time.sec < 10 ? 0 : ""
                      }${time.sec}`}
                    </span>
                  ) : (
                    <span className="d-inline-block text-white">
                      {table.capacity}
                    </span>
                  )} */}

                  <span
                    className={`text-white  ${
                      table.status === "booked" ? "d-none" : "d-inline-block"
                    }`}
                  >
                    {table.capacity}
                  </span>
                </div>
              </>
            );
          })}
        {/* <div className="w-100 d-flex">
          <Stopwatch className="m-4" />
          <Stopwatch className="m-4" />
          <Stopwatch className="m-4" />
        </div> */}
      </div>
    </>
  );
};

export default AssignTable;
