import { BASE_URL } from "../../../../BaseUrl";
import { getCookie } from "../../../../functions/Functions";
import axios from "axios";


export const DAY_CLOSE_SAVE_URL = `${BASE_URL}/settings/dayclosesave`;
export const DAY_CLOSE_URL = `${BASE_URL}/settings/dayclose`;
export const DAY_CLOSE_LIST_URL = `${BASE_URL}/settings/daycloselist`;

export function saveDayClose(obj) {
    return axios.get(DAY_CLOSE_SAVE_URL+'?close_date='+obj.date+'&total_amount='+obj.sale+'&expenses='+obj.expense+'&cash='+obj.cash+'&card='+obj.card+'&upi='+obj.online+'&user_id='+obj.userId, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}

export function getDayClose(obj) {
    return axios.get(DAY_CLOSE_URL+'?close_date='+2022-12-12, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}

export function DayCloseList() {
    return axios.get(DAY_CLOSE_LIST_URL, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}
