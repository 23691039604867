import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReservationMenu from "./layout/ReservationMenu";
import { sidebarSkeleton } from "./Skeleton/Skeleton";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography, Modal } from "@mui/material";
import { _t, getCookie } from "../../../../functions/Functions";
import { BASE_URL } from "../../../../BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import AssignTable from "./component/AssignTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Reservation = () => {
  const { t } = useTranslation();

  // hidefooter
  const hideFooter = () => {
    let reservationPanels = window.location.pathname.includes(
      "/dashboard/reservation"
    );
    let footerId = document.getElementById("footer");
    reservationPanels
      ? footerId.classList.add("d-none")
      : footerId.classList.remove("d-none");
  };

  const [showAssign, setshowAssign] = useState();
  const [showNotify, setshowNotify] = useState(false);
  const [showCheck, setshowCheck] = useState(false);
  const [showOptionList, setshowOptionList] = useState(null);
  const [checkoutopen, setcheckoutOpen] = useState({
    open: false,
    table_group_id: null,
  });
  const [removeWaitList, setremoveWaitList] = useState({
    open: false,
    id: null,
  });

  //  checkout confirm modal
  const handlecheckoutopen = (table_group_id) => {
    if (table_group_id === null) {
      toast.error(`${_t(t("Please assign table first!!!"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      setcheckoutOpen({
        open: true,
        table_group_id: table_group_id,
      });
    }
  };
  const handlecheckoutClose = () =>
    setcheckoutOpen({
      open: false,
    });

  // remove waitlist confirm modal
  const handleRemoveOpen = (id) => {
    setremoveWaitList({
      open: true,
      id: id,
    });
  };
  const handleRemoveClose = () => {
    setremoveWaitList({
      open: false,
    });
  };

  // handle show option
  const handleShowOptions = (e) => {
    if (e === "assign") {
      setshowAssign(true);
      setshowNotify(false);
      setshowCheck(false);
    } else if (e === "notify") {
      setshowAssign(false);
      setshowNotify(true);
      setshowCheck(false);
    } else if (e === "check") {
      setshowAssign(false);
      setshowNotify(false);
      setshowCheck(true);
    }
  };

  //  todaylist
  const [todayList, settodayList] = useState([]);

  //  todaylist
  const [upcommingList, setupcommingList] = useState([]);

  // get today date
  const [TodayDate, setTodayDate] = useState(null);

  // loading
  const [loading, setLoading] = useState(false);

  // get current time
  const setCurentTimeDate = () => {
    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();

    let newdate = year + "/" + month + "/" + day;
    setTodayDate(newdate);
  };

  // get today reservation list
  const getWaitlistReservations = () => {
    setLoading(true);
    const $todayUrl = BASE_URL + `/settings/get-all-reservation-list`;
    return axios
      .get($todayUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        settodayList(res.data["today"]);
        setupcommingList(res.data["upcomming"]);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
        setLoading(false);
      });
  };

  // notify customer
  const notifyCustomer = (id, msg) => {
    setLoading(true);
    const notifyUrl = BASE_URL + `/settings/notify-customer`;
    const formData = new FormData();
    formData.append("customer_id", id);
    formData.append("message", msg);
    return axios
      .post(notifyUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success(`${_t(t("Notification successfully sent"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`${_t(t("Please Try again!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        console.log(err);
        setLoading(false);
      });
  };

  // handle checkin
  const handleCheckin = (table_group_id, customer_id) => {
    if (table_group_id === null) {
      toast.error(`${_t(t("Please assign table first!!!"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      setLoading(true);
      const notifyUrl =
        BASE_URL + `/settings/checkedin-customer/${customer_id}`;
      const formData = new FormData();
      formData.append("table_group_id", table_group_id);
      return axios
        .post(notifyUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          // update waitlist value
          settodayList(res.data["today"]);
          setupcommingList(res.data["upcomming"]);
          toast.success(`${_t(t("Reservation booked successfully!!!"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          setLoading(false);
        })
        .catch((err) => {
          toast.error(`${_t(t("Please Try again!!"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          console.log(err);
          setLoading(false);
        });
    }
  };

  // handle checkout
  const handlecheckout = () => {
    setLoading(true);
    const notifyUrl = BASE_URL + `/settings/checkout-customer`;
    const formData = new FormData();
    formData.append("table_group_id", checkoutopen.table_group_id);
    return axios
      .post(notifyUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success(`${_t(t("Checkout successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setcheckoutOpen({
          open: false,
          table_group_id: null,
        });
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`${_t(t("Please Try again!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        console.log(err);
        setLoading(false);
      });
  };

  // handledelete reservation
  const handleWaitlistRemove = () => {
    setLoading(true);
    const removeUrl = BASE_URL + `/settings/delete-reservation`;
    const formData = new FormData();
    formData.append("customer_id", removeWaitList.id);
    return axios
      .post(removeUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success(`${_t(t("Reservation removed successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        // close confirm modal
        setremoveWaitList({
          open: false,
        });
        // update waitlist value
        settodayList(res.data["today"]);
        setupcommingList(res.data["upcomming"]);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`${_t(t("Please Try again!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        console.log(err);
        setLoading(false);
      });
  };

  // handle show option list
  const handleOptionListOpen = (idx) => {
    setshowOptionList(idx);
  };

  // handle close option list
  const handleOptionListClose = (idx) => {
    setshowOptionList(null);
  };

  // useEffect
  useEffect(() => {
    setshowAssign(true);
    getWaitlistReservations();
    setCurentTimeDate();
    hideFooter();
  }, []);

  return (
    <>
      {/* checkout confirm modal */}

      <Modal
        open={checkoutopen.open}
        onClose={handlecheckoutClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure?
          </Typography>
          <Box className="mt-4 d-flex">
            <button
              className="btn btn-primary px-4 mr-4"
              onClick={() => handlecheckout()}
            >
              Yes
            </button>
            <button
              className="btn btn-success  px-4"
              onClick={handlecheckoutClose}
            >
              No
            </button>
          </Box>
        </Box>
      </Modal>

      {/* remove waitlist list */}
      <Modal
        open={removeWaitList.open}
        onClose={handleRemoveClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure?
          </Typography>
          <Box className="mt-4 d-flex">
            <button
              className="btn btn-primary px-4 mr-4"
              onClick={() => handleWaitlistRemove()}
            >
              Yes
            </button>
            <button
              className="btn btn-success  px-4"
              onClick={handleRemoveClose}
            >
              No
            </button>
          </Box>
        </Box>
      </Modal>

      {/* wraper */}
      <div className="reservation_wrapper pt-3">
        {/* left side reservation menu */}
        <ReservationMenu />
        {/* right side reservation content*/}
        <div className="rsv_right_content">
          {/* top header-bar */}
          <div className="top-header d-flex align-items-center justify-content-between">
            <div className="date-wrapper">
              <span className="icon">
                <i class="fas fa-calendar-day"></i>
              </span>
              <span className="day">
                {/* Tuesday, May 2022 */}
                {TodayDate}
              </span>
            </div>
            <div>
              <Link
                to="/dashboard/reservation/add-party"
                className="btn btn-primary text-capitalize  fz-12"
              >
                add party
              </Link>
            </div>
          </div>
          {/* bottom content */}
          <div className="rsv_btm_content">
            {/* inner left side */}
            <div className="rsv_left_btm_sidebar">
              {/* mui card info */}

              {/* title */}
              {/* upcoming */}

              {todayList && todayList.length > 0 ? (
                <span className="text-capitalize font-weight-bold d-inline-block my-3 bg-dark text-white w-100 p-2">
                  {/* today */}
                  current
                </span>
              ) : (
                ""
              )}

              {/* reservation list */}
              {loading === true
                ? [sidebarSkeleton()]
                : [
                    todayList && todayList.length > 0
                      ? todayList.map((todayrsv, idx) => {
                          return (
                            <>
                              <div className="rsv_party_list mb-4" key={idx}>
                                <div className="mr-5">
                                  <span className="time">
                                    <i class="far fa-clock d-inline-block mr-2"></i>
                                    {/* 3.30PM */}
                                    {todayrsv.startTime}
                                  </span>
                                  <span className="show-status my-2"></span>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="total-size mr-3">
                                    {/* 10 */}
                                    {todayrsv.total_guest}
                                  </span>
                                  <span className="customer-name">
                                    {/* prince */}
                                    {todayrsv.name}
                                  </span>
                                </div>
                                <button
                                  onClick={() =>
                                    handleOptionListOpen(todayrsv.id)
                                  }
                                  className="btn btn-green rsv_list_action_button"
                                >
                                  <i class="fas fa-ellipsis-v"></i>
                                </button>
                                {/* party option for single waitlist  start*/}
                                <div
                                  className={`party_rsv_option ${
                                    showOptionList == todayrsv.id
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  {/* close option */}
                                  <button
                                    className="btn btn-danger position-rsv-option-close"
                                    onClick={() => handleOptionListClose()}
                                  >
                                    <i class="far fa-times-circle"></i>
                                  </button>

                                  {/* menu counter */}
                                  <span className="text-center d-block mb-3">
                                    {showNotify == true
                                      ? 2
                                      : showCheck == true
                                      ? 3
                                      : 1}{" "}
                                    of 3
                                  </span>
                                  {/* title box */}
                                  <div className="title-box">party option</div>
                                  {/* menu option */}
                                  <div className="party-menu-wrapper mt-4 d-flex justify-content-center">
                                    <div
                                      className={`single-option text-decoration-none ${
                                        showAssign == true ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        handleShowOptions("assign")
                                      }
                                    >
                                      <span className="icon d-block">
                                        <i class="fas fa-laptop"></i>
                                      </span>
                                      <span className="title d-block">
                                        assign
                                      </span>
                                    </div>
                                    <div
                                      className={`single-option mx-2 ${
                                        showNotify == true ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        handleShowOptions("notify")
                                      }
                                    >
                                      <span className="icon d-block">
                                        <i class="fas fa-wifi"></i>
                                      </span>
                                      <span className="title d-block">
                                        notify
                                      </span>
                                    </div>
                                    <div
                                      className={`single-option ${
                                        showCheck == true ? "active" : ""
                                      }`}
                                      onClick={() => handleShowOptions("check")}
                                    >
                                      <span className="icon d-block">
                                        <i class="fas fa-chair"></i>
                                      </span>
                                      <span className="title d-block">
                                        check
                                      </span>
                                    </div>
                                  </div>
                                  {/* assign box */}
                                  <div
                                    className={`assign-box-wrapper mt-3 p-4 ${
                                      showAssign == true ? "active" : "d-none"
                                    }`}
                                  >
                                    {/* assign table button */}
                                    <Link
                                      to={`/dashboard/reservation/assign-tables-customer/${todayrsv.id}`}
                                      className="btn btn-green mb-4 w-100"
                                    >
                                      assign table
                                    </Link>
                                    {/* title info */}
                                    <div className="title-box">
                                      guest information
                                    </div>
                                    {/* info */}
                                    <div className="d-flex flex-wrap py-4">
                                      <span className="d-inline-block mr-3">
                                        <i class="fas fa-clipboard-list"></i>
                                      </span>
                                      <p>
                                        {todayrsv && todayrsv.note != null
                                          ? todayrsv.note
                                          : " No notes"}
                                      </p>
                                    </div>
                                    <div></div>
                                    {/* today button */}
                                    {/* <span className="day">today</span> */}
                                    {/* details */}
                                    <div className="info-wrapper py-3 mb-2">
                                      <span className="d-inline-block mr-3">
                                        <i class="fas fa-circle"></i>
                                      </span>
                                      <span>
                                        Email : {todayrsv && todayrsv.email}
                                      </span>
                                    </div>
                                    <div className="info-wrapper py-3 mb-2">
                                      <span className="d-inline-block mr-3">
                                        <i class="fas fa-circle"></i>
                                      </span>
                                      <span>
                                        Phone : {todayrsv && todayrsv.phone}
                                      </span>
                                    </div>
                                    <div className="info-wrapper py-3 mb-2">
                                      <span className="d-inline-block mr-3">
                                        <i class="fas fa-circle"></i>
                                      </span>
                                      <span>
                                        Total Guest :{" "}
                                        {todayrsv && todayrsv.total_guest}
                                      </span>
                                    </div>
                                    <div className="info-wrapper py-3 mb-2">
                                      <span className="d-inline-block mr-3">
                                        <i class="fas fa-circle"></i>
                                      </span>
                                      <span>
                                        Branch :{" "}
                                        {todayrsv && todayrsv.branches.name}
                                      </span>
                                    </div>
                                    <div className="info-wrapper py-3 mb-2">
                                      <span className="d-inline-block mr-3">
                                        <i class="fas fa-circle"></i>
                                      </span>
                                      <span>
                                        Scheduled at :{" "}
                                        {todayrsv && todayrsv.startTime}
                                      </span>
                                    </div>
                                    <div className="info-wrapper py-3 mb-2">
                                      <span className="d-inline-block mr-3">
                                        <i class="fas fa-circle"></i>
                                      </span>
                                      <span>
                                        Date : {todayrsv && todayrsv.date}
                                      </span>
                                    </div>
                                    {/* action button */}
                                    <div className="d-flex justify-content-between mt-1">
                                      <button
                                        onClick={() =>
                                          handleRemoveOpen(todayrsv.id)
                                        }
                                        className="btn btn-green"
                                      >
                                        delete
                                      </button>
                                      {/* <button className="btn btn-green">
                                        edit
                                      </button> */}
                                    </div>
                                  </div>

                                  {/* notify */}
                                  <div
                                    className={`assign-box-wrapper px-4 py-2 ${
                                      showNotify == true ? "active" : "d-none"
                                    }`}
                                  >
                                    <h4 className="text-center text-capitalize">
                                      Notify guest
                                    </h4>
                                    <p className="pb-2 mb-0 text-center">
                                      let the party know their table status
                                    </p>
                                    <button
                                      onClick={() =>
                                        notifyCustomer(
                                          todayrsv.id,
                                          "almost ready"
                                        )
                                      }
                                      className="btn w-100 d-block text-capitalize hover-btn-bg text-info"
                                    >
                                      almost ready
                                    </button>
                                    <button
                                      onClick={() =>
                                        notifyCustomer(todayrsv.id, "ready")
                                      }
                                      className="btn w-100 d-block text-capitalize hover-btn-bg text-info"
                                    >
                                      ready
                                    </button>
                                    <button
                                      onClick={() =>
                                        notifyCustomer(todayrsv.id, "canceled")
                                      }
                                      className="btn w-100 d-block text-capitalize hover-btn-bg text-info"
                                    >
                                      cancel
                                    </button>
                                  </div>
                                  {/* check */}
                                  <div
                                    className={`assign-box-wrapper px-4 py-2 ${
                                      showCheck == true ? "active" : "d-none"
                                    }`}
                                  >
                                    <button
                                      onClick={() =>
                                        handleCheckin(
                                          todayrsv.table_group_id,
                                          todayrsv.id
                                        )
                                      }
                                      className="btn w-100 mt-4 d-block text-capitalize hover-btn-bg text-info"
                                    >
                                      Check in & seat
                                    </button>
                                    <button
                                      onClick={() =>
                                        handlecheckoutopen(
                                          todayrsv.table_group_id
                                        )
                                      }
                                      className="btn w-100 d-block text-capitalize hover-btn-bg text-info"
                                    >
                                      Check out
                                    </button>
                                  </div>
                                </div>
                                {/* party option for single waitlist end*/}
                              </div>
                            </>
                          );
                        })
                      : [
                          <div className="text-center border shadow border-3 my-3">
                            <h5>No reservations for today!!</h5>
                          </div>,
                        ],
                  ]}

              {/* upcoming */}
              {upcommingList && upcommingList.length > 0 ? (
                <span className="text-capitalize font-weight-bold d-inline-block my-3 bg-dark text-white w-100 p-2">
                  upcoming
                </span>
              ) : (
                ""
              )}

              {/* reservation list */}
              {upcommingList && upcommingList.length > 0
                ? [
                    upcommingList.map((upcomming, idx) => {
                      return (
                        <>
                          <div className="rsv_party_list mb-4" key={idx}>
                            <div className="mr-5">
                              <span className="time">
                                <i class="far fa-clock d-inline-block mr-2"></i>
                                {/* 3.30PM */}
                                {upcomming.startTime}
                              </span>
                              <span className="show-status my-2"></span>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="total-size mr-3">
                                {/* 10 */}
                                {upcomming.total_guest}
                              </span>
                              <span className="customer-name">
                                {/* prince */}
                                {upcomming.name}
                              </span>
                            </div>
                            <button
                              onClick={() => handleOptionListOpen(upcomming.id)}
                              className="btn btn-green rsv_list_action_button"
                            >
                              <i class="fas fa-ellipsis-v"></i>
                            </button>
                            {/* party option for single waitlist  start*/}
                            <div
                              className={`party_rsv_option ${
                                showOptionList == upcomming.id ? "active" : ""
                              }`}
                            >
                              {/* close option */}
                              <button
                                className="btn btn-danger position-rsv-option-close"
                                onClick={() => handleOptionListClose()}
                              >
                                <i class="far fa-times-circle"></i>
                              </button>

                              {/* menu counter */}
                              <span className="text-center d-block mb-3">
                                {showNotify == true
                                  ? 2
                                  : showCheck == true
                                  ? 3
                                  : 1}{" "}
                                of 3
                              </span>
                              {/* title box */}
                              <div className="title-box">party option</div>
                              {/* menu option */}
                              <div className="party-menu-wrapper mt-4 d-flex justify-content-center">
                                <div
                                  className={`single-option text-decoration-none ${
                                    showAssign == true ? "active" : ""
                                  }`}
                                  onClick={() => handleShowOptions("assign")}
                                >
                                  <span className="icon d-block">
                                    <i class="fas fa-laptop"></i>
                                  </span>
                                  <span className="title d-block">assign</span>
                                </div>
                                <div
                                  className={`single-option mx-2 ${
                                    showNotify == true ? "active" : ""
                                  }`}
                                  onClick={() => handleShowOptions("notify")}
                                >
                                  <span className="icon d-block">
                                    <i class="fas fa-wifi"></i>
                                  </span>
                                  <span className="title d-block">notify</span>
                                </div>
                                <div
                                  className={`single-option ${
                                    showCheck == true ? "active" : ""
                                  }`}
                                  onClick={() => handleShowOptions("check")}
                                >
                                  <span className="icon d-block">
                                    <i class="fas fa-chair"></i>
                                  </span>
                                  <span className="title d-block">check</span>
                                </div>
                              </div>
                              {/* assign box */}
                              <div
                                className={`assign-box-wrapper mt-3 p-4 ${
                                  showAssign == true ? "active" : "d-none"
                                }`}
                              >
                                {/* assign table button */}
                                <Link
                                  to={`/dashboard/reservation/assign-tables-customer/${upcomming.id}`}
                                  className="btn btn-green mb-4 w-100"
                                >
                                  assign table
                                </Link>
                                {/* title info */}
                                <div className="title-box">
                                  guest information
                                </div>
                                {/* info */}
                                <div className="d-flex flex-wrap py-4">
                                  <span className="d-inline-block mr-3">
                                    <i class="fas fa-clipboard-list"></i>
                                  </span>
                                  <p>
                                    {upcomming && upcomming.note != null
                                      ? upcomming.note
                                      : " No notes"}
                                  </p>
                                </div>
                                <div></div>
                                {/* today button */}
                                {/* <span className="day">today</span> */}
                                {/* details */}
                                <div className="info-wrapper py-3 mb-2">
                                  <span className="d-inline-block mr-3">
                                    <i class="fas fa-circle"></i>
                                  </span>
                                  <span>
                                    Email : {upcomming && upcomming.email}
                                  </span>
                                </div>
                                <div className="info-wrapper py-3 mb-2">
                                  <span className="d-inline-block mr-3">
                                    <i class="fas fa-circle"></i>
                                  </span>
                                  <span>
                                    Phone : {upcomming && upcomming.phone}
                                  </span>
                                </div>
                                <div className="info-wrapper py-3 mb-2">
                                  <span className="d-inline-block mr-3">
                                    <i class="fas fa-circle"></i>
                                  </span>
                                  <span>
                                    Total Guest :{" "}
                                    {upcomming && upcomming.total_guest}
                                  </span>
                                </div>
                                <div className="info-wrapper py-3 mb-2">
                                  <span className="d-inline-block mr-3">
                                    <i class="fas fa-circle"></i>
                                  </span>
                                  <span>
                                    Branch :{" "}
                                    {upcomming && upcomming.branches.name}
                                  </span>
                                </div>
                                <div className="info-wrapper py-3 mb-2">
                                  <span className="d-inline-block mr-3">
                                    <i class="fas fa-circle"></i>
                                  </span>
                                  <span>
                                    Scheduled at :{" "}
                                    {upcomming && upcomming.startTime}
                                  </span>
                                </div>
                                <div className="info-wrapper py-3 mb-2">
                                  <span className="d-inline-block mr-3">
                                    <i class="fas fa-circle"></i>
                                  </span>
                                  <span>
                                    Date : {upcomming && upcomming.date}
                                  </span>
                                </div>
                                {/* action button */}
                                <div className="d-flex justify-content-between mt-1">
                                  <button
                                    onClick={() =>
                                      handleRemoveOpen(upcomming.id)
                                    }
                                    className="btn btn-green"
                                  >
                                    delete
                                  </button>
                                  {/* <button className="btn btn-green">
                                        edit
                                      </button> */}
                                </div>
                              </div>

                              {/* notify */}
                              <div
                                className={`assign-box-wrapper px-4 py-2 ${
                                  showNotify == true ? "active" : "d-none"
                                }`}
                              >
                                <h4 className="text-center text-capitalize">
                                  Notify guest
                                </h4>
                                <p className="pb-2 mb-0 text-center">
                                  let the party know their table status
                                </p>
                                <button
                                  onClick={() =>
                                    notifyCustomer(upcomming.id, "almost ready")
                                  }
                                  className="btn w-100 d-block text-capitalize hover-btn-bg text-info"
                                >
                                  almost ready
                                </button>
                                <button
                                  onClick={() =>
                                    notifyCustomer(upcomming.id, "ready")
                                  }
                                  className="btn w-100 d-block text-capitalize hover-btn-bg text-info"
                                >
                                  ready
                                </button>
                                <button
                                  onClick={() =>
                                    notifyCustomer(upcomming.id, "canceled")
                                  }
                                  className="btn w-100 d-block text-capitalize hover-btn-bg text-info"
                                >
                                  cancel
                                </button>
                              </div>
                              {/* check */}
                              <div
                                className={`assign-box-wrapper px-4 py-2 ${
                                  showCheck == true ? "active" : "d-none"
                                }`}
                              >
                                <button
                                  onClick={() =>
                                    handleCheckin(
                                      upcomming.table_group_id,
                                      upcomming.id
                                    )
                                  }
                                  className="btn w-100 mt-4 d-block text-capitalize hover-btn-bg text-info"
                                >
                                  Check in & seat
                                </button>
                                <button
                                  onClick={() =>
                                    handlecheckoutopen(upcomming.table_group_id)
                                  }
                                  className="btn w-100 d-block text-capitalize hover-btn-bg text-info"
                                >
                                  Check out
                                </button>
                              </div>
                            </div>
                            {/* party option for single waitlist end*/}
                          </div>
                        </>
                      );
                    }),
                  ]
                : [
                    <div className="text-center border shadow border-3 my-3">
                      <h5>No upcoming reservations!!</h5>
                    </div>,
                  ]}
            </div>
            {/* inner right side */}
            <AssignTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reservation;
