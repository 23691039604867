import { BASE_URL } from "../../../../BaseUrl";
import { getCookie } from "../../../../functions/Functions";
import axios from "axios";


export const DAY_CLOSE_URL = `${BASE_URL}/settings/dayclose`;

export function getDayClose() {
    return axios.get(DAY_CLOSE_URL, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}