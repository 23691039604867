import React,{FC, useState, useEffect} from 'react'
import { getVoidOrderReports } from './_requests'
import { MdEdit, MdDelete } from "react-icons/md";
import { BiX } from "react-icons/bi";
import { _t } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { BsCreditCard } from "react-icons/bs";
import { date } from 'yup';

const VoidOrderReports = () => {

  const { t } = useTranslation();

  const[orderReports, setOrderReports] = useState({});
  const[id, setId] = useState('');
  const[date, setDate] = useState('');

  const searchDateOrders = async (date) => {
    // const cat = document.getElementById('category').value;
    // let body = {
    //   "category": cat
    // }
    setDate(date);
    const Data = await getVoidOrderReports(date);
    if(Data != null) {
      console.log('voidData', Data)
      setOrderReports(Data);
    }
    // const Data = await saveCategories(body);
    // if(Data != null) {
    //   document.getElementById('category').value='';
    //   setCategories(Data);
    // }
  }

  const handleSetEdit = async () => {
    const cat = document.getElementById('categoryUpdate').value;
    let body = {
      "id": id,
      "category": cat
    }
    // const Data = await updateCategories(body);
    // if(Data != null) {
    //   document.getElementById('categoryUpdate').value='';
    //   setCategories(Data);
    // }

  }

  const handleDelete = async () => {
    // const data = await deleteCategories(id)
    // if(data != null) {
    //   setCategories(data);
    // }
  }

    useEffect(() => {
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      let currentDate = `${year}-${month}-${day}`
      document.getElementById('orderReportDate').value = currentDate;
      searchDateOrders(currentDate);
      setDate(currentDate);
    }, []);

    return (
    <div className="container mt-2">
      <div className='modal fade' id={'deleteCategory'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Confirmation</h3>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal'>
                      <BiX size={20}/>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <p>Are you sure want to delete?</p>
                    <div className='d-flex align-items-center justify-content-end'>
                        <button className='btn btn-sm btn-secondary mt-3 mx-3' data-dismiss='modal'>
                            No
                        </button>
                        <button className='btn btn-sm text-primary mt-3' data-dismiss='modal' 
                        onClick={(e) => handleDelete()}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='modal fade' id={'updateCategory'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Confirmation</h3>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal'>
                      <BiX size={20}/>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                <div className='row'>
                  <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 py-3'>
                    <input type="text" placeholder="Category..." id='categoryUpdate' className="form-control border-0 form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 py-3 px-2'>
                    <button type='button' onClick={handleSetEdit} className='btn btn-transparent btn-primary sm-text text-uppercase w-100' data-dismiss='modal'>Save</button>
                  </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <form>
      <div className='w-100'>
        <div className='card t-bg-white sm_shadow sm_card expense_card overflow-hidden'>
          <div className='d-flex justify-content-between container pt-3'>
            <div className='d-flex align-items-center pb-0'>
              <ul className="t-list fk-breadcrumb">
                <li className="fk-breadcrumb__list">
                  <span className="t-link fk-breadcrumb__link text-capitalize pl-3">
                    {_t(t("Table Void Reports"))}
                  </span>
                </li>
              </ul>
            </div>
            <div className='pb-0'>
                <div className='p-3 d-flex justify-content-end'>
                  <input type="date" id='orderReportDate' value={moment(date).format('YYYY-MM-DD')} onChange={(e) => searchDateOrders(e.target.value)} className="form-control"/>
                </div>
            </div>
          </div>
          <div className='card-body pt-0'>
          {/* <div className='row'>
            <div className='col-md-3'>
                <div className='card h-100'>
                    <div className='card-body p-1'>
                    <div class="d-flex align-items-center">
                      <div class="symbol mr-3 p-2">
                        <img className='icon icon_1' src={"/assets/img/cash-icon.png"} alt="" />
                      </div>
                      <div class="flex-grow-1">
                        <a href="#" class="text-dark text-bold text-hover-primary fs-6">Cash</a>
                        <span class="text-muted d-block fw-semibold">₹{orderReports.total?.cash ?? '0'}</span>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3'>
                <div className='card h-100'>
                    <div className='card-body p-1'>
                    <div class="d-flex align-items-center">
                      <div class="symbol mr-3 p-2">
                        <img className='icon icon_1' src={"/assets/img/card.png"} alt="" />
                      </div>
                      <div class="flex-grow-1">
                        <a href="#" class="text-dark text-bold text-hover-primary fs-6">Card</a>
                        <span class="text-muted d-block fw-semibold">₹{orderReports.total?.card ?? '0'}</span>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3'>
                <div className='card h-100'>
                    <div className='card-body p-1'>
                    <div class="d-flex align-items-center">
                      <div class="symbol mr-3 p-2">
                        <img className='icon icon_1' src={"/assets/img/UPI-icon.png"} alt="" />
                      </div>
                      <div class="flex-grow-1">
                        <a href="#" class="text-dark text-bold text-hover-primary fs-6">UPI</a>
                        <span class="text-muted d-block fw-semibold">₹{orderReports.total?.upi ?? '0'}</span>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3'>
                <div className='card h-100'>
                    <div className='card-body p-1'>
                    <div class="d-flex align-items-center">
                      <div class="symbol mr-3 p-2">
                        <img className='icon icon_1' src={"/assets/img/other-payment.png"} alt="" />
                      </div>
                      <div class="flex-grow-1">
                        <a href="#" class="text-dark text-bold text-hover-primary fs-6">Other Payments</a>
                        <span class="text-muted d-block fw-semibold">₹{orderReports.total?.other ?? '0'}</span>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
        </div> */}
            <div className=" overflow-auto">
              <div>
              <table className='table table-bordered table-hover mt-4'> 
              <thead className="align-middle">
                <tr>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">No.</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Customer Name</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Branch</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Slot</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Table</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Order Bill</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Bill Number</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Tax</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Advance</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Total Bill</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Reason</th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {orderReports.details?.map((cate, i) => {
                  return(
                <>                
                <tr>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{i+1}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.customer_name}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.theBranch?.name}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.slot_name}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.table_name}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.order_bill - cate.vat}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.bill_no}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.vat}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.advance?.advance}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.order_bill}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.cancel_reason}</td>
                 
                </tr></>)})}
              </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
    )
}

export default VoidOrderReports

