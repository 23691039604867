import React, { useEffect, useContext, useState } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
// import TimePicker from 'react-time-picker';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';

//functions
import {
  _t,
  getCookie,
  pageLoading,
  modalLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import { MdEdit, MdDelete } from "react-icons/md";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { NavLink } from "react-router-dom";

const BookingSlots = () => {
  var weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  //getting context values here
  const {
    //common
    loading,
    setLoading,
    dataPaginating,
    showManageStock,
  } = useContext(SettingsContext);

  const { authUserInfo } = useContext(UserContext);

  const {
    //branch
    branchForSearch,

    //work period
    getBookingSlots,
    bookingSlotList,
    setBookingSlotList,
    setPaginatedBookingSlot,
    bookingSlotForSearch,
    setBookingSlotListForSearch,
  } = useContext(RestaurantContext);

  const { t } = useTranslation();

  //new unit
  let [newBookSlots, setNewBookSlots] = useState({
    user_type: null,
    branch_id: null,
    slot_type: null,
    in_time: null,
    out_time: null,
    edit: false,
    slot_id: null,
    editSlug: null,
    uploading: false,
  });

  let [fromTime, setFromTime] = useState('');
  let [toTime, setToTime] = useState('');
  let [fromTimeVal, setFromTimeVal] = useState('');
  let [toTimeVal, setToTimeVal] = useState('');
  let [fromTimeEdit, setFromTimeEdit] = useState(false);
  let [toTimeEdit, setToTimeEdit] = useState(false);
  let [editSlotId, setEditSlotId] = useState('');
  const [checkedSlot, setCheckedSlot] = useState(1);

  //search result
  let [searchedWorkPeriod, setSearchedWorkPeriod] = useState({
    list: null,
    searched: false,
  });

  const slotData = [
    {
        id: 1,
        name: 'Lunch',
        label: 'Lunch'
    },
    {
        id: 2,
        name: 'Dinner',
        label: 'Dinner'
    }
  ];

  //useEffect == componentDidMount
  useEffect(() => {
    authUserInfo.details &&
      setNewBookSlots({
        branch_id: authUserInfo.details.branch_id
          ? authUserInfo.details.branch_id
          : null,
        user_type: authUserInfo.details.user_type,
      });
  }, []);

  //set branch id
  const handleSetBranchId = (branch) => {
    setNewBookSlots({
      ...newBookSlots,
      branch_id: branch.id,
    });
  };

  //search work periods here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedWorkPeriod({ ...searchedWorkPeriod, searched: false });
    } else {
      let searchedList = bookingSlotForSearch.filter((item) => {
        let lowerCaseItemBranchName = item.slot_type.toLowerCase();
        let lowerCaseItemUserName = item.in_time.toLowerCase();
        let lowerCaseItemDate = item.out_time.toLowerCase();
        // let lowerCaseItemUserName2 =
        //   item.ended_by && item.ended_by.toLowerCase();
        return (
          lowerCaseItemBranchName.includes(searchInput) ||
          lowerCaseItemUserName.includes(searchInput) ||
          lowerCaseItemDate.includes(searchInput) 
        //   ||
        //   (lowerCaseItemUserName2 &&
        //     lowerCaseItemUserName2.includes(searchInput))
        );
      });
      setSearchedWorkPeriod({
        ...searchedWorkPeriod,
        list: searchedList,
        searched: true,
      });
    }
  };

  //Save New work period
  const handleSaveNewWorkPeriod = (e) => {
    e.preventDefault();
    if (newBookSlots.branch_id) {
        setNewBookSlots({
        ...newBookSlots,
        uploading: true,
      });
      const url = BASE_URL + `/settings/new-work-period`;
      let date =
        weekday[new Date().getDay()] +
        ", " +
        new Date().getDate() +
        " " +
        month[new Date().getMonth()] +
        ", " +
        new Date().getFullYear();

      let time = new Date().getTime();

      let formData = {
        date: date,
        branch_id: newBookSlots.branch_id,
        started_at: time,
      };
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data === "exist") {
            authUserInfo.details &&
            setNewBookSlots({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
              });
            toast.error(
              `${_t(t("Please end the started work period first"))}`,
              {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              }
            );
          } else {
            authUserInfo.details &&
            setNewBookSlots({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
              });
            setBookingSlotList(res.data[0]);
            setBookingSlotListForSearch(res.data[1]);
            setSearchedWorkPeriod({
              ...searchedWorkPeriod,
              list: res.data[1],
            });
            setLoading(false);
            toast.success(`${_t(t("Work period has been started"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        })
        .catch(() => {
          setLoading(false);
          setNewBookSlots({
            ...newBookSlots,
            uploading: false,
          });
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //milisec to hour min
  const millisToMinutesAndHours = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var hours = Math.floor(minutes / 60);
    minutes = Math.floor(minutes % 60);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${hours} ${_t(t("hr"))} - ${minutes} ${_t(t("min"))} - ${seconds < 10 ? "0" : ""
      }${seconds} ${_t(t("sec"))}`;
  };

  //end confirmation modal of workPeriod
  const handleDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to end now?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleEndWorkPeriod(id);
                  onClose();
                }}
              >
                {_t(t("Yes, end work period!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //end here
  const handleEndWorkPeriod = (id) => {
    setNewBookSlots({
      ...newBookSlots,
      uploading: true,
    });
    const url = BASE_URL + `/settings/update-work-period`;
    let time = new Date().getTime();
    let formData = {
      id: id,
      ended_at: time,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data !== "orderExist" && res.data !== "addClosing") {
          authUserInfo.details &&
          setNewBookSlots({
              branch_id: authUserInfo.details.branch_id
                ? authUserInfo.details.branch_id
                : null,
              user_type: authUserInfo.details.user_type,

              uploading: false,
            });
            setBookingSlotList(res.data[0]);
            setBookingSlotListForSearch(res.data[1]);
          setSearchedWorkPeriod({
            ...searchedWorkPeriod,
            list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Work period has been ended"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          if (res.data === "addClosing") {
            toast.error(`${_t(t("Add closing stock of ingredients"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          } else {
            toast.error(
              `${_t(
                t("All submitted orders need to be settled to end workperiod")
              )}`,
              {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              }
            );
          }
          setLoading(false);
          setNewBookSlots({
            ...newBookSlots,
            uploading: false,
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNewBookSlots({
          ...newBookSlots,
          uploading: false,
        });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  const handleSaveNewSlot = async(e) => {
    // console.log(fromTimeVal);
    // console.log(toTimeVal);
    // console.log(checkedSlot);
    // e.preventDefault();
    if (newBookSlots.branch_id) {
        setNewBookSlots({
        ...newBookSlots,
        uploading: true,
      });
      const url = BASE_URL + `/settings/store-slots?in_time=`+fromTimeVal+`&out_time=`+toTimeVal+`&slot_type=`+checkedSlot;
      
      return await
    //    axios
    //     .post(url, {
    //         headers: { Authorization: `Bearer ${getCookie()}` },
    //     })
    fetch(url, {
        method:'post',
        headers: { Authorization: `Bearer ${getCookie()}` },
    }).then((res) => {
            console.log('slotsss res');
            console.log(res.data);
          // if (res.data === "exist") {
          //   authUserInfo.details &&
          //   setNewBookSlots({
          //       branch_id: authUserInfo.details.branch_id
          //         ? authUserInfo.details.branch_id
          //         : null,
          //       user_type: authUserInfo.details.user_type,

          //       uploading: false,
          //     });
          //   toast.error(
          //     `${_t(t("Please end the started work period first"))}`,
          //     {
          //       position: "bottom-center",
          //       autoClose: 10000,
          //       hideProgressBar: false,
          //       closeOnClick: true,
          //       pauseOnHover: true,
          //       className: "text-center toast-notification",
          //     }
          //   );
          // } else {
            authUserInfo.details &&
            setNewBookSlots({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
              });
            // setBookingSlotList(res.data[0]);
            // setBookingSlotListForSearch(res.data[1]);
            // setSearchedWorkPeriod({
            //   ...searchedWorkPeriod,
            //   list: res.data[1],
            // });
            setLoading(false);
            toast.success(`${_t(t("Booking slot added started"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          // }
        })
        .catch((error) => {
          setLoading(false);
          setNewBookSlots({
            ...newBookSlots,
            uploading: false,
          });
          console.log('error slot');
          console.log(error);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }

  const handleUpdateSlot = async() => {
    if (newBookSlots.branch_id) {
      setNewBookSlots({
      ...newBookSlots,
      uploading: true,
    });
    const url = BASE_URL + `/settings/slot-update?in_time=`+fromTimeVal+`&out_time=`+toTimeVal+`&slot_type=`+checkedSlot+'&id='+editSlotId;
    
    return await fetch(url, {
      method:'post',
      headers: { Authorization: `Bearer ${getCookie()}` },
    }).then((res) => {
            console.log('slotsss res');
            console.log(res.data);
            authUserInfo.details &&
            setNewBookSlots({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
              });
            setLoading(false);
            setFromTimeEdit(false);
            setToTimeEdit(false);
            toast.success(`${_t(t("Booking slot updated"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
        })
        .catch((error) => {
          setLoading(false);
          setNewBookSlots({
            ...newBookSlots,
            uploading: false,
          });
          console.log('error slot');
          console.log(error);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }

  //set edit true & values
  const handleSetEdit = (id) => {
    let bookSlot = bookingSlotForSearch.filter((item) => {
      return item.id === id;
    });

    let selectedOptionForBranch = null;
    if (
      authUserInfo.details !== null &&
      authUserInfo.details.user_type !== "staff"
    ) {
      if (bookSlot[0].branch_id) {
        selectedOptionForBranch = branchForSearch.filter((branchItem) => {
          return branchItem.id === parseInt(bookSlot[0].branch_id);
        });
      }
    }
    setCheckedSlot(bookSlot[0].slot_type)
    setFromTimeVal(bookSlot[0].in_time)
    setToTimeVal(bookSlot[0].out_time)
    setEditSlotId(bookSlot[0].id)
    setNewBookSlots({
      ...newBookSlots,
      slot_type: bookSlot[0].slot_type,
      in_time: bookSlot[0].in_time,
      out_time: bookSlot[0].out_time,
      editSlug: bookSlot[0].id,
      edit: true,
    });
  };

  const fromEditClick = () => {
    setFromTimeEdit(true);
  }

  const toEditClick = () => {
    setToTimeEdit(true);
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("Booking Slots"))}</title>
      </Helmet>

        {/* Add modal */}
        <div className="modal fade" id="addSlot" aria-hidden="true">
            <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header align-items-center">
                <div className="fk-sm-card__content">
                    <h5 className="text-capitalize fk-sm-card__title">
                    {!newBookSlots.edit
                      ? 
                      _t(t("Add new slot"))
                      : _t(t("Update customer")) 
                    }
                    </h5>
                </div>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      setFromTimeEdit(false);
                      setToTimeEdit(false);
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                ></button>
                </div>
                <div className="modal-body">
                {/* show form or show saving loading */}
                { 
                // newBookSlots.uploading === false ? (
                    <div key="fragment-customer-1">
                    <form
                        // onSubmit={
                        //   !newCustomer.edit
                        //     ? 
                        // handleSaveNewSlot
                        //     : handleUpdateCustomer
                        // }
                    >
                        <div className="row">
                            <div className="d-flex align-items-center gap-2">
                            {slotData.map(item => {
                                return (
                                    <label key={item.id} className="mx-checkbox">
                                    <input
                                        type="radio"
                                        id={item.id}
                                        value={item.name}
                                        className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                        name="radioGroup"
                                        label={item.label}
                                        aria-label={item.label}
                                        aria-checked={checkedSlot === item.name}
                                        checked={checkedSlot === item.name}
                                        onChange={() => setCheckedSlot(item.name)}
                                    />
                                    <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text">
                                        {item.label}
                                    </span>
                                    </label>
                                    
                                );
                            })}
                            </div>
                            <div className="mt-3 col-md-6 col-12 time_slot_field">
                              {!newBookSlots.edit ?
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <TimePicker
                                      label="From Time"
                                      value={fromTime}
                                      format="hh:mm:ss"
                                      // inputFormat="hh:mm:ss"
                                      onChange={(newValue) => {
                                          setFromTime(newValue);
                                          console.log(newValue);
                                      }}
                                      renderInput={(params) => {
                                          if(fromTime != ''){
                                              setFromTimeVal(params.inputProps.value);
                                              // console.log({...params});
                                          }
                                          return <TextField {...params} />
                                      }
                                  }
                                  />
                                </LocalizationProvider>  
                                : !fromTimeEdit ?
                                <div className="edit_slot_input">
                                  <div>
                                    <label htmlFor="email" className="form-label">
                                      {_t(t("From Time"))}
                                    </label>
                                    <div className="">
                                      {fromTimeVal}
                                    </div>
                                  </div>
                                  <button 
                                  type="button"
                                  onClick={() => {
                                    fromEditClick()
                                  }}
                                  className="btn btn-sm btn-outline-primary"
                                  >Change</button>
                                </div> : 
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    label="From Time"
                                    value={fromTime}
                                    format="hh:mm:ss"
                                    // inputFormat="hh:mm:ss"
                                    onChange={(newValue) => {
                                        setFromTime(newValue);
                                        console.log(newValue);
                                    }}
                                    renderInput={(params) => {
                                        if(fromTime != ''){
                                            setFromTimeVal(params.inputProps.value);
                                            // console.log({...params});
                                        }
                                        return <TextField {...params} />
                                    }
                                }
                                />
                              </LocalizationProvider> 
                              }
                            </div>
                            
                            <div className="mt-3 col-md-6 col-12 time_slot_field">
                            {!newBookSlots.edit ?
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="To Time"
                                        value={toTime}
                                        onChange={(newValue) => {
                                            setToTime(newValue);
                                        }}
                                        renderInput={(params) => {
                                            if(toTime != ''){
                                                setToTimeVal(params.inputProps.value);
                                            }
                                            return <TextField {...params} />
                                        }}
                                    />
                                </LocalizationProvider>
                                : !toTimeEdit ?
                                <div className="edit_slot_input">
                                  <div>
                                    <label htmlFor="email" className="form-label">
                                      {_t(t("To Time"))}
                                    </label>
                                    <div className="">
                                      {toTimeVal}
                                    </div>
                                  </div>
                                  <button 
                                  type="button"
                                  onClick={() => {
                                    toEditClick()
                                  }}
                                  className="btn btn-sm btn-outline-primary"
                                  >Change</button>
                                </div> : 
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <TimePicker
                                      label="To Time"
                                      value={toTime}
                                      onChange={(newValue) => {
                                          setToTime(newValue);
                                      }}
                                      renderInput={(params) => {
                                          if(toTime != ''){
                                              setToTimeVal(params.inputProps.value);
                                          }
                                          return <TextField {...params} />
                                      }}
                                  />
                                </LocalizationProvider>
                              }
                            </div>

                        </div>
                        
                        <div className="mt-4">
                        <div className="row">
                            <div className="col-6">
                            <button
                                type="button"
                                onClick={
                                      !newBookSlots.edit
                                      ? 
                                      handleSaveNewSlot
                                      : handleUpdateSlot}
                                className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                            >
                                {
                                !newBookSlots.edit?
                                 _t(t("Save"))
                                : _t(t("Update"))
                                }
                            </button>
                            </div>
                            <div className="col-6">
                            <button
                                type="button"
                                className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                                onClick={() => {
                                  setFromTimeEdit(false);
                                  setToTimeEdit(false);
                                }}
                                data-dismiss="modal"
                            >
                                {_t(t("Close"))}
                            </button>
                            </div>
                        </div>
                        </div>
                    </form>
                    </div>
                // ) : (
                //     <div key="fragment2">
                //     <div className="text-center text-primary font-weight-bold text-uppercase">
                //         {_t(t("Please wait"))}
                //     </div>
                //     {modalLoading(3)}
                //     <div className="mt-4">
                //         <div className="row">
                //         <div className="col-6">
                //             <button
                //             type="button"
                //             className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                //             onClick={(e) => {
                //                 e.preventDefault();
                //             }}
                //             >
                //             {
                //             _t(t("Save")) 
                //             }
                //             </button>
                //         </div>
                //         <div className="col-6">
                //             <button
                //             type="button"
                //             className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                //             data-dismiss="modal"
                //             >
                //             {_t(t("Close"))}
                //             </button>
                //         </div>
                //         </div>
                //     </div>
                //     </div>
                // )
                }
                </div>
            </div>
            </div>
        </div>
        {/* Add modal Ends*/}

      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {newBookSlots.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white sm_shadow sm_card">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row t-mt-10 gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                    <div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {!searchedWorkPeriod.searched
                              ? _t(t("Booking Slots"))
                              : _t(t("Search Result"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-12 col-lg-5">
                      <div className="input-group">
                        <button className="btn btn-primary" type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="form-file">
                          <input
                            type="text"
                            className="form-control border-0 form-control--light-1 rounded-0"
                            placeholder={
                              _t(t("Search by branch, date, user")) + ".."
                            }
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-md-4 mt-lg-0 col-lg-7 t-mb-15 mb-md-0">
                      <div className="row t-mt-10 gx-2 align-items-center">
                        {authUserInfo.details !== null &&
                          authUserInfo.details.user_type !== "staff" ? (
                          <>
                            <div className="col-md-7 t-mb-15 mb-md-0 mt-3 mt-md-0">
                              <Select
                                options={branchForSearch && branchForSearch}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                classNamePrefix="select"
                                className="xsm-text"
                                onChange={handleSetBranchId}
                                maxMenuHeight="200px"
                                placeholder={
                                  _t(t("Please select a branch")) + ".."
                                }
                              />
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list fk-sort align-items-center justify-content-center">
                                <li className="fk-sort__list mb-0 flex-grow-1">
                                  <button
                                    data-toggle="modal"
                                    data-target="#addSlot"
                                    onClick={() => {
                                      setCheckedSlot('');
                                      setNewBookSlots({
                                        ...newBookSlots,
                                        branch: null,
                                        edit: false,
                                        uploading: false,
                                      });
                                    }}
                                    className="w-100 btn btn-dark sm-text text-uppercase"
                                  >
                                    {_t(t("Add Time Slot"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : (
                          <div className="col-12 col-md-5 ml-auto mt-2 mt-md-0">
                            <ul className="t-list fk-sort align-items-center justify-content-center">
                              <li className="fk-sort__list mb-0 flex-grow-1">
                                <button
                                    data-toggle="modal"
                                    data-target="#addSlot"
                                    onClick={() => {
                                      setCheckedSlot('');
                                      setNewBookSlots({
                                        ...newBookSlots,
                                        branch: null,
                                        edit: false,
                                        uploading: false,
                                      });
                                    }}
                                  className="w-100 btn btn-dark sm-text text-uppercase"
                                >
                                  {_t(t("Add Time Slot"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fk-scroll--order-history" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="overflow-auto">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Slot Type"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("In Time"))}
                              </th>
                              {/* <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Out Time"))}
                              </th> */}
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                              {/* {showManageStock ? <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Stock"))}
                              </th> : null} */}
                              {/* <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Ended at"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Ended by"))}
                              </th> */}

                              {/* <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Total time"))}
                              </th> */}
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {!searchedWorkPeriod.searched
                              ? [
                                bookingSlotList && [
                                    bookingSlotList.data.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="8"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    bookingSlotList.data.map((item, index) => {
                                      return (
                                        <tr
                                          className="align-middle"
                                          key={index}
                                        >
                                          <th
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {index +
                                              1 +
                                              (bookingSlotList.current_page -
                                                1) *
                                                bookingSlotList.per_page}
                                          </th>

                                          <td className="xsm-text align-middle text-center text-secondary">
                                            {item.slot_type}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.in_time}
                                          </td>

                                          {/* <td className="xsm-text align-middle text-center">
                                            {item.out_time}
                                          </td> */}

                                          <td className="xsm-text text-capitalize align-middle text-center">
                                            <div className="d-flex align-items-center justify-content-center gap-1">
                                              <button 
                                              data-toggle="modal"
                                              data-target="#addSlot"
                                              onClick={() => {
                                                setNewBookSlots({
                                                  ...newBookSlots,
                                                  branch: null,
                                                  uploading: false,
                                                });
                                                handleSetEdit(
                                                  item.id
                                                );
                                              }}
                                              type="button" className="btn btn-sm btn-outline-primary">
                                                <MdEdit size={20}/>
                                              </button>
                                              <button 
                                              onClick={() => {

                                              }}
                                              type="button" className="btn btn-sm btn-outline-danger">
                                                <MdDelete size={20}/>
                                              </button>
                                            </div>
                                            {/* <div className="dropdown">
                                              <button
                                                className="btn t-bg-clear t-text-dark--light-40"
                                                type="button"
                                                data-toggle="dropdown"
                                              >
                                                <i className="fa fa-ellipsis-h"></i>
                                              </button>
                                              <div className="dropdown-menu">
                                                <button
                                                  className="dropdown-item sm-text text-capitalize"
                                                  onClick={() => {
                                                    setNewCustomer({
                                                      ...newCustomer,
                                                      branch: null,
                                                    });
                                                    handleSetEdit(
                                                      item.slug
                                                    );
                                                  }}
                                                  data-toggle="modal"
                                                  data-target="#addCustomer"
                                                >
                                                  <span className="t-mr-8">
                                                    <i className="fa fa-pencil"></i>
                                                  </span>
                                                  {_t(t("Edit"))}
                                                </button>

                                                <button
                                                  className="dropdown-item sm-text text-capitalize"
                                                  onClick={() => {
                                                    handleDeleteConfirmation(
                                                      item.slug
                                                    );
                                                  }}
                                                >
                                                  <span className="t-mr-8">
                                                    <i className="fa fa-trash"></i>
                                                  </span>
                                                  {_t(t("Delete"))}
                                                </button>
                                              </div>
                                            </div> */}
                                          </td>

                                          {/* show if manage stock is enable */}
                                          {/* {showManageStock ? <td className="xsm-text align-middle text-center">
                                            <NavLink
                                              className={`btn xxsm-text ${item.ended_at === null
                                                ? "btn-secondary"
                                                : "btn-success"
                                                } btn-sm p-1`}
                                              to={
                                                `/dashboard/closing-stock/` +
                                                item.started_at
                                              }
                                            >
                                              {_t(t("Closing Stock"))}
                                            </NavLink>
                                          </td> : null} */}


                                          {/* <td className="xsm-text align-middle text-center text-primary">
                                            {item.ended_at ? (
                                              <Moment format="LT">
                                                {new Date(item.updated_at)}
                                              </Moment>
                                            ) : (
                                              "-"
                                            )}
                                          </td> */}

                                          {/* <td className="xsm-text align-middle text-center">
                                            {item.ended_at ? (
                                              item.ended_by
                                            ) : (
                                              <button
                                                className="btn btn-primary btn-sm py-0 px-4"
                                                onClick={() => {
                                                  handleDeleteConfirmation(
                                                    item.id
                                                  );
                                                }}
                                              >
                                                {_t(t("End"))}
                                              </button>
                                            )}
                                          </td>
                                          <td className="xsm-text align-middle text-center">
                                            {item.ended_at
                                              ? millisToMinutesAndHours(
                                                parseInt(
                                                  item.ended_at -
                                                  item.started_at
                                                )
                                              )
                                              : "-"}
                                          </td> */}
                                        </tr>
                                      );
                                    })
                                  ),
                                ],
                              ]
                              : [
                                /* searched data, logic === haveData*/
                                searchedWorkPeriod && [
                                  searchedWorkPeriod.list.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="9"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    searchedWorkPeriod.list.map(
                                      (item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (bookingSlotList.current_page -
                                                  1) *
                                                  bookingSlotList.per_page}
                                            </th>

                                            <td className="xsm-text align-middle text-center text-secondary">
                                                {item.slot_type}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                                {item.in_time}
                                            </td>

                                            {/* <td className="xsm-text align-middle text-center">
                                                {item.out_time}
                                            </td> */}

                                            {/* <td className="xsm-text align-middle text-center text-green">
                                                <Moment format="LT">
                                                {new Date(item.record_time)}
                                                </Moment>
                                            </td> */}
                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              <div className="d-flex align-items-center justify-content-center gap-1">
                                                <button 
                                                data-toggle="modal"
                                                data-target="#addSlot"
                                                onClick={() => {
                                                  setNewBookSlots({
                                                    ...newBookSlots,
                                                    branch: null,
                                                    uploading: false,
                                                  });
                                                  handleSetEdit(
                                                    item.id
                                                  );
                                                }}
                                                type="button" className="btn btn-sm btn-outline-primary">
                                                  <MdEdit size={20}/>
                                                </button>
                                                <button 
                                                onClick={() => {

                                                }}
                                                type="button" className="btn btn-sm btn-outline-danger">
                                                  <MdDelete size={20}/>
                                                </button>
                                              </div>
                                            </td>
                                            {/* 
                                            <td className="xsm-text align-middle text-center">
                                              <NavLink
                                                className={`btn xxsm-text ${item.ended_at === null
                                                    ? "btn-secondary"
                                                    : "btn-success"
                                                  } btn-sm p-1`}
                                                to={
                                                  `/dashboard/closing-stock/` +
                                                  item.started_at
                                                }
                                              >
                                                {_t(t("Closing Stock"))}
                                              </NavLink>
                                            </td> */}

                                            {/* <td className="xsm-text align-middle text-center">
                                              {item.ended_at ? (
                                                <Moment format="LT">
                                                  {new Date(item.updated_at)}
                                                </Moment>
                                              ) : (
                                                "-"
                                              )}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.ended_at ? (
                                                item.ended_by
                                              ) : (
                                                <button
                                                  className="btn btn-primary btn-sm py-0 px-4"
                                                  onClick={() => {
                                                    handleDeleteConfirmation(
                                                      item.id
                                                    );
                                                  }}
                                                >
                                                  {_t(t("End"))}
                                                </button>
                                              )}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.ended_at
                                                ? millisToMinutesAndHours(
                                                  parseInt(
                                                    item.ended_at -
                                                    item.started_at
                                                  )
                                                )
                                                : "-"}
                                            </td> */}
                                          </tr>
                                        );
                                      }
                                    )
                                  ),
                                ],
                              ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* pagination loading effect */}
              {newBookSlots.uploading === true || loading === true
                ? paginationLoading()
                : [
                  // logic === !searched
                  !searchedWorkPeriod.searched ? (
                    <div key="fragment4">
                      <div className="t-bg-white mt-1 t-pt-5 pb-3 radius_bottom">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            {/* pagination function */}
                            {pagination(
                              bookingSlotList,
                              setPaginatedBookingSlot
                            )}
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(bookingSlotList)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // if searched
                    <div className="t-bg-white mt-1 t-pt-5 pb-3 radius_bottom">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          <ul className="t-list d-flex">
                            <li className="t-list__item no-pagination-style">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  setSearchedWorkPeriod({
                                    ...searchedWorkPeriod,
                                    searched: false,
                                  })
                                }
                              >
                                {_t(t("Clear Search"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                {searchedShowingData(
                                  searchedWorkPeriod,
                                  bookingSlotForSearch
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ),
                ]}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default BookingSlots;
