import React,{FC, useState, useEffect, useContext} from 'react'
import { UserContext } from "../../../../contexts/User";
import { _t } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { DayCloseList, saveDayClose } from "./requests";
import { MdEdit, MdDelete } from "react-icons/md";
import { BiX } from "react-icons/bi";
import Moment from "react-moment";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";

const DayClose = () => {

    const { t } = useTranslation();

    const {authUserInfo} = useContext(UserContext);

    const [loading, setLoading] = useState(false)
    const [dayCloseList, setDayCloseList] = useState([])
    const [id, setId] = useState('')

    const dayClose = async () => {
      const response = await DayCloseList()
      setDayCloseList(response)
    }

    const dayCloseSave = async () => {
      
    const date = document.getElementById('dayCloseDate')?.value;
    const sale = document.getElementById('dayCloseSale')?.value;
    const expense = document.getElementById('dayCloseExpense')?.value;
    const cash = document.getElementById('dayCloseCash')?.value;
    const online = document.getElementById('dayCloseOnline')?.value;
    const card = document.getElementById('dayCloseCard')?.value;

      let body = {
        "date": date,
        "sale": sale,
        "expense": expense,
        "cash": cash,
        "online": online,
        "card": card,
        "userId": authUserInfo.details?.id,
    }
      const Data = await saveDayClose(body);
      if(Data != null) {
        reset();
        dayClose();
      }
    //  else {
    //   let body = {
    //     "id": id,
    //     "category": selectedCategory,
    //     "description": exp,
    //     "amount": amt,
    //     "expdate": expDate
    // }
    //   const Data = await updateExpense(body);
    //   if(Data != null) {
    //     reset();
    //     setExpense(Data);
    //     setId('');
    //   }
    // }
    }

    const reset = () => {
      document.getElementById("dayCloseForm").reset();
      dayClose();
    }

    useEffect(() => {
      dayClose();
    }, []);

    return (
        <>
        <div className="container mt-2">
      <div className='modal fade' id={'deleteExpense'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Confirmation</h3>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal'>
                      <BiX size={20}/>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <p>Are you sure want to delete?</p>
                    <div className='d-flex align-items-center justify-content-end'>
                        <button className='btn btn-sm btn-secondary mt-3 mx-3' data-dismiss='modal'>
                            No
                        </button>
                        <button className='btn btn-sm text-primary mt-3' data-dismiss='modal' 
                        // onClick={(e) => handleDelete()}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='modal fade' id={'addDayClose'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                  {id ? <h3>Update Expense</h3> :
                    <h3>Add Expense</h3>}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal' onClick={() => reset()}>
                      <BiX size={20}/>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                <div className='row'>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                    <input type="date" placeholder="Date..." id='dayCloseDate' className="form-control form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                    <input type="number" placeholder="Total Sale..." id='dayCloseSale' className="form-control form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                    <input type="number" placeholder="Total Expense..." id='dayCloseExpense' className="form-control form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                    <input type="number" placeholder="Cash..." id='dayCloseCash' className="form-control form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                    <input type="number" placeholder="Online (UPI)..." id='dayCloseOnline' className="form-control form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                    <input type="number" placeholder="Card Payment..." id='dayClosecard' className="form-control form-control--light-1 rounded-0"/>
                  </div>
                </div>
                </div>
                <div className='modal-footer'>                  
                  <div className='d-flex justify-content-end'>
                    <button type='button' onClick={() => reset()} className='btn btn-transparent btn-secondary sm-text text-dark mr-2 text-uppercase w-100' data-dismiss='modal'>Cancel</button>
                    <button type='button' onClick={dayCloseSave} className='btn btn-transparent btn-primary sm-text text-uppercase w-100' data-dismiss='modal'>Save</button>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <form id='dayCloseForm'>
      <div className='w-100'>
        <div className='t-bg-white sm_shadow sm_card expense_card overflow-auto'>
          <div className='row w-100 justify-content-between'>
            <ul className="col-md-3 t-list fk-breadcrumb pl-4 pt-4 pb-0" >
              <li className="fk-breadcrumb__list">
                <span className="t-link fk-breadcrumb__link text-capitalize">
                  {_t(t("Day Close"))}
                </span>
              </li>
            </ul>            
            <div className='col-md-2 p-4 pb-0'>
                <button type='button' data-toggle="modal"
                  data-target={"#addDayClose"} className='btn btn-transparent btn-primary sm-text text-uppercase w-100'>Add New</button>
            </div>
          </div>
          <div className='' data-simplebar>
            <div className="t-pl-15 t-pr-15 overflow-auto">
              <div>
              <table className='table table-bordered table-hover mt-4'> 
              <thead className="align-middle">
                <tr>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">No.</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Day Close Date</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Total Amount</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Total Expence</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Cash in Hand</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Online Payment</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Card Payment</th>
                  {/* <th scope="col" className="sm-text align-middle text-center border-1 border">Actions</th> */}
                </tr>
              </thead>
              <tbody className="align-middle">
                {dayCloseList.map((cate, i) => {
                  return(
                <>                
                <tr>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{i+1}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.day_close_date}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.total_amount}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.total_expense}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.cash_in_hand}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.Online}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.card_payment}</td>
                  {/* <td scope="col" className="sm-text align-middle text-center border-1 border">
                  <button 
                    data-toggle="modal"
                    data-target={"#addExpense"}
                    type="button" className="btn btn-sm btn-outline-primary mx-1"
                    // onClick={() => { }}
                    >
                      <MdEdit size={20}/>
                    </button>
                    <button 
                    data-toggle="modal"
                    data-target={"#deleteExpense"}
                    type="button" className="btn btn-sm btn-outline-danger mx-1"
                    // onClick={() => setId(cate.id)}
                    >
                      <MdDelete size={20}/>
                    </button>
                  </td> */}
                </tr></>)})}
              </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
        </>
    )
}
export default DayClose