import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
//functions
import { _t, getCookie, pageLoading } from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL, demo } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StripeCardSetUp = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // hooks
  const [stripeCardDetails, setstripeCardDetails] = useState({
    card_reg_code: null,
    card_label: null,
    card_location_id: null,
  });

  // loading
  const [loading, setloading] = useState(true);

  // checkbox state
  const [isChecked, setIsChecked] = useState(false);

  // get stripe info
  // const getstripeInfo = () => {
  //     setloading(true);
  //     const url = BASE_URL + `/settings/get-stripe-info`;
  //     axios.get(url, {
  //         headers: { Authorization: `Bearer ${getCookie()}` },
  //     }).then((res) => {
  //         setstripeCardDetails({
  //             ...stripeCardDetails,
  //             clientId: res.data.STRIPE_KEY,
  //             secretKey: res.data.STRIPE_SECRET,
  //             statementDescriptor: res.data.STATEMENT_DESCRIPTOR,
  //         })

  //         if (res.data.STRIPE_SHOW == "YES") {
  //             setIsChecked(true);
  //         } else {
  //             setIsChecked(false);
  //         }
  //         setloading(false);

  //     });
  // }

  //useEffect == componentDidMount
  useEffect(() => {
    // getstripeInfo();
  }, []);

  // onchange
  const handleOnchange = (e) => {
    setstripeCardDetails({
      ...stripeCardDetails,
      [e.target.name]: e.target.value,
    });
  };

  // handle checkbox
  const handleCheckbox = (e) => {
    setIsChecked(e.target.checked);
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (demo == "YES") {
      toast.success(`${_t(t("Sorry,This is demo purpose only"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      const url = BASE_URL + `/settings/connect-stripe-card-reader`;
      let formData = new FormData();
      formData.append("card_reg_code", stripeCardDetails.card_reg_code);
      formData.append("card_label", stripeCardDetails.card_label);
      formData.append("card_location_id", stripeCardDetails.card_location_id);
      formData.append("show", isChecked);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data == "online") {
            toast.success(`${_t(t("stripe card setup successfully"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          } else {
            toast.warning(`${_t(t("something went wrong!!"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Stripe"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            {/* <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div> */}
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-12 col-xxl-12 t-mb-30 mb-lg-0">
              <div className="t-bg-white p-3 p-md-3 sm_card sm_shadow">
                <div>
                  <h4 className="text-secodary my-4">
                    Setup Stripe Card Reader
                  </h4>
                </div>
                <hr />

                {loading == false ? (
                  pageLoading()
                ) : (
                  <>
                    <div>
                      <p>
                        {" "}
                        Go to the BBPOS WisePOS E settings, and then tap
                        Generate pairing code. Enter the code in
                        below.Application will send the code to Stripe.
                      </p>
                    </div>

                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="card_reg_code" className="form-label">
                          Card register id
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="card_reg_code"
                          onChange={handleOnchange}
                          name="card_reg_code"
                          defaultValue={stripeCardDetails.card_reg_code}
                          placeholder="1252788"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="card_label" className="form-label">
                          stripe card label
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="card_label"
                          onChange={handleOnchange}
                          defaultValue={stripeCardDetails.card_label}
                          name="card_label"
                          placeholder="Alie sharlock"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="statement" className="form-label">
                          Card location id
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="statement"
                          onChange={handleOnchange}
                          defaultValue={stripeCardDetails.card_location_id}
                          name="card_location_id"
                          placeholder="12etyr77"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="checkbox"
                          id="stripecheckbox"
                          defaultValue={isChecked}
                          onChange={handleCheckbox}
                          checked={isChecked}
                        />
                        <label
                          htmlFor="stripecheckbox"
                          className="form-label ml-4 text-capitalize"
                        >
                          hide/show
                        </label>
                      </div>
                      <div className="mb-3">
                        <button className="btn btn-primary text-capitalize">
                          connnect
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default StripeCardSetUp;
