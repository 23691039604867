import React, { useEffect, useContext, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import { BsPrinter, BsCloudDownload } from "react-icons/bs";
import jsPDF from 'jspdf';

//functions
import {
  _t,
  getCookie,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";

const ItemWise = () => {
  const { t } = useTranslation();
  //getting context values here
  let { loading, setLoading, dataPaginating } = useContext(SettingsContext);
  // States hook here
  const [amountChart, setAmountChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [],
      },
      labels: { show: false },
    },
    series: [
      {
        name: _t(t("Amount")),
        data: [],
      },
    ],
  });

  //all data
  const [reportData, setReportData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);

  //useEffect == componentDidMount()
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //get ItemWise reports filter
  const getItemWiseSelected = () => {
    if (startDate !== null && endDate !== null) {
      setLoading(true);
      var fromDate = startDate.toISOString();
      var toDate = endDate.toISOString();
      const url = BASE_URL + "/settings/get-food-item-report";
      let formData = {
        fromDate,
        toDate,
      };
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          let formattedAmount = res.data[1].map((item) =>
            parseFloat(formatPrice(item))
          );
          setAmountChart({
            ...amountChart,
            options: {
              ...amountChart.options,
              xaxis: {
                ...amountChart.options.xaxis,
                categories: res.data[0],
                labels: { show: false },
              },
            },
            series: [
              { name: amountChart.series[0].name, data: formattedAmount },
            ],
          });
          setReportData(res.data[2]);
          setGeneratedReport(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select all the field to generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current
  });

  function tableToCSV() {
 
    var csv_data = [];

    var rows = document.getElementsByClassName('trow');
    for (var i = 0; i < rows.length; i++) {

        var cols = rows[i].querySelectorAll('td,th');

        var csvrow = [];
        for (var j = 0; j < cols.length; j++) {
            csvrow.push(cols[j].innerHTML);
        }

        csv_data.push(csvrow.join(","));
    }

    csv_data = csv_data.join('\n');

    downloadCSVFile(csv_data);
}

function downloadCSVFile(csv_data) {
 
    let CSVFile = new Blob([csv_data], {
        type: "text/csv"
    });

    var temp_link = document.createElement('a');

    temp_link.download = "Discount Wise.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    temp_link.click();
    document.body.removeChild(temp_link);
}

  function sumArray(array){
    let sum = 0
    for (let i = 0; i < 
    array?.length; i++) {
    sum += array[i]
    }
    return sum
  }

  const handleGeneratePdf = async () => {
		const doc = new jsPDF({
			format: 'a4',
      orientation: 'landscape',
			unit: 'px',
		});

		// Adding the fonts.
		doc.setFont('Inter-Regular', 'small');
		doc.setFontSize('10');

		doc.html(reportTemplateRef.current, {
			async callback(doc) {
				await doc.save('Item Wise');
			},
		});
	};

  const componentRef = useRef();
  const reportTemplateRef = useRef();
  const today = new Date();

  return (
    <>
      <Helmet>
        <title>{_t(t("Food item wise report offline report"))}</title>
      </Helmet>

      <div className="d-none">
        <div ref={componentRef}>
          {/* <BeveragesBill start={startDate} end={endDate} data={reportData}/> */}
          <div className="fk-print pt-4">
            <div className="container-bill">
              <h2 className="d-flex justify-content-center font-weight-bold">SMOKE HUB BARBEQUE - 
              {/* <span className="text-uppercase">{orderDetails.branch?.name}</span> */}
              </h2>
              <div className="font-size-14">
                <p className="d-flex xsm-text justify-content-center">Date & Time: {moment(today).format('DD-MMMM-YYYY hh:mm a')}</p>
                <p className="d-flex xsm-text justify-content-center">From: {moment(startDate).format('DD-MMMM-YYYY')}</p>
                <p className="d-flex xsm-text justify-content-center">To: {moment(endDate).format('DD-MMMM-YYYY')}</p>
                <p className="d-flex justify-content-center font-weight-bold pt-3">Buffet</p>
              </div>
              <div className="container d-flex justify-content-center pt-2">
                <table className="table mb-0 table-borderless akash-table-for-print-padding w-100">
                  <thead>
                    <tr className="border-top">
                      <th
                        colSpan={3}
                        className="fk-print-text text-capitalize text-center border-0 font-weight-bold"
                      >
                        Item Wise
                      </th>                      
                    </tr>
                    <tr className="border-top border-bottom">
                      <th
                        scope="col"
                        className="fk-print-text xsm-text text-left text-capitalize border-0"
                      >
                      item
                      </th>
                      <th
                        scope="col"
                        className="fk-print-text xsm-text text-right text-capitalize border-0"
                      >
                        qty
                      </th>
                      <th
                        scope="col"
                        className="fk-print-text xsm-text text-capitalize text-right border-0"
                      >
                        amt
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-bottom">
                    {reportData?.map((item, i) => {
                      return (
                          <tr key={i}>
                            <td className="fk-print-text xsm-text text-capitalize text-left border-0">
                              {item.food_item}
                            </td>
                            <td className="fk-print-text xsm-text text-capitalize text-right border-0">
                              {item.quantity}
                            </td>
                            <td className="fk-print-text xsm-text text-capitalize text-right border-0">
                              {item.price}
                            </td>
                          </tr>
                      )})}                                                   
                  </tbody>
                  <tbody className="border-top">
                    <tr>
                      <td className="fk-print-text xsm-text text-capitalize text-left border-0">Total</td>
                      <td className="fk-print-text xsm-text text-capitalize text-right border-0">{sumArray(reportData?.map((item, i) => item.quantity))}</td>
                      <td className="fk-print-text xsm-text text-capitalize text-right border-0">{sumArray(reportData?.map((item, i) => parseInt(item.price)))}</td>
                    </tr>
                  </tbody>                  
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>  

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            {/* <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div> */}
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-12 col-xxl-12 t-mb-30 mb-lg-0">
              <div className="t-bg-white sm_shadow sm_card">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row t-mt-10 gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Food item wise report offline report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row t-mt-10 gx-2 mt-2 justify-content-end">
                          <div className="col-12 col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-12 col-md-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-5 col-md-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2"
                              onClick={getItemWiseSelected}
                            >
                              {_t(t("Generate Report"))}
                            </button>
                          </div>
                          {/* <div className="col-5 col-md-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            <Link
                              to='/dashboard/online-food-items-reports'
                              className="btn btn-block btn-secondary text-uppercase sm-text py-2"

                            >
                              {_t(t("online orders"))}
                            </Link>
                          </div> */}

                          <div className="col-5 col-md-8 t-mb-15 mb-md-0 d-block d-md-none">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text"
                              onClick={getItemWiseSelected}
                            >
                              {_t(t("Generate Report"))}
                            </button>
                          </div>
                        </div>
                        {generatedReport ? (
                          <>
                            <div className="row t-mt-10 gx-2 justify-content-center t-pt-15">
                              <div className="col-12 mb-md-0">
                                <Chart
                                  options={amountChart.options}
                                  series={amountChart.series}
                                  type="line"
                                  width="100%"
                                  height="350px"
                                />
                              </div>
                            </div>
                            {reportData !== null &&
                              reportData !== undefined &&
                              reportData.length > 0 && (
                                <div className="row t-mt-10 gx-2 justify-content-center t-pb-15 t-pt-15">
                                  <div className="d-flex justify-content-end pr-3">
                                    <div className='p-3' onClick={handleOnlyPrint}>
                                        <BsPrinter size={20}/>
                                    </div>
                                    <div className='p-3' id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <BsCloudDownload size={20}/>
                                    </div>  
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                      <button class="dropdown-item" type="button" onClick={handleGeneratePdf}>PDF</button>
                                      <button class="dropdown-item" type="button" onClick={tableToCSV}>CSV</button>
                                    </div>                           
                                  </div>
                                  <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                                    <table className="table table-bordered table-hover min-table-height mt-3">
                                      <thead className="align-middle">
                                        <tr className="trow">
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("S/L"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Name"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Group"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Qty"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Bill"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Date"))}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="align-middle">
                                        {/* loop here*/}
                                        {reportData.map((item, index) => {
                                          return (
                                            <tr
                                              className="trow"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index + 1}
                                              </th>

                                              <td className="xsm-text align-middle text-center">
                                                {item.food_item}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.food_group}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.quantity}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {currencySymbolLeft()}
                                                {formatPrice(item.price)}
                                                {currencySymbolRight()}
                                              </td>
                                              <td className="xsm-text align-middle text-center">
                                                <Moment format="LL">
                                                  {item.created_at}
                                                </Moment>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="d-none">
                                  <div className="p-5" ref={reportTemplateRef}>
                                  <table className="pdf-table table-p">
                                      <thead className="align-middle">
                                        <tr>
                                          <th
                                            scope="col"
                                            className="xsm-text pdf-table font-size-14 text-center"
                                          >
                                            {_t(t("S/L"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="xsm-text pdf-table font-size-14 text-center"
                                          >
                                            {_t(t("Name"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="xsm-text pdf-table font-size-14 text-center"
                                          >
                                            {_t(t("Group"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="xsm-text pdf-table font-size-14 text-center"
                                          >
                                            {_t(t("Qty"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="xsm-text pdf-table font-size-14 text-center"
                                          >
                                            {_t(t("Bill"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="xsm-text pdf-table font-size-14 text-center"
                                          >
                                            {_t(t("Date"))}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="align-middle">
                                        {/* loop here*/}
                                        {reportData.map((item, index) => {
                                          return (
                                            <tr
                                              className="align-middle text-center"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text pdf-table text-center"
                                              >
                                                {index + 1}
                                              </th>

                                              <td className="xsm-text pdf-table text-center">
                                                {item.food_item}
                                              </td>

                                              <td className="xsm-text pdf-table text-center">
                                                {item.food_group}
                                              </td>

                                              <td className="xsm-text pdf-table text-center">
                                                {item.quantity}
                                              </td>

                                              <td className="xsm-text pdf-table text-center">
                                                {formatPrice(item.price)}
                                              </td>
                                              <td className="xsm-text pdf-table text-center">
                                                <Moment format="LL">
                                                  {item.created_at}
                                                </Moment>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                  </div>
                                </div>
                              )}
                          </>
                        ) : (
                          <div className="row t-mt-10 gx-2 justify-content-center t-pt-15">
                            <div className="col-8 mt-5 py-4 mb-md-0 card text-center text-uppercase sm-text">
                              {_t(
                                t("Generate report following the above field")
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default ItemWise;
