import React, { useEffect, useState, useContext } from "react";
import ReservationMenu from "../layout/ReservationMenu";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import moment from "moment";

// context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";

// functions
import { _t, getCookie } from "../../../../../functions/Functions";

// base url
import { BASE_URL } from "../../../../../BaseUrl";

// axios
import axios from "axios";
import { toast } from "react-toastify";

const AddParty = () => {
  const { t } = useTranslation();
  // get context value
  let {
    //branch
    branchForSearch,
  } = useContext(RestaurantContext);

  // useState
  // client info
  const [clientReqInfo, setclientReqInfo] = useState({
    name: null,
    email: null,
    phone: null,
    total_guest: null,
    note: null,
  });
  // clientReqInfo handle on change
  const handleOnChange = (e) => {
    setclientReqInfo({
      ...clientReqInfo,
      [e.target.name]: e.target.value,
    });
  };

  // loading
  const [loading, setLoading] = useState(false);

  // branch id
  const [branch_id, setbranch_id] = useState();

  const handleBranch = (event) => {
    setbranch_id(event.target.value);
  };

  // date
  const [rsvDate, setrsvDate] = useState();

  // startTime
  const [startTime, setstartTime] = useState();

  // handle time
  const handlTime = (event) => {
    setstartTime(event.target.value);
  };

  // all schedule
  const [schedule, setSchedule] = useState([]);

  // get all time option
  const getAllSchedule = () => {
    setLoading(true);
    const scheduleUrl = BASE_URL + `/settings/get-all-schedule`;
    return axios
      .get(scheduleUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setSchedule(res.data);
        setLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
      });
  };

  // get data on render
  useEffect(() => {
    getAllSchedule();
  }, []);

  // handle reset
  const handleReset = (e) => {
    e.preventDefault();
    setclientReqInfo({
      name: null,
      email: null,
      phone: null,
      total_guest: null,
      note: null,
    });
    setbranch_id(null);
    setstartTime(null);
    setrsvDate(null);
  };

  // hadleSubmit
  const hadleSubmit = (e) => {
    e.preventDefault();
    // get all data
    const data = {
      name: clientReqInfo.name,
      email: clientReqInfo.email,
      phone: clientReqInfo.phone,
      total_guest: clientReqInfo.total_guest,
      branch_id: branch_id,
      note: clientReqInfo.note,
      date: rsvDate,
      startTime: startTime,
    };

    const sendUrl = BASE_URL + `/settings/client-party-request`;
    axios
      .post(sendUrl, data, {
        headers: {
          Authorization: `Bearer ${getCookie()}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === 422) {
          toast.error(`${_t(t("Please enter all required fields"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else if (res.data.status === 202) {
          toast.info(`${_t(t("The time is already booked"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          setclientReqInfo({
            name: null,
            email: null,
            phone: null,
            total_guest: null,
            note: null,
          });
          setbranch_id(null);
          setstartTime(null);
          setrsvDate(null);

          setLoading(false);

          toast.success(
            `${_t(t("Reservation request submitted successfully"))}`,
            {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };
  return (
    <div className="reservation_wrapper pt-3">
      {/* reservation menu */}
      <ReservationMenu />
      {/* right side reservation content*/}
      <div className="rsv_right_content">
        {/* top header-bar */}
        <div className="top-header d-flex align-items-center justify-content-between">
          <div className="date-wrapper">
            <span className="icon">
              <i className="fas fa-calendar-day"></i>
            </span>
            <span className="day">Tuesday, May 2022</span>
          </div>
        </div>
        {/* bottom content */}
        <div className="rsv_btm_content">
          {/* inner right side */}
          <div className="rsv_right_table_plan_wrapper w-100 h-100">
            {/* form box */}
            <Container
              fixed
              sx={{
                postion: "relative",
                zIndex: "11",
              }}
            >
              {/* form container wrapper */}
              <Grid
                container
                component="form"
                onSubmit={hadleSubmit}
                spacing={4}
                sx={{
                  background: "#F5F9FF",
                  borderRadius: "19px",
                  padding: "40px 50px 70px 50px",
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    component="h4"
                    sx={{
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    Add reservation
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    helperText="Please enter your name"
                    required
                    label="Name"
                    type="text"
                    name="name"
                    defaultValue={clientReqInfo.name}
                    onChange={handleOnChange}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    helperText="Please enter your email"
                    required
                    label="Email"
                    name="email"
                    defaultValue={clientReqInfo.email}
                    onChange={handleOnChange}
                    type="email"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    helperText="Please enter your phone number"
                    required
                    name="phone"
                    defaultValue={clientReqInfo.phone}
                    onChange={handleOnChange}
                    type="tel"
                    label="Phone Number"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    helperText="Total number of guest"
                    required
                    label="Total guest"
                    name="total_guest"
                    defaultValue={clientReqInfo.total_guest}
                    onChange={handleOnChange}
                    type="number"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={2} direction="row">
                    <TextField
                      helperText="Note"
                      name="note"
                      defaultValue={clientReqInfo.note}
                      onChange={handleOnChange}
                      label="Note"
                      type="text"
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select branch
                    </InputLabel>
                    <Select
                      required
                      labelId="branch-select-id-label"
                      id="branch-select-id"
                      label="Branch"
                      onChange={handleBranch}
                    >
                      {branchForSearch &&
                        branchForSearch.map((branch) => {
                          return (
                            <MenuItem value={branch.id}>{branch.name}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Stack>
                      <DatePicker
                        required
                        label="Date"
                        value={rsvDate}
                        onChange={setrsvDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="start-time-select-label">
                      Starting time
                    </InputLabel>
                    <Select
                      required
                      labelId="start-time-select-label"
                      id="start-time-select"
                      value={startTime}
                      label="Starting time"
                      onChange={handlTime}
                    >
                      {schedule &&
                        schedule.map((time) => {
                          return (
                            <MenuItem value={time.time}>{time.time}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={2} direction="row">
                    <Button type="submit" variant="contained" color="primary">
                      submit request
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
              {/* </form> */}
            </Container>

            {/* <div className="mt-4">
              <span>Today available time</span>
              <div className="d-flex justify-content-center">
                <span className="btn d-inline-block px-4 pt-2 m-3 bg-primary text-white rounded">
                  4.30 PM
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddParty;
