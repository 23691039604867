import React, { useEffect, useContext, useState } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  pageLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { WithContext as ReactTags } from 'react-tag-input';

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { NavLink } from "react-router-dom";
import { MdEdit, MdDelete } from "react-icons/md";
import { BiX } from "react-icons/bi";
import { getReservationById, getAvalilableTables, cancelReservationById, getCustomerByMobile } from "./requests"
import { date } from "yup";

let reserveTags = [
    'BirthDay Party',
    'Reunion',
    'Family Party'
]

const suggestions = reserveTags.map(country => {
    return {
      id: country,
      text: country,
      toString: function(){return this.text;}
    };
});

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const ReservationList = () => {

  var today = new Date();
  var date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();  

  var weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  //getting context values here
  const {
    //common
    loading,
    setLoading,
    dataPaginating,
    showManageStock,
  } = useContext(SettingsContext);


  const { authUserInfo } = useContext(UserContext);
  const [orderDate, setOrderDate] = useState(null);
  const [isPhoneEmpty, setIsPhoneEmpty] =  useState(true);
  const [isGuestEmpty, setIsGuestEmpty] =  useState(true);
  const [isDateEmpty, setIsDateEmpty] =  useState(true);
  const [isSlotEmpty, setIsSlotEmpty] =  useState(true);
  const [isAdultEmpty, setIsAdultEmpty] =  useState(true);
  const [isTotalEmpty, setIsTotalEmpty] =  useState(true);
  const [formStatus, setFormStatus] =  useState(false);
  const [editId, setEditId] = useState('');
  const [resId, setResId] = useState('');
  const [availableTables, setAvailableTables] = useState([]);
  const [reservationById, setreservationById] = useState(null);

  console.log('authUserInfo', authUserInfo)

  const {
    //branch
    branchForSearch,
    tableForSearch,

    //work period
    getWorkPeriod,
    workPeriodList,
    setWorkPeriodList,
    setPaginatedWorkPeriod,
    workPeriodForSearch,
    setWorkPeriodListForSearch,

    // booking slot
    bookingSlotForSearch,

    reservationList,
    setReservationList,
    setPaginatedReservations,
    reservationListForSearch,
    setReservationListForSearch,
  } = useContext(RestaurantContext);

  const { t } = useTranslation();

  console.log('reservationById', reservationList)

  //new unit
  let [newWorkPeriod, setNewWorkPeriod] = useState({
    user_type: null,
    branch_id: null,
    uploading: false,
  });

  let [newReservation, setNewReservation] = useState({
    mobile_number: null,
    guest_name: null,
    reservation_datetime: null,
    table_id: null,
    slot_id: null,
    total_guests: null,
    no_of_adults: null,
    no_of_kids: null,
    no_of_male: null,
    no_of_female: null,
    reservation_status: '1',
    reserved_by: null,
    tags: null,
    comments: null,
    advance: null,
    branch_id: null
  });

  const reservationEdit = async (id) => {
    setEditId(id)
    const response = await getReservationById(id)
    console.log('response', response.all_reservations[0])
    // setOrderDate(response.all_reservations[0].reservation_datetime)
    function toTimestamp(strDate){
      var datum = Date.parse(strDate);
      return datum;
   }
   setreservationById(response.all_reservations[0])
    setOrderDate(toTimestamp(response.all_reservations[0].reservation_datetime))
    setNewReservation({
      mobile_number: response.all_reservations[0].mobile_number,
      guest_name: response.all_reservations[0].guest_name,
      reservation_datetime: response.all_reservations[0].reservation_datetime,
      table_id: response.all_reservations[0].table_id,
      slot_id: response.all_reservations[0].slot_id,
      total_guests: response.all_reservations[0].total_guests,
      no_of_adults: response.all_reservations[0].no_of_adults,
      no_of_kids: response.all_reservations[0].no_of_kids,
      no_of_male: response.all_reservations[0].no_of_male,
      no_of_female: response.all_reservations[0].no_of_female,
      reservation_status: response.all_reservations[0].reservation_status,
      reserved_by: response.all_reservations[0].reserved_by,
      tags: response.all_reservations[0].tags,
      comments: response.all_reservations[0].comments,
      advance: response.all_reservations[0].advance,
      branch_id: response.all_reservations[0].branch_id,
    })
    availableTablelist(id, moment(response.all_reservations[0].reservation_datetime).format('DD-MM-YYYY'), response.all_reservations[0].slot_id);
  }

  //search result
  let [searchedWorkPeriod, setSearchedWorkPeriod] = useState({
    list: null,
    searched: false,
  });

  const availableTablelist = async (id, date, slot) => {
    const response = await getAvalilableTables(id, date, slot)
    setAvailableTables(response)
  }

  // useEffect(() => {
  //   availableTablelist
  // }, []);

  //useEffect == componentDidMount
  useEffect(() => {
    
    let resTagString = resTags.join(',');
    authUserInfo.details &&
      setNewWorkPeriod({
        branch_id: authUserInfo.details.branch_id
          ? authUserInfo.details.branch_id
          : null,
        user_type: authUserInfo.details.user_type,
      });
      setNewReservation({
        ...newReservation,
        reserved_by: authUserInfo.details.name,
        tags: resTagString
      });
      console.log('reservationList -fdfdf');
      console.log(reservationList);
      console.log(workPeriodList);
  }, [authUserInfo]);

  //set branch id
  const handleSetBranchId = (branch) => {
    setNewWorkPeriod({
      ...newWorkPeriod,
      branch_id: branch.id,
    });
    setNewReservation({
      ...newReservation,
      branch_id: branch.id,
    });
    
  };

  //search work periods here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedWorkPeriod({ ...searchedWorkPeriod, searched: false });
    } else {
      let searchedList = reservationListForSearch.filter((item) => {
        let lowerCaseItemGuestName = item.guest_name.toLowerCase();
        let lowerCaseItemMobile = item.mobile_number.toLowerCase();
        let lowerCaseTags = item.tags.toLowerCase();
        let dateRes = moment(item.reservation_datetime).format('DD-MM-YYYY');
        return (
          lowerCaseItemGuestName.includes(searchInput) ||
          lowerCaseItemMobile.includes(searchInput) ||
          lowerCaseTags.includes(searchInput) ||
          dateRes.includes(searchInput) 
        );
      });
      setSearchedWorkPeriod({
        ...searchedWorkPeriod,
        list: searchedList,
        searched: true,
      });
    }
  };

  //Save New work period
  const handleSaveNewWorkPeriod = (e) => {
    e.preventDefault();
    if (newWorkPeriod.branch_id) {
      setNewWorkPeriod({
        ...newWorkPeriod,
        uploading: true,
      });
      const url = BASE_URL + `/settings/new-work-period`;
      let date =
        weekday[new Date().getDay()] +
        ", " +
        new Date().getDate() +
        " " +
        month[new Date().getMonth()] +
        ", " +
        new Date().getFullYear();

      let time = new Date().getTime();

      let formData = {
        date: date,
        branch_id: newWorkPeriod.branch_id,
        started_at: time,
      };
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data === "exist") {
            authUserInfo.details &&
              setNewWorkPeriod({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
              });
            toast.error(
              `${_t(t("Please end the started work period first"))}`,
              {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              }
            );
          } else {
            authUserInfo.details &&
              setNewWorkPeriod({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
              });
            setReservationList(res.data[0]);
            setWorkPeriodListForSearch(res.data[1]);
            setSearchedWorkPeriod({
              ...searchedWorkPeriod,
              list: res.data[1],
            });
            setLoading(false);
            toast.success(`${_t(t("Work period has been started"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        })
        .catch(() => {
          setLoading(false);
          setNewWorkPeriod({
            ...newWorkPeriod,
            uploading: false,
          });
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //milisec to hour min
  const millisToMinutesAndHours = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var hours = Math.floor(minutes / 60);
    minutes = Math.floor(minutes % 60);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${hours} ${_t(t("hr"))} - ${minutes} ${_t(t("min"))} - ${seconds < 10 ? "0" : ""
      }${seconds} ${_t(t("sec"))}`;
  };

  //end confirmation modal of workPeriod
  const handleDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to end now?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleEndWorkPeriod(id);
                  onClose();
                }}
              >
                {_t(t("Yes, end work period!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  
  //end here
  const handleEndWorkPeriod = (id) => {
    setNewWorkPeriod({
      ...newWorkPeriod,
      uploading: true,
    });
    const url = BASE_URL + `/settings/update-work-period`;
    let time = new Date().getTime();
    let formData = {
      id: id,
      ended_at: time,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data !== "orderExist" && res.data !== "addClosing") {
          authUserInfo.details &&
            setNewWorkPeriod({
              branch_id: authUserInfo.details.branch_id
                ? authUserInfo.details.branch_id
                : null,
              user_type: authUserInfo.details.user_type,

              uploading: false,
            });
          setWorkPeriodList(res.data[0]);
          setWorkPeriodListForSearch(res.data[1]);
          setSearchedWorkPeriod({
            ...searchedWorkPeriod,
            list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Work period has been ended"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          if (res.data === "addClosing") {
            toast.error(`${_t(t("Add closing stock of ingredients"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          } else {
            toast.error(
              `${_t(
                t("All submitted orders need to be settled to end workperiod")
              )}`,
              {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              }
            );
          }
          setLoading(false);
          setNewWorkPeriod({
            ...newWorkPeriod,
            uploading: false,
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNewWorkPeriod({
          ...newWorkPeriod,
          uploading: false,
        });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  // console.log('Today', moment(newReservation?.reservation_datetime).format('DD-MM-YYYY'), moment(today).format('DD-MM-YYYY'))

  const [resTags, setResTags] = React.useState([
    // { id: 'BirthDay Party', text: 'BirthDay Party', toString: function(){return this.text;}},
    // { id: 'Reunion', text: 'Reunion', toString: function(){return this.text;}},
    // { id: 'Family Party', text: 'Family Party', toString: function(){return this.text;}},
  ]);

  const handleTagDelete = i => {
    let newTags = [];
    console.log(i);
    setResTags(resTags.filter((resTag, index) => {
        console.log(resTag)
        console.log(resTag[index])
        if(index !== i){
            newTags.push(resTag)
        }
        return index !== i;
    }));
    console.log(resTags);
    console.log(newTags);
    
    let resTagString = newTags.join(',');
    setNewReservation({
        ...newReservation,
        tags: resTagString
    });
  };  

  const handleAddition = resTag => {
    let newTags = [];
    Object.assign(resTag, {toString: function(){return this.text;}});
    setResTags([...resTags, resTag]);
    newTags.push(resTag);
    let newArr = [...resTags, ...newTags]; 
    let resTagString = newArr.join(',');
    setNewReservation({
        ...newReservation,
        tags: resTagString
    });
  };

  const handleDrag = (resTag, currPos, newPos) => {
    const newTags = resTags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, resTag);
    
    let resTagString = newTags.join(',');
    setNewReservation({
        ...newReservation,
        tags: resTagString
    });

    // re-render
    setResTags(newTags);
  };

  const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const handleSetTable = async(table) => {
    console.log('table change');
    console.log(table.status);
    setNewReservation({
        ...newReservation,
        table_id : table.id
    })
  }

  const handleSetSlot = async(slot) => {
    console.log('table change');
    console.log(slot);
    setNewReservation({
        ...newReservation,
        slot_id : slot
    })
  }

  const setMobile = async (e) => {
    console.log('wrebgifvugr', e.target.value);
    setNewReservation({
        ...newReservation,
        mobile_number : e.target.value.slice(0, 10)
    })
    if(e.target.value.toString().length != 10){
      setIsPhoneEmpty(false);
    } else {
      setIsPhoneEmpty(true);
    }    
    if(e.target.value.toString().length >= 10) {
      const response = await getCustomerByMobile(e.target.value.slice(0, 10))
      console.log('customerName', response)
      if(response.customer_details.length != 0) {
        setNewReservation({
          ...newReservation,
          mobile_number : response.customer_details[0].phn_no,
          guest_name : response.customer_details[0].name
      })
      if(response.customer_details[0].name != ''){
        setIsGuestEmpty(false);
      } else {
        setIsGuestEmpty(true);
      }
      }
    }    
  }

  const setGuestName = (e) => {
    console.log(e.target.value);
    setNewReservation({
        ...newReservation,
        guest_name : e.target.value
    })
    if(e.target.value != ''){
      setIsGuestEmpty(false);
    } else {
      setIsGuestEmpty(true);
    }
  }

  const setAdultCount = (e) => {
    console.log(e.target.value);
    setNewReservation({
        ...newReservation,
        no_of_adults : e.target.value
    })
    if(e.target.value != ''){
      setIsAdultEmpty(false);
    } else {
      setIsAdultEmpty(false);
    }
  }

  const setTotalCount = (e) => {
    console.log(e.target.value);
    setNewReservation({
        ...newReservation,
        total_guests : e.target.value
    })
    if(e.target.value != ''){
      setIsTotalEmpty(false);
    } else {
      setIsTotalEmpty(true);
    }
  }

  const handleSetNewReservation = (e) => {
    console.log('startusValue', e.target.value)
    setNewReservation({ ...newReservation, [e.target.name]: e.target.value });
  };


  const handleSaveNewReservation = async () => {
    // let resDate = orderDate != null ? moment(orderDate).format('YYYY-MM-DD hh:mm:ss'): null;
    
    console.log(newReservation);

    const url = BASE_URL + `/settings/add-reservation`;

    if(newReservation.guest_name == null || newReservation.guest_name == ""){
      setIsGuestEmpty(true);
    } else {
      setIsGuestEmpty(false);
    }

    if(newReservation.mobile_number == null || newReservation.mobile_number.toString().length != 10){
      setIsPhoneEmpty(false);
    } else {
      setIsPhoneEmpty(true);
    }

    if(newReservation.reservation_datetime == null || newReservation.reservation_datetime == ""){
      setIsDateEmpty(true);
    } else {
      setIsDateEmpty(false);
    }

    if(newReservation.slot_id == null || newReservation.slot_id == ""){
      setIsSlotEmpty(true);
    } else {
      setIsSlotEmpty(false);
    }

    if(newReservation.no_of_adults == null || newReservation.no_of_adults == ""){
      setIsAdultEmpty(true);
    } else {
      setIsAdultEmpty(false);
    }

    if(
      newReservation.mobile_number != null && newReservation.mobile_number.toString().length == 10 &&
      newReservation.guest_name != null && newReservation.guest_name != '' &&
      newReservation.reservation_datetime != null && newReservation.reservation_datetime != '' &&
      newReservation.slot_id != null && newReservation.slot_id != ''
    ) {
      console.log('fields filled');
      setLoading(true);
      const branch = authUserInfo.details.user_type == "staff" ? authUserInfo.details.branch_id : newReservation.branch_id;
      const formData = {
        "branch_id": branch?.toString(),
        "mobile_number": newReservation.mobile_number,
        "guest_name": newReservation.guest_name,
        "tags": newReservation.tags,
        "total_guests": newReservation.total_guests,
        "reservation_datetime": newReservation.reservation_datetime,
        "no_of_adults": newReservation.no_of_adults,
        "no_of_kids": newReservation.no_of_kids,
        "no_of_male": newReservation.no_of_male,
        "no_of_female": newReservation.no_of_female,
        "reservation_status": newReservation.reservation_status,
        "reserved_by": newReservation.reserved_by,
        "table_id": newReservation.table_id,
        "slot_id": newReservation.slot_id,
        "comments": newReservation.comments,        
        "advance": newReservation.advance,        
      }

      return await axios
          .post(url, formData, {
              headers: { Authorization: `Bearer ${getCookie()}` },
          }).then((res) => {
              console.log('slotsss res');
              console.log(res.data);
              setLoading(false);
              if(res.data.status == 200 || res.data.status == 201){
                setOrderDate(null);
                document.getElementById('closeForm12312323')?.click();
                setNewReservation({
                  mobile_number: null,
                  guest_name: null,
                  reservation_datetime: null,
                  table_id: null,
                  slot_id: null,
                  total_guests: null,
                  no_of_adults: null,
                  no_of_kids: null,
                  no_of_male: null,
                  no_of_female: null,
                  reservation_status: null,
                  reserved_by: null,
                  tags: null,
                  advance: null,
                  comments: null
                })
                toast.success(`${_t(t(res.data.message))}`, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
              } else {
                toast.warning(`${_t(t("Check the form or server"))}`, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
              }
              
          }).catch((error) => {
              console.log(error);
              setLoading(false);
              toast.error(`${_t(t("Server Error"))}`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",                
              });
          })
    } else {
      console.log('fields empty');
      toast.error(`${_t(t("Field Empty"))}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
    
  }
  const handleUpdateNewReservation = async () => {
    // let resDate = orderDate != null ? moment(orderDate).format('YYYY-MM-DD hh:mm:ss'): null;
    
    console.log(newReservation);

    const url = BASE_URL + `/settings/update-reservation`;

    if(newReservation.guest_name == null || newReservation.guest_name == ""){
      setIsGuestEmpty(true);
    } else {
      setIsGuestEmpty(false);
    }

    if(newReservation.mobile_number == null || newReservation.mobile_number == ""){
      setIsPhoneEmpty(true);
    } else {
      setIsPhoneEmpty(false);
    }

    if(newReservation.reservation_datetime == null || newReservation.reservation_datetime == ""){
      setIsDateEmpty(true);
    } else {
      setIsDateEmpty(false);
    }

    if(newReservation.slot_id == null || newReservation.slot_id == ""){
      setIsSlotEmpty(true);
    } else {
      setIsSlotEmpty(false);
    }

    if(
      newReservation.mobile_number != null && newReservation.mobile_number.toString().length == 10 &&
      newReservation.guest_name != null && newReservation.guest_name != '' &&
      newReservation.reservation_datetime != null && newReservation.reservation_datetime != '' &&
      newReservation.slot_id != null && newReservation.slot_id != '' 
    ) {
      console.log('fields filled');
      setLoading(true);
      const formData = {
        "mobile_number": newReservation.mobile_number,
        "guest_name": newReservation.guest_name,
        "tags": newReservation.tags,
        "reservation_datetime": newReservation.reservation_datetime,
        "total_guests": newReservation.total_guests,
        "no_of_adults": newReservation.no_of_adults,
        "no_of_kids": newReservation.no_of_kids,
        "no_of_male": newReservation.no_of_male,
        "no_of_female": newReservation.no_of_female,
        "reservation_status": newReservation.reservation_status,
        "reserved_by": newReservation.reserved_by,
        "table_id": newReservation.table_id,
        "slot_id": newReservation.slot_id,
        "comments": newReservation.comments,
        "advance": newReservation.advance,
        "branch_id": newReservation.branch_id
      }

      return await axios
          .put(url+'/'+editId, formData, {
              headers: { Authorization: `Bearer ${getCookie()}` },
          }).then((res) => {
              console.log('slotsss res');
              console.log(res.data);
              setLoading(false);
              if(res.data.status == 200 || res.data.status == 201){
                setOrderDate(null);
                document.getElementById('closeForm12312323')?.click();
                setNewReservation({
                  mobile_number: null,
                  guest_name: null,
                  reservation_datetime: null,
                  table_id: null,
                  slot_id: null,
                  no_of_adults: null,
                  no_of_kids: null,
                  no_of_male: null,
                  no_of_female: null,
                  reservation_status: null,
                  reserved_by: null,
                  tags: null,
                  comments: null
                })
                toast.success(`${_t(t(res.data.message))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
              } else {
                toast.warning(`${_t(t("Check the form or server"))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
              }
              
          }).catch((error) => {
              console.log(error);
              setLoading(false);
              toast.error(`${_t(t("Server Error"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
          })
    } else {
      console.log('fields empty');
    }
    
  }

  const reserveCancel = async () => {
    const response = await cancelReservationById(resId);
    if(response.status == 200) {
      document.getElementById('cancelReserve')?.click();
      toast.success(`${_t(t("Reservation Canceled"))}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }

  // document.getElementById('#enquiry_form')?.validate({
  //   phone:{
  //   required:true,
  //   minlength:10,
  //   maxlength:10,
  //   number: true
  //   },})

  return (
    <>
      <Helmet>
        <title>{_t(t("Reservation List"))}</title>
      </Helmet>

        {/* Add modal */}
        <div className="modal fade" id="addReservation" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header align-items-center">
                <div className="fk-sm-card__content">
                    <h5 className="text-capitalize fk-sm-card__title">
                    {
                    formStatus
                      ? _t(t("Update Reservation")) : _t(t("Create New Reservation"))
                    }
                    </h5>
                </div>
                <button
                    type="button" id="closeForm12312323"
                    className="btn-close"
                    onClick={() => {
                      setFormStatus(false);
                    //   setToTimeEdit(false);
                    setResTags([]);
                    setOrderDate(null);
                    setNewReservation({
                      mobile_number: null,
                      guest_name: null,
                      reservation_datetime: null,
                      table_id: null,
                      slot_id: null,
                      total_guests: null,
                      no_of_adults: null,
                      no_of_kids: null,
                      no_of_male: null,
                      no_of_female: null,
                      reservation_status: null,
                      reserved_by: null,
                      tags: null,
                      comments: null,
                      advance: null,
                      branch_id: null
                    });
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                ></button>
                </div>
                <div className="modal-body">
                {/* show form or show saving loading */}
                { 
                // newBookSlots.uploading === false ? (
                    <div key="fragment-customer-1">
                    <form name="reg_form" id="reg_form">
                        <div className="reserve_form row">
                          <div className="col-12 t-mb-15 mb-3">
                              {authUserInfo.details !== null &&
                                authUserInfo.details.user_type !== "staff" ? (
                                <>
                                    <Select
                                      options={branchForSearch && branchForSearch}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      classNamePrefix="select"
                                      className="xsm-text"
                                      onChange={handleSetBranchId}
                                      maxMenuHeight="200px"
                                      placeholder={
                                        _t(t("Please select a branch")) + ".."
                                      }
                                    />
                                </>
                              ) : (<></>
                              )}
                          </div>
                            <div className="form-group col-md-6 mb-2">
                                <label for="phone">Mobile Number *</label>
                                <input type="number" maxLength={10} className={`${newReservation.mobile_number == null || newReservation.mobile_number.toString().length != 10 ? "form-control is-invalid": "form-control is-valid"}`} onChange={setMobile} value={newReservation.mobile_number || ""} placeholder="Enter Phone" id="phone" name="phone"/>
                                {newReservation.mobile_number == null || newReservation.mobile_number == '' && <small className="error_text invalid-feedback">Please fill this field</small>}
                            </div>
                            <div className="form-group col-md-6 mb-2">
                                <label for="guest_name">Guest Name *</label>
                                <input type="text" className={`${newReservation.guest_name == null || newReservation.guest_name == '' ? "form-control is-invalid": "form-control is-valid"}`} onChange={setGuestName} value={newReservation.guest_name || ""} placeholder="Enter Name" id="guest_name"/>
                                {newReservation.guest_name == null || newReservation.guest_name == '' && <small className="error_text invalid-feedback">Please fill this field</small>}
                            </div>
                            <div className="form-group col-md-4 mb-2">
                                <label for="order_date">Order Date</label>
                                <div className={`${orderDate == null ? "is-invalid": "is-valid"}`}>
                                  <DatePicker
                                      selected={orderDate}
                                      onChange={(date) => {
                                          setOrderDate(date)
                                          setNewReservation({
                                              ...newReservation,
                                              reservation_datetime : moment(date).format('YYYY-MM-DD hh:mm:ss')
                                          })
                                          availableTablelist(editId, moment(date).format('DD-MM-YYYY'), newReservation.slot_id);
                                          setIsDateEmpty(false);
                                      }}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      minDate={moment().toDate()}
                                      dropdownMode="select"
                                      className={`${orderDate == null ? "form-control xsm-text py-2 is-invalid": "form-control xsm-text py-2 is-valid"}`}
                                      placeholderText={_t(t("Order Date"))}
                                      shouldCloseOnSelect={false}
                                  />
                                </div>
                                {orderDate == null && <small className="error_text invalid-feedback">Please fill this field</small>}
                            </div>
                            <div className="form-group col-md-4 mb-2">
                                <label for="guest">Time Slot</label>
                                {/* <Select
                                    options={
                                      bookingSlotForSearch !== null &&
                                      bookingSlotForSearch
                                    }
                                    components={makeAnimated()}
                                    // defaultValue={}
                                    getOptionLabel={(option) => option.in_time}
                                    getOptionValue={(option) => option.in_time}
                                    classNamePrefix="select"
                                    className={`${isSlotEmpty ? "xsm-text is-invalid": "xsm-text is-valid"}`}
                                    onChange={(val) => { 
                                      handleSetSlot(val)
                                      setIsSlotEmpty(false);
                                    }}
                                    maxMenuHeight="100px"
                                    placeholder={_t(t("Time Slots")) + ".."}
                                /> */}
                                <select className={`${newReservation.slot_id == '' || newReservation.slot_id == null ? "form-select xsm-text is-invalid": "form-select xsm-text is-valid"}`} value={newReservation.slot_id || ""} onChange={(e) => { 
                                      handleSetSlot(e.target.value);
                                      availableTablelist(editId, moment(newReservation.reservation_datetime).format('DD-MM-YYYY'), e.target.value);
                                      setIsSlotEmpty(false);
                                    }}>
                                      <option disabled value=''>Select Slot</option>
                                      {bookingSlotForSearch.map((option, i) => {
                                        return(
                                  <option value={option.id} key={i}>{option.in_time}</option>)})}
                                </select>
                                {newReservation.slot_id == '' || newReservation.slot_id == null && <small className="error_text invalid-feedback">Please fill this field</small>}
                            </div>
                            {formStatus && 
                            moment(newReservation.reservation_datetime).format('DD-MM-YYYY') == moment(today).format('DD-MM-YYYY') &&
                            <div className="form-group col-md-4 mb-2">
                                <label for="guest">Table</label>
                                <Select
                                    options={
                                      availableTables !== null &&
                                      availableTables
                                    }
                                    components={makeAnimated()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.name}
                                    classNamePrefix="select"
                                    className="xsm-text is-invalid"
                                    onChange={(val) => { 
                                        handleSetTable(val)
                                    }}
                                    maxMenuHeight="100px"
                                    placeholder={_t(t("Table")) + ".."}
                                />
                            </div>}
                            <div className="form-group col-md-4 mb-2">
                                <label for="adults_count">No. of Guests</label>
                                <input type="text" className={`${newReservation.total_guests == null || newReservation.total_guests == '' ? "form-control is-invalid": "form-control is-valid"}`} onChange={setTotalCount} value={newReservation.total_guests || ""} placeholder="0" id="count"/>
                                {newReservation.total_guests == null || newReservation.total_guests == '' && <small className="error_text invalid-feedback">Please fill this field</small>}
                            </div>
                            {formStatus && <>
                            <div className="form-group col-md-4 mb-2">
                                <label for="adults_count">No. of Adults</label>
                                <input type="text" className= "form-control" onChange={setAdultCount} value={newReservation.no_of_adults || ""} placeholder="0" id="adults_count"/>
                                {/* {newReservation.no_of_adults == null || newReservation.no_of_adults == '' && <small className="error_text invalid-feedback">Please fill this field</small>} */}
                            </div>
                            <div className="form-group col-md-4 mb-2">
                                <label for="kids_count">No. of Kids</label>
                                <input type="text" className="form-control" name="no_of_kids" value={newReservation.no_of_kids || ""} onChange={handleSetNewReservation} placeholder="0" id="kids_count"/>
                            </div>
                            <div className="form-group col-md-4 mb-2">
                                <label for="males_count">No. of Males</label>
                                <input type="text" className="form-control" name="no_of_male" value={newReservation.no_of_male || ""} onChange={handleSetNewReservation} placeholder="0" id="males_count"/>
                            </div>
                            <div className="form-group col-md-4 mb-2">
                                <label for="females_count">No. of Females</label>
                                <input type="text" className="form-control" name="no_of_female" value={newReservation.no_of_female || ""} onChange={handleSetNewReservation} placeholder="0" id="females_count"/>
                            </div> </>}
                            <div className="form-group col-md-4 mb-2">
                                <label for="reservation_by">Reserved By</label>
                                <input type="text" className="form-control" name="reserved_by" value={newReservation.reserved_by || ""} onChange={handleSetNewReservation} placeholder="Reserved By" id="reservation_by"/>
                            </div>
                            <div className="form-group col-md-4 mb-2">
                                <label for="reservation_by">Advance Amount</label>
                                <input type="number" className="form-control" name="advance" value={newReservation.advance || ""} onChange={handleSetNewReservation} placeholder="Advance" id="advance"/>
                            </div>
                            <div className="form-group col-md-4 mb-2">
                                <label for="reservation_status">Reserved Status</label>
                                {/* <input type="text" className="form-control" name="reservation_status" value={newReservation.reservation_status || ""} onChange={handleSetNewReservation} placeholder="status" id="reservation_status"/> */}
                                <select className="form-select xsm-text" value={newReservation.reservation_status || ""} onChange={handleSetNewReservation} name="reservation_status" id="reservation_status" >
                                  <option value="1">Waitlisted</option>
                                  <option value="0">Cancelled</option>                                  
                                  <option value="2">Arrived</option>
                                  <option value="3">Confirmed</option>
                                  <option value="4">Partial Seated</option>
                                </select>
                            </div>
                            <div className="form-group col-md-12 col-12 sm_tags mb-2">
                                <label>Tags</label>
                                <ReactTags
                                    tags={resTags}
                                    suggestions={suggestions}
                                    delimiters={delimiters}
                                    handleDelete={handleTagDelete}
                                    handleAddition={handleAddition}
                                    handleDrag={handleDrag}
                                    handleTagClick={handleTagClick}
                                    inputFieldPosition="bottom"
                                    autocomplete
                                />
                            </div>
                            <div className="form-group col-md-12 col-12 mb-2">
                                <label for="comment">Comments</label>
                                <textarea className="form-control" name="comments" value={newReservation.comments || ""} onChange={handleSetNewReservation} rows="3" id="comment"></textarea>
                            </div>
                        </div>
                        <div className="mt-4">
                        <div className="row">
                            <div className="col-6">
                            <button
                                type="button"
                                onClick={
                                      formStatus
                                      ? handleUpdateNewReservation :
                                      handleSaveNewReservation                                    
                                    }
                                className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                            >
                                {
                                // !newBookSlots.edit?
                                 _t(t("Save"))
                                // : _t(t("Update"))
                                }
                            </button>
                            </div>
                            <div className="col-6">
                            <button
                                type="button"
                                className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                                onClick={() => {
                                  setFormStatus(false);
                                //   setToTimeEdit(false);
                                setOrderDate(null);
                                setResTags([]);
                                setNewReservation({
                                  mobile_number: null,
                                  guest_name: null,
                                  reservation_datetime: null,
                                  table_id: null,
                                  slot_id: null,
                                  total_guests: null,
                                  no_of_adults: null,
                                  no_of_kids: null,
                                  no_of_male: null,
                                  no_of_female: null,
                                  reservation_status: null,
                                  reserved_by: null,
                                  tags: null,
                                  comments: null,
                                  advance: null,
                                  branch_id: null
                                });
                                }}
                                data-dismiss="modal"
                            >
                                {_t(t("Close"))}
                            </button>
                            </div>
                        </div>
                        </div>
                    </form>
                    </div>
                }
                </div>
            </div>
            </div>
        </div>
        <div className='modal fade' id={'cancelReservation'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Confirmation</h3>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal'>
                      <BiX size={20}/>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <p>Are you sure want to Cancel?</p>
                    <div className='d-flex align-items-center justify-content-end'>
                        <button className='btn btn-sm btn-secondary mt-3 mx-3' id="cancelReserve" data-dismiss='modal'>
                            No
                        </button>
                        <button className='btn btn-sm text-primary mt-3' 
                        onClick={reserveCancel}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
        {/* Add modal Ends*/}

      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {newWorkPeriod.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white sm_shadow sm_card expense_card">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row t-mt-10 gx-2 align-items-center t-pt-15 t-pl-15 t-pr-15">
                    <div className="col-12">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {!searchedWorkPeriod.searched
                              ? _t(t("Reservation List"))
                              : _t(t("Search Result"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="col-md-12 col-lg-5">
                      <div className="input-group">
                        <button className="btn btn-primary" type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="form-file">
                          <input
                            type="text"
                            className="form-control border-0 form-control--light-1 rounded-0"
                            placeholder={
                              _t(t("Search by branch, date, user")) + ".."
                            }
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-12">
                      <div className="row gx-2 align-items-end justify-content-end">
                        {authUserInfo.details !== null &&
                          authUserInfo.details.user_type !== "staff" ? (
                          <>
                            <div className="">
                              <Select
                                options={branchForSearch && branchForSearch}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                classNamePrefix="select"
                                className="xsm-text"
                                onChange={handleSetBranchId}
                                maxMenuHeight="200px"
                                placeholder={
                                  _t(t("Please select a branch")) + ".."
                                }
                              />
                            </div>
                            <div className="">
                              <ul className="t-list fk-sort align-items-right justify-content-right">
                                <li className="fk-sort__list mb-0 flex-grow-1">
                                  <button
                                    data-toggle="modal"
                                    data-target="#addReservation"
                                    // onClick={handleSaveNewWorkPeriod}
                                    className="w-50 btn btn-dark sm-text text-uppercase"
                                  >
                                    {_t(t("Create Reservation"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : (
                          <div className="col-12 col-md-5 mt-2 mt-md-0 text-right">
                            <ul className="t-list fk-sort align-items-right justify-content-right">
                              <li className="fk-sort__list mb-0 flex-grow-1">
                                <button
                                    data-toggle="modal"
                                    data-target="#addReservation"
                                  // onClick={() => {
                                  //   setNewReservation({
                                  //     ...newReservation,
                                  //     reserved_by: authUserInfo.details.name
                                  //   });}
                                  // }
                                  onClick={() => {
                                    setNewReservation({ ...newReservation, ['reserved_by']: authUserInfo.details.name })
                                  }}
                                  className="w-50 btn btn-dark sm-text text-uppercase"
                                >
                                  {_t(t("Create Reservation"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fk-scroll--order-history" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="overflow-auto">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Guest Name"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Mobile"))}
                              </th>
                              {/* <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Tags"))}
                              </th> */}
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Reservation Date"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Time Slot"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Table"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Status"))}
                              </th>
                              {/* {showManageStock ? <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Stock"))}
                              </th> : null} */}
                              {/* <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("No of Adults"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("No of Kids"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("No of Male"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("No of Female"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Comments"))}
                              </th> */}

                              {/* <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Created Date"))}
                              </th> */}
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Actions"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {!searchedWorkPeriod.searched
                              ? [
                                reservationList && [
                                  reservationList.data.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="8"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    reservationList.data.map((item, index) => {
                                      return (
                                        <tr
                                          className="align-middle"
                                          key={index}
                                        >
                                          <th
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {index +
                                              1 +
                                              (reservationList.current_page -
                                                1) *
                                                reservationList.per_page}
                                          </th>

                                          <td className="xsm-text align-middle text-center text-secondary">
                                            {item.guest_name}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.mobile_number}
                                          </td>

                                          {/* <td className="xsm-text align-middle text-center">
                                            {item.tags}
                                          </td> */}

                                          <td className="xsm-text align-middle text-center text-green">
                                          {moment(item.reservation_datetime).format('DD-MM-YYYY')}
                                          </td>

                                          <td className="xsm-text align-middle text-center text-secondary">
                                            {item.in_time != null ? item.in_time : '-'}
                                          </td>

                                          <td className="xsm-text align-middle text-center text-secondary">
                                            {item.table_name}
                                          </td>

                                          <td className="xsm-text align-middle text-center text-secondary">
                                            {item.reservation_status == 0 ? <button
                                                className="btn btn-primary xsm-text text-center py-0 px-4 status_button"
                                                // onClick={() => {
                                                //   handleDeleteConfirmation(
                                                //     item.id
                                                //   );
                                                // }}
                                              >
                                                {_t(t("Canceled"))}
                                              </button> : item.reservation_status == 1 ? <button
                                                className="btn btn-warning xsm-text text-center py-0 px-4 status_button"
                                                // onClick={() => {
                                                //   handleDeleteConfirmation(
                                                //     item.id
                                                //   );
                                                // }}
                                              >
                                                {_t(t("Waitlisted"))}
                                              </button> : item.reservation_status == 2 ? <button
                                                className="btn btn-success xsm-text text-center py-0 px-4 status_button"
                                                // onClick={() => {
                                                //   handleDeleteConfirmation(
                                                //     item.id
                                                //   );
                                                // }}
                                              >
                                                {_t(t("Arrived"))}
                                              </button> : item.reservation_status == 3 ? <button
                                                className="btn btn-info xsm-text text-center py-0 px-4 status_button"
                                                // onClick={() => {
                                                //   handleDeleteConfirmation(
                                                //     item.id
                                                //   );
                                                // }}
                                              >
                                                {_t(t("Confirmed"))}
                                              </button> : <button
                                                className="btn btn-secondary text-center xsm-text py-0 px-4 status_button"
                                                // onClick={() => {
                                                //   handleDeleteConfirmation(
                                                //     item.id
                                                //   );
                                                // }}
                                              >
                                                {_t(t("Partial Seated"))}
                                              </button>}
                                          </td>

                                          <td className="xsm-text align-middle text-center text-secondary">
                                          <button 
                                              data-toggle="modal"
                                              data-target={"#addReservation"}
                                              onClick={() => {
                                                setFormStatus(true);
                                                reservationEdit(item.id);
                                              }}
                                              type="button" className="btn btn-sm btn-outline-primary border-0 mx-1"
                                              >
                                                <MdEdit size={20}/>
                                              </button>
                                              <button 
                                                data-toggle="modal"
                                                data-target={"#cancelReservation"}
                                                onClick={() => setResId(item.id)}
                                                type="button" className="btn btn-sm btn-outline-danger border-0 mx-1"
                                                >
                                                  <MdDelete size={20}/>
                                              </button>
                                          </td>

                                          {/* <td className="xsm-text align-middle text-center">
                                            {item.no_of_adults}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.no_of_kids}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.no_of_male}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.no_of_female}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.comments}
                                          </td> */}

                                          {/* <td className="xsm-text align-middle text-center text-primary">
                                            {item.ended_at ? (
                                              <Moment format="LT">
                                                {new Date(item.created_at)}
                                              </Moment>
                                            ) : (
                                              "-"
                                            )}
                                          </td>

                                          <td className="xsm-text align-middle text-center text-primary">
                                            {item.ended_at ? (
                                              <Moment format="LT">
                                                {new Date(item.updated_at)}
                                              </Moment>
                                            ) : (
                                              "-"
                                            )}
                                          </td> */}

                                          {/* show if manage stock is enable */}
                                          {/* {showManageStock ? <td className="xsm-text align-middle text-center">
                                            <NavLink
                                              className={`btn xxsm-text ${item.ended_at === null
                                                ? "btn-secondary"
                                                : "btn-success"
                                                } btn-sm p-1`}
                                              to={
                                                `/dashboard/closing-stock/` +
                                                item.started_at
                                              }
                                            >
                                              {_t(t("Closing Stock"))}
                                            </NavLink>
                                          </td> : null} */}


                                          {/* <td className="xsm-text align-middle text-center text-primary">
                                            {item.ended_at ? (
                                              <Moment format="LT">
                                                {new Date(item.updated_at)}
                                              </Moment>
                                            ) : (
                                              "-"
                                            )}
                                          </td> */}

                                          {/* <td className="xsm-text align-middle text-center">
                                            {item.ended_at ? (
                                              item.ended_by
                                            ) : (
                                              <button
                                                className="btn btn-primary btn-sm py-0 px-4"
                                                onClick={() => {
                                                  handleDeleteConfirmation(
                                                    item.id
                                                  );
                                                }}
                                              >
                                                {_t(t("End"))}
                                              </button>
                                            )}
                                          </td> */}
                                          {/* <td className="xsm-text align-middle text-center">
                                            {item.ended_at
                                              ? millisToMinutesAndHours(
                                                parseInt(
                                                  item.ended_at -
                                                  item.started_at
                                                )
                                              )
                                              : "-"}
                                          </td> */}
                                        </tr>
                                      );
                                    })
                                  ),
                                ],
                              ]
                              : [
                                /* searched data, logic === haveData*/
                                searchedWorkPeriod && [
                                  searchedWorkPeriod.list.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="9"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    searchedWorkPeriod.list.map(
                                      (item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (reservationList.current_page -
                                                  1) *
                                                  reservationList.per_page}
                                            </th>


                                          <td className="xsm-text align-middle text-center text-secondary">
                                            {item.guest_name}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.mobile_number}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.tags}
                                          </td>

                                          <td className="xsm-text align-middle text-center text-green">
                                              {moment(item.reservation_datetime).format('DD-MM-YYYY')}
                                          </td>

                                          <td className="xsm-text align-middle text-center text-secondary">
                                          {item.in_time != null ? item.in_time : '-'}
                                          </td>

                                          <td className="xsm-text align-middle text-center text-secondary">
                                          {item.table_name}
                                          </td>

                                          <td className="xsm-text align-middle text-center text-secondary">
                                          <button 
                                              data-toggle="modal"
                                              data-target={"#addReservation"}
                                              onClick={() => {
                                                setFormStatus(true);
                                                reservationEdit(item.id);                                                
                                              }}
                                              type="button" className="btn btn-sm btn-outline-primary border-0 mx-1"
                                              >
                                                <MdEdit size={20}/>
                                              </button>
                                              <button 
                                              data-toggle="modal"
                                              data-target={"#cancelReservation"}
                                              onClick={setResId(item.id)}
                                              type="button" className="btn btn-sm btn-outline-danger border-0 mx-1"
                                              >
                                                <MdDelete size={20}/>
                                              </button>
                                          </td>

                                          {/* <td className="xsm-text align-middle text-center">
                                            {item.no_of_adults}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.no_of_kids}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.no_of_male}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.no_of_female}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.comments}
                                          </td> */}


                                          {/* <td className="xsm-text align-middle text-center text-primary">
                                            {item.ended_at ? (
                                              <Moment format="LT">
                                                {new Date(item.created_at)}
                                              </Moment>
                                            ) : (
                                              "-"
                                            )}
                                          </td>

                                          <td className="xsm-text align-middle text-center text-primary">
                                            {item.ended_at ? (
                                              <Moment format="LT">
                                                {new Date(item.updated_at)}
                                              </Moment>
                                            ) : (
                                              "-"
                                            )}
                                          </td> */}
                                            {/* 
                                            <td className="xsm-text align-middle text-center">
                                              <NavLink
                                                className={`btn xxsm-text ${item.ended_at === null
                                                    ? "btn-secondary"
                                                    : "btn-success"
                                                  } btn-sm p-1`}
                                                to={
                                                  `/dashboard/closing-stock/` +
                                                  item.started_at
                                                }
                                              >
                                                {_t(t("Closing Stock"))}
                                              </NavLink>
                                            </td> */}

                                            {/* <td className="xsm-text align-middle text-center">
                                              {item.ended_at ? (
                                                <Moment format="LT">
                                                  {new Date(item.updated_at)}
                                                </Moment>
                                              ) : (
                                                "-"
                                              )}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.ended_at ? (
                                                item.ended_by
                                              ) : (
                                                <button
                                                  className="btn btn-primary btn-sm py-0 px-4"
                                                  onClick={() => {
                                                    handleDeleteConfirmation(
                                                      item.id
                                                    );
                                                  }}
                                                >
                                                  {_t(t("End"))}
                                                </button>
                                              )}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.ended_at
                                                ? millisToMinutesAndHours(
                                                  parseInt(
                                                    item.ended_at -
                                                    item.started_at
                                                  )
                                                )
                                                : "-"}
                                            </td> */}
                                          </tr>
                                        );
                                      }
                                    )
                                  ),
                                ],
                              ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* pagination loading effect */}
              {newWorkPeriod.uploading === true || loading === true
                ? paginationLoading()
                : [
                  // logic === !searched
                  !searchedWorkPeriod.searched ? (
                    <div key="fragment4">
                      <div className="t-bg-white mt-1 t-pt-5 pb-3 radius_bottom">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            {/* pagination function */}
                            {pagination(
                              reservationList,
                              setPaginatedReservations
                            )}
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(reservationList)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // if searched
                    <div className="t-bg-white mt-1 t-pt-5 pb-3 radius_bottom">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          <ul className="t-list d-flex">
                            <li className="t-list__item no-pagination-style">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  setSearchedWorkPeriod({
                                    ...searchedWorkPeriod,
                                    searched: false,
                                  })
                                }
                              >
                                {_t(t("Clear Search"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                {searchedShowingData(
                                  searchedWorkPeriod,
                                  reservationListForSearch
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ),
                ]}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ReservationList;
