import React, { useEffect, useContext, useState } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
// import TimePicker from 'react-time-picker';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';

//functions
import {
  _t,
  getCookie,
  pageLoading,
  modalLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import { MdEdit, MdDelete } from "react-icons/md";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { NavLink } from "react-router-dom";

const TableSection = () => {
  var weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  //getting context values here
  const {
    //common
    loading,
    setLoading,
    dataPaginating,
    showManageStock,
  } = useContext(SettingsContext);

  const { authUserInfo } = useContext(UserContext);

  const {
    //branch
    branchForSearch,

    //work period
    getTableSections,
    tableSectionList,
    setTableSectionList,
    setPaginatedTableSections,
    tableSectionForSearch,
    setTableSectionListForSearch,
  } = useContext(RestaurantContext);

  const { t } = useTranslation();

  //new unit
  let [newTableSec, setNewTableSec] = useState({
    id: null,
    user_type: null,
    branch_id: null,
    branch_name: null,
    section_name: null,
    edit: false,
    uploading: false
  });

  let [fromTime, setFromTime] = useState('');
  let [toTime, setToTime] = useState('');
  let [fromTimeVal, setFromTimeVal] = useState('');
  let [toTimeVal, setToTimeVal] = useState('');
  let [fromTimeEdit, setFromTimeEdit] = useState(false);
  let [toTimeEdit, setToTimeEdit] = useState(false);
  let [editSlotId, setEditSlotId] = useState('');
  const [checkedSlot, setCheckedSlot] = useState(1);

  //search result
  let [searchedWorkPeriod, setSearchedWorkPeriod] = useState({
    list: null,
    searched: false,
  });

  const slotData = [
    {
        id: 1,
        name: 'Lunch',
        label: 'Lunch'
    },
    {
        id: 2,
        name: 'Dinner',
        label: 'Dinner'
    }
  ];

  //useEffect == componentDidMount
  useEffect(() => {
    authUserInfo.details &&
      setNewTableSec({
        branch_id: authUserInfo.details.branch_id
          ? authUserInfo.details.branch_id
          : null,
        user_type: authUserInfo.details.user_type,
      });
  }, []);

  //set branch id
  const handleSetBranchId = (branch) => {
    let searchInput = branch.name.toLowerCase();
    let searchedList = tableSectionForSearch.filter((item) => {
      //branch
      let lowerCaseItemBranch = item.branch_name.toLowerCase();
      return lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput);
    });
    setNewTableSec({
      ...newTableSec,
      branch_id: branch.id,
    });
    setSearchedWorkPeriod({
      ...searchedWorkPeriod,
      list: searchedList,
      searched: true,
    });
  };

  //search work periods here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedWorkPeriod({ ...searchedWorkPeriod, searched: false });
    } else {
      let searchedList = tableSectionForSearch.filter((item) => {
        let lowerCaseItemBranchName = item.branch_name.toLowerCase();
        let lowerCaseItemSectionName = item.section_name.toLowerCase();
        // let lowerCaseItemDate = item.out_time.toLowerCase();
        // let lowerCaseItemUserName2 =
        //   item.ended_by && item.ended_by.toLowerCase();
        return (
          lowerCaseItemBranchName.includes(searchInput) ||
          lowerCaseItemSectionName.includes(searchInput) 
          // ||
          // lowerCaseItemDate.includes(searchInput) 
        //   ||
        //   (lowerCaseItemUserName2 &&
        //     lowerCaseItemUserName2.includes(searchInput))
        );
      });
      setSearchedWorkPeriod({
        ...searchedWorkPeriod,
        list: searchedList,
        searched: true,
      });
    }
  };

  //Save New work period
  const handleSaveNewWorkPeriod = (e) => {
    e.preventDefault();
    if (newTableSec.branch_id) {
        setNewTableSec({
        ...newTableSec,
        uploading: true,
      });
      const url = BASE_URL + `/settings/new-work-period`;
      let date =
        weekday[new Date().getDay()] +
        ", " +
        new Date().getDate() +
        " " +
        month[new Date().getMonth()] +
        ", " +
        new Date().getFullYear();

      let time = new Date().getTime();

      let formData = {
        date: date,
        branch_id: newTableSec.branch_id,
        started_at: time,
      };
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data === "exist") {
            authUserInfo.details &&
            setNewTableSec({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
              });
            toast.error(
              `${_t(t("Please end the started work period first"))}`,
              {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              }
            );
          } else {
            authUserInfo.details &&
            setNewTableSec({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
              });
            setTableSectionList(res.data[0]);
            setTableSectionListForSearch(res.data[1]);
            setSearchedWorkPeriod({
              ...searchedWorkPeriod,
              list: res.data[1],
            });
            setLoading(false);
            toast.success(`${_t(t("Work period has been started"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        })
        .catch(() => {
          setLoading(false);
          setNewTableSec({
            ...newTableSec,
            uploading: false,
          });
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //milisec to hour min
  const millisToMinutesAndHours = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var hours = Math.floor(minutes / 60);
    minutes = Math.floor(minutes % 60);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${hours} ${_t(t("hr"))} - ${minutes} ${_t(t("min"))} - ${seconds < 10 ? "0" : ""
      }${seconds} ${_t(t("sec"))}`;
  };

  //end confirmation modal of workPeriod
  const handleDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete now?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteTableSec(id);
                  onClose();
                }}
              >
                {_t(t("Yes, delete section!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleDeleteTableSec = async (id) => {
    setNewTableSec({
      ...newTableSec,
      uploading: true,
    });
    const sectionUrl = BASE_URL + `/settings/delete-table_section/`+id;

    return await axios
    .put(sectionUrl, {}, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    }).then((res) => {
        console.log('slotsss res');
        console.log(res.data);
        authUserInfo.details &&
        setNewTableSec({
            branch_id: authUserInfo.details.branch_id
              ? authUserInfo.details.branch_id
              : null,
            user_type: authUserInfo.details.user_type,

            uploading: false,
          });
        setLoading(false);
        // setFromTimeEdit(false);
        // setToTimeEdit(false);
        toast.success(`${_t(t("Table Section deleted"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
    })
    .catch((error) => {
      setLoading(false);
      setNewTableSec({
        ...newTableSec,
        uploading: false,
      });
      console.log('error slot');
      console.log(error);
      toast.error(`${_t(t("Please try again"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    });
  }

  //end here
  const handleEndWorkPeriod = (id) => {
    setNewTableSec({
      ...newTableSec,
      uploading: true,
    });
    const url = BASE_URL + `/settings/update-work-period`;
    let time = new Date().getTime();
    let formData = {
      id: id,
      ended_at: time,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data !== "orderExist" && res.data !== "addClosing") {
          authUserInfo.details &&
          setNewTableSec({
              branch_id: authUserInfo.details.branch_id
                ? authUserInfo.details.branch_id
                : null,
              user_type: authUserInfo.details.user_type,

              uploading: false,
            });
            setTableSectionList(res.data[0]);
            setTableSectionListForSearch(res.data[1]);
          setSearchedWorkPeriod({
            ...searchedWorkPeriod,
            list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Work period has been ended"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          if (res.data === "addClosing") {
            toast.error(`${_t(t("Add closing stock of ingredients"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          } else {
            toast.error(
              `${_t(
                t("All submitted orders need to be settled to end workperiod")
              )}`,
              {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              }
            );
          }
          setLoading(false);
          setNewTableSec({
            ...newTableSec,
            uploading: false,
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setNewTableSec({
          ...newTableSec,
          uploading: false,
        });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  const handleSaveNewSlot = async(e) => {
    let checkBranch = false;
    if (
      authUserInfo.details !== null &&
      authUserInfo.details.user_type !== "staff"
    ) {
      checkBranch = true;
    }
    if (checkBranch) {
      if (newTableSec.branch_id != null) {
          setNewTableSec({
          ...newTableSec,
          uploading: true,
        });
        const sectionUrl = BASE_URL + `/settings/new-table_section`;

        const body = {
          "section_name": newTableSec.section_name,
          "branch_id": newTableSec.branch_id
        }
        
        return await
        axios
          .post(sectionUrl, body, {
              headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
              console.log('slotsss res');
              console.log(res);
              authUserInfo.details &&
              setNewTableSec({
                  branch_id: authUserInfo.details.branch_id
                    ? authUserInfo.details.branch_id
                    : null,
                  user_type: authUserInfo.details.user_type,

                  uploading: false,
                });
              setLoading(false);
              toast.success(`${_t(t("Table Section added started"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
          })
          .catch((error) => {
            setLoading(false);
            setNewTableSec({
              ...newTableSec,
              uploading: false,
            });
            console.log('error slot');
            console.log(error);
            toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          });
      } else {
        toast.error(`${_t(t("Please select a branch"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      setNewTableSec({
        ...newTableSec,
        uploading: true,
      });
      const sectionUrl = BASE_URL + `/settings/new-customer`;
      
      const body = {
        "section_name": newTableSec.section_name,
        "branch_id": authUserInfo.details !== null && authUserInfo.details.branch_id
      }
      
      // if (authUserInfo.details !== null) {
      //   formData.append("branch_id", authUserInfo.details.branch_id);
      // }

      return axios
        .post(sectionUrl, body, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setNewTableSec({
            branch_id: '',
            section_name: '',
            branch_name: '',
            edit: false,
            editSlug: null,
            uploading: false,
          });
          setTableSectionList(res.data[0]);
          setTableSectionListForSearch(res.data[1]);
          setLoading(false);
          toast.success(`${_t(t("Table Section has been added"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        })
        .catch((error) => {
          setLoading(false);
          setNewTableSec({
            ...newTableSec,
            uploading: false,
          });
          if (error && error.response.data.errors) {
            if (error.response.data.errors.phn_no) {
              error.response.data.errors.phn_no.forEach((item) => {
                if (item === "A customer exists with this phone number") {
                  toast.error(
                    `${_t(t("A customer exists with this phone number"))}`,
                    {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    }
                  );
                }
              });
            }
          }
        });
    }
  }

  const handleUpdateSlot = async() => {
    if (newTableSec.branch_id != null) {
      setNewTableSec({
      ...newTableSec,
      uploading: true,
    });
    const sectionUrl = BASE_URL + `/settings/update-table_section/`+newTableSec.id;

    const bodyData = {
      "section_name": newTableSec.section_name,
      "branch_id": newTableSec.branch_id
    };
    
    return await axios
    .put(sectionUrl, bodyData, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    }).then((res) => {
            console.log('slotsss res');
            console.log(res.data);
            authUserInfo.details &&
            setNewTableSec({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
              });
            setLoading(false);
            // setFromTimeEdit(false);
            // setToTimeEdit(false);
            toast.success(`${_t(t("Table Section updated"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
        })
        .catch((error) => {
          setLoading(false);
          setNewTableSec({
            ...newTableSec,
            uploading: false,
          });
          console.log('error slot');
          console.log(error);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }

  //set edit true & values
  const handleSetEdit = (id) => {
    let tableSec = tableSectionForSearch.filter((item) => {
      return item.id === id;
    });

    console.log('tableSec sdsdsd');
    console.log(tableSec);

    let selectedOptionForBranch = null;
    if (
      authUserInfo.details !== null &&
      authUserInfo.details.user_type !== "staff"
    ) {
      if (tableSec[0].branch_id) {
        selectedOptionForBranch = branchForSearch.filter((branchItem) => {
          return branchItem.id === parseInt(tableSec[0].branch_id);
        });
      }
    }
    setNewTableSec({
      ...newTableSec,
      section_name: tableSec[0].section_name,
      id: tableSec[0].id,
      branch_id: tableSec[0].branch_id,
      editSlug: tableSec[0].slug,
      edit: true,
    });

    // setCheckedSlot(bookSlot[0].slot_type)
    // setFromTimeVal(bookSlot[0].in_time)
    // setToTimeVal(bookSlot[0].out_time)
    // setEditSlotId(bookSlot[0].id)
    // setNewBookSlots({
    //   ...newBookSlots,
    //   slot_type: bookSlot[0].slot_type,
    //   in_time: bookSlot[0].in_time,
    //   out_time: bookSlot[0].out_time,
    //   editSlug: bookSlot[0].id,
    //   edit: true,
    // });
  };

  const fromEditClick = () => {
    setFromTimeEdit(true);
  }

  const toEditClick = () => {
    setToTimeEdit(true);
  }

  //set name, phn no hook
  const handleSetNewSection = (e) => {
    setNewTableSec({ ...newTableSec, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Table Section"))}</title>
      </Helmet>

        {/* Add modal */}
        <div className="modal fade" id="addSlot" aria-hidden="true">
            <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header align-items-center">
                <div className="fk-sm-card__content">
                    <h5 className="text-capitalize fk-sm-card__title">
                    {!newTableSec.edit
                      ? 
                      _t(t("Add new section"))
                      : _t(t("Update Section"))   
                    }
                    </h5>
                </div>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                ></button>
                </div>
                <div className="modal-body">
                {/* show form or show saving loading */}
                { 
                // newBookSlots.uploading === false ? (
                    <div key="fragment-customer-1">
                    <form
                        // onSubmit={
                        //   !newCustomer.edit
                        //     ? 
                        // handleSaveNewSlot
                        //     : handleUpdateCustomer
                        // }
                    >

                    <div>
                      <label htmlFor="section_name" className="form-label">
                        {_t(t("Section Name"))}{" "}
                        <small className="text-primary">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="section_name"
                        name="section_name"
                        placeholder="e.g. Section A"
                        value={newTableSec.section_name || ""}
                        required
                        onChange={handleSetNewSection}
                      />
                    </div>
                        
                        <div className="mt-4">
                        <div className="row">
                            <div className="col-6">
                            <button
                                type="button"
                                onClick={
                                      !newTableSec.edit
                                      ? 
                                      handleSaveNewSlot
                                      : handleUpdateSlot}
                                className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                            >
                                {
                                !newTableSec.edit?
                                 _t(t("Save"))
                                : _t(t("Update"))
                                }
                            </button>
                            </div>
                            <div className="col-6">
                            <button
                                type="button"
                                className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                                onClick={() => {
                                }}
                                data-dismiss="modal"
                            >
                                {_t(t("Close"))}
                            </button>
                            </div>
                        </div>
                        </div>
                    </form>
                    </div>
                // ) : (
                //     <div key="fragment2">
                //     <div className="text-center text-primary font-weight-bold text-uppercase">
                //         {_t(t("Please wait"))}
                //     </div>
                //     {modalLoading(3)}
                //     <div className="mt-4">
                //         <div className="row">
                //         <div className="col-6">
                //             <button
                //             type="button"
                //             className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                //             onClick={(e) => {
                //                 e.preventDefault();
                //             }}
                //             >
                //             {
                //             _t(t("Save")) 
                //             }
                //             </button>
                //         </div>
                //         <div className="col-6">
                //             <button
                //             type="button"
                //             className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                //             data-dismiss="modal"
                //             >
                //             {_t(t("Close"))}
                //             </button>
                //         </div>
                //         </div>
                //     </div>
                //     </div>
                // )
                }
                </div>
            </div>
            </div>
        </div>
        {/* Add modal Ends*/}

      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {newTableSec.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white sm_shadow sm_card">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row t-mt-10 gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                    <div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {!searchedWorkPeriod.searched
                              ? _t(t("Table Section"))
                              : _t(t("Search Result"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-12 col-lg-5">
                      <div className="input-group">
                        <button className="btn btn-primary" type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="form-file">
                          <input
                            type="text"
                            className="form-control border-0 form-control--light-1 rounded-0"
                            placeholder={
                              _t(t("Search by branch, date, user")) + ".."
                            }
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-md-4 mt-lg-0 col-lg-7 t-mb-15 mb-md-0">
                      <div className="row t-mt-10 gx-2 align-items-center">
                        {authUserInfo.details !== null &&
                          authUserInfo.details.user_type !== "staff" ? (
                          <>
                            <div className="col-md-7 t-mb-15 mb-md-0 mt-3 mt-md-0">
                              <Select
                                options={branchForSearch && branchForSearch}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                classNamePrefix="select"
                                className="xsm-text"
                                onChange={handleSetBranchId}
                                maxMenuHeight="200px"
                                placeholder={
                                  _t(t("Please select a branch")) + ".."
                                }
                              />
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list fk-sort align-items-center justify-content-center">
                                <li className="fk-sort__list mb-0 flex-grow-1">
                                  <button
                                    data-toggle="modal"
                                    data-target="#addSlot"
                                    onClick={() => {
                                      setCheckedSlot('');
                                      setNewTableSec({
                                        ...newTableSec,
                                        branch: null,
                                        edit: false,
                                        uploading: false,
                                      });
                                    }}
                                    className="w-100 btn btn-dark sm-text text-uppercase"
                                  >
                                    {_t(t("Add Table Section"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : (
                          <div className="col-12 col-md-5 ml-auto mt-2 mt-md-0">
                            <ul className="t-list fk-sort align-items-center justify-content-center">
                              <li className="fk-sort__list mb-0 flex-grow-1">
                                <button
                                    data-toggle="modal"
                                    data-target="#addSlot"
                                    onClick={() => {
                                      setCheckedSlot('');
                                      setNewTableSec({
                                        ...newTableSec,
                                        branch: null,
                                        edit: false,
                                        uploading: false,
                                      });
                                    }}
                                  className="w-100 btn btn-dark sm-text text-uppercase"
                                >
                                  {_t(t("Add Time Slot"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fk-scroll--order-history" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="overflow-auto">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Section Id"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Section Name"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Branch Name"))}
                              </th>
                              {/* <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Created at"))}
                              </th> */}
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                              {/* {showManageStock ? <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Stock"))}
                              </th> : null} */}
                              {/* <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Ended at"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Ended by"))}
                              </th> */}

                              {/* <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Total time"))}
                              </th> */}
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {!searchedWorkPeriod.searched
                              ? [
                                tableSectionList && [
                                  tableSectionList.data.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="8"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    tableSectionList.data.map((item, index) => {
                                      return (
                                        <tr
                                          className="align-middle"
                                          key={item.id}
                                        >
                                          <th
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {index +
                                              1 +
                                              (tableSectionList.current_page -
                                                1) *
                                                tableSectionList.per_page}
                                          </th>

                                          <td className="xsm-text align-middle text-center text-secondary">
                                            {item.id}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.section_name}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.branch_name}
                                          </td>

                                          {/* <td className="xsm-text align-middle text-center text-green">
                                            <Moment format="LT">
                                              {new Date(item.record_time)}
                                            </Moment>
                                          </td> */}

                                          <td className="xsm-text text-capitalize align-middle text-center">
                                            <div className="d-flex align-items-center justify-content-center gap-1">
                                              <button 
                                              data-toggle="modal"
                                              data-target="#addSlot"
                                              onClick={() => {
                                                // setNewTableSec({
                                                //   ...newTableSec,
                                                //   branch: null,
                                                //   uploading: false,
                                                // });
                                                handleSetEdit(
                                                  item.id
                                                );
                                              }}
                                              type="button" className="btn btn-sm btn-outline-primary">
                                                <MdEdit size={20}/>
                                              </button>
                                              <button 
                                              onClick={() => {
                                                handleDeleteConfirmation(
                                                  item.id
                                                );
                                              }}
                                              type="button" className="btn btn-sm btn-outline-danger">
                                                <MdDelete size={20}/>
                                              </button>
                                            </div>
                                            {/* <div className="dropdown">
                                              <button
                                                className="btn t-bg-clear t-text-dark--light-40"
                                                type="button"
                                                data-toggle="dropdown"
                                              >
                                                <i className="fa fa-ellipsis-h"></i>
                                              </button>
                                              <div className="dropdown-menu">
                                                <button
                                                  className="dropdown-item sm-text text-capitalize"
                                                  onClick={() => {
                                                    setNewCustomer({
                                                      ...newCustomer,
                                                      branch: null,
                                                    });
                                                    handleSetEdit(
                                                      item.slug
                                                    );
                                                  }}
                                                  data-toggle="modal"
                                                  data-target="#addCustomer"
                                                >
                                                  <span className="t-mr-8">
                                                    <i className="fa fa-pencil"></i>
                                                  </span>
                                                  {_t(t("Edit"))}
                                                </button>

                                                <button
                                                  className="dropdown-item sm-text text-capitalize"
                                                  onClick={() => {
                                                    handleDeleteConfirmation(
                                                      item.slug
                                                    );
                                                  }}
                                                >
                                                  <span className="t-mr-8">
                                                    <i className="fa fa-trash"></i>
                                                  </span>
                                                  {_t(t("Delete"))}
                                                </button>
                                              </div>
                                            </div> */}
                                          </td>

                                          {/* show if manage stock is enable */}
                                          {/* {showManageStock ? <td className="xsm-text align-middle text-center">
                                            <NavLink
                                              className={`btn xxsm-text ${item.ended_at === null
                                                ? "btn-secondary"
                                                : "btn-success"
                                                } btn-sm p-1`}
                                              to={
                                                `/dashboard/closing-stock/` +
                                                item.started_at
                                              }
                                            >
                                              {_t(t("Closing Stock"))}
                                            </NavLink>
                                          </td> : null} */}


                                          {/* <td className="xsm-text align-middle text-center text-primary">
                                            {item.ended_at ? (
                                              <Moment format="LT">
                                                {new Date(item.updated_at)}
                                              </Moment>
                                            ) : (
                                              "-"
                                            )}
                                          </td> */}

                                          {/* <td className="xsm-text align-middle text-center">
                                            {item.ended_at ? (
                                              item.ended_by
                                            ) : (
                                              <button
                                                className="btn btn-primary btn-sm py-0 px-4"
                                                onClick={() => {
                                                  handleDeleteConfirmation(
                                                    item.id
                                                  );
                                                }}
                                              >
                                                {_t(t("End"))}
                                              </button>
                                            )}
                                          </td>
                                          <td className="xsm-text align-middle text-center">
                                            {item.ended_at
                                              ? millisToMinutesAndHours(
                                                parseInt(
                                                  item.ended_at -
                                                  item.started_at
                                                )
                                              )
                                              : "-"}
                                          </td> */}
                                        </tr>
                                      );
                                    })
                                  ),
                                ],
                              ]
                              : [
                                /* searched data, logic === haveData*/
                                searchedWorkPeriod && [
                                  searchedWorkPeriod.list.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="9"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    searchedWorkPeriod.list.map(
                                      (item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={item.id}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (tableSectionList.current_page -
                                                  1) *
                                                  tableSectionList.per_page}
                                            </th>

                                            <td className="xsm-text align-middle text-center text-secondary">
                                                {item.id}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                                {item.section_name}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                                {item.branch_name}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              <div className="d-flex align-items-center justify-content-center gap-1">
                                                <button 
                                                data-toggle="modal"
                                                data-target="#addSlot"
                                                onClick={() => {
                                                  // setNewTableSec({
                                                  //   ...newTableSec,
                                                  //   branch: null,
                                                  //   uploading: false,
                                                  // });
                                                  handleSetEdit(
                                                    item.id
                                                  );
                                                }}
                                                type="button" className="btn btn-sm btn-outline-primary">
                                                  <MdEdit size={20}/>
                                                </button>
                                                <button 
                                                onClick={() => {
                                                  handleDeleteConfirmation(
                                                    item.id
                                                  );
                                                }}
                                                type="button" className="btn btn-sm btn-outline-danger">
                                                  <MdDelete size={20}/>
                                                </button>
                                              </div>
                                            </td>

                                            {/* <td className="xsm-text align-middle text-center text-green">
                                                <Moment format="LT">
                                                {new Date(item.record_time)}
                                                </Moment>
                                            </td> */}
                                            {/* 
                                            <td className="xsm-text align-middle text-center">
                                              <NavLink
                                                className={`btn xxsm-text ${item.ended_at === null
                                                    ? "btn-secondary"
                                                    : "btn-success"
                                                  } btn-sm p-1`}
                                                to={
                                                  `/dashboard/closing-stock/` +
                                                  item.started_at
                                                }
                                              >
                                                {_t(t("Closing Stock"))}
                                              </NavLink>
                                            </td> */}

                                            {/* <td className="xsm-text align-middle text-center">
                                              {item.ended_at ? (
                                                <Moment format="LT">
                                                  {new Date(item.updated_at)}
                                                </Moment>
                                              ) : (
                                                "-"
                                              )}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.ended_at ? (
                                                item.ended_by
                                              ) : (
                                                <button
                                                  className="btn btn-primary btn-sm py-0 px-4"
                                                  onClick={() => {
                                                    handleDeleteConfirmation(
                                                      item.id
                                                    );
                                                  }}
                                                >
                                                  {_t(t("End"))}
                                                </button>
                                              )}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.ended_at
                                                ? millisToMinutesAndHours(
                                                  parseInt(
                                                    item.ended_at -
                                                    item.started_at
                                                  )
                                                )
                                                : "-"}
                                            </td> */}
                                          </tr>
                                        );
                                      }
                                    )
                                  ),
                                ],
                              ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* pagination loading effect */}
              {newTableSec.uploading === true || loading === true
                ? paginationLoading()
                : [
                  // logic === !searched
                  !searchedWorkPeriod.searched ? (
                    <div key="fragment4">
                      <div className="t-bg-white mt-1 t-pt-5 pb-3 radius_bottom">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            {/* pagination function */}
                            {pagination(
                              tableSectionList,
                              setPaginatedTableSections
                            )}
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(tableSectionList)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // if searched
                    <div className="t-bg-white mt-1 t-pt-5 pb-3 radius_bottom">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          <ul className="t-list d-flex">
                            <li className="t-list__item no-pagination-style">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  setSearchedWorkPeriod({
                                    ...searchedWorkPeriod,
                                    searched: false,
                                  })
                                }
                              >
                                {_t(t("Clear Search"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                {searchedShowingData(
                                  searchedWorkPeriod,
                                  tableSectionForSearch
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ),
                ]}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default TableSection;
