import { BASE_URL } from "../../../../BaseUrl";
import { getCookie } from "../../../../functions/Functions";
import axios from "axios";


export const NEW_EXPENSE_URL = `${BASE_URL}/settings/get-reservation-by-id`;
export const AVAILABLE_TABLES_URL = `${BASE_URL}/settings/get-available-tables`;
export const CANCEL_RESERVATION_URL = `${BASE_URL}/settings/cencel-reservation-by-id`;
export const GET_CUSTOMER_BY_MOBILE_URL = `${BASE_URL}/settings/get-customer-by-mobile`;


export function getReservationById(id) {
    return axios.get(NEW_EXPENSE_URL+'/'+id, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}

export function getAvalilableTables(id, date, slot) {
    return axios.get(AVAILABLE_TABLES_URL+'/'+id+'/'+date+'/'+slot, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}

export function cancelReservationById(id) {
    return axios.get(CANCEL_RESERVATION_URL+'/'+id, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response))
}

export function getCustomerByMobile(id) {
    return axios.get(GET_CUSTOMER_BY_MOBILE_URL+'/'+id, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}