import { BASE_URL } from "../../../../BaseUrl";
import { getCookie } from "../../../../functions/Functions";
import axios from "axios";

export const GET_RESERVATION_REPORT_URL = `${BASE_URL}/settings/get-reservation-report`;


export function getReservationReports(obj) {
    return axios.post(GET_RESERVATION_REPORT_URL, obj, {headers: { Authorization: `Bearer ${getCookie()}`}})
    .then((response => response.data))
}