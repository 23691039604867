import React, { useEffect, useContext, useState } from "react";
import { NavLink, Link, withRouter, useHistory } from "react-router-dom";
import { SettingsContext } from "../../../contexts/Settings";
import { HiOutlineLogout } from 'react-icons/hi';
import { FiChevronsRight, FiCpu, FiUser, FiLayout, FiChevronDown, FiSettings } from "react-icons/fi";
import { BiBookBookmark, BiLayout } from "react-icons/bi";
import { MdMonitor, MdAvTimer, MdTimelapse, MdRestaurant, MdOutlineAssignment,MdBackupTable,MdSpaceDashboard, MdOutlineBrunchDining, MdOutlineAdminPanelSettings } from "react-icons/md";
import { UserContext } from "../../../contexts/User";
import { SAAS_APPLICATION, BASE_URL } from "../../../BaseUrl";
import axios from "axios";
import $ from "jquery";
import { MdAttachMoney } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
import { BiCalendarCheck } from "react-icons/bi";

import {
  _t,
  getCookie,
  deleteCookie,
  managePageHrefLink,
  getSystemSettings,
} from "../../../functions/Functions";
import { useTranslation } from "react-i18next";

const Sidebar = (props) => {
  const { t, i18n } = useTranslation();
  const [templateValue, setTemplateValue] = useState(1);
  let { navLanguageList, navCurrencyList, generalSettings, showManageStock } =
    useContext(SettingsContext);
  const { authUserInfo } = useContext(UserContext);

  // getTemplate value
  const getActivatedTemplate = async (e) => {
    const url = BASE_URL + "/settings/get-template-value";
    const res = await axios.get(url);
    setTemplateValue(res.data);
    console.log("template sidebar", res);
  };

  useEffect(() => {
    getActivatedTemplate();
  }, []);

  const subMenuClick = () => {
    var posHasSub = $(".fk-pos-nav__list-has-sub");
    var posSub = $(".fk-pos-nav__sub");
    $(".fk-pos-nav__list-has-sub > .submenu_list").on("click", function (e) {
      e.preventDefault();
    });
    posHasSub.on("click", function (e) {
      $(this).find(posSub).slideDown();
      $(this).siblings().find(posSub).slideUp();
      $(this).addClass("active").siblings().removeClass("active");
    });    
  }

  //dynamic style
  const style = {
    logo: {
      backgroundColor:
        generalSettings &&
        getSystemSettings(generalSettings, "type_background"),
      backgroundImage:
        generalSettings &&
        `url(${getSystemSettings(generalSettings, "type_logo")})`,
    },
    currency: {
      backgroundColor:
        generalSettings && getSystemSettings(generalSettings, "type_clock"),
      color:
        generalSettings && getSystemSettings(generalSettings, "type_color"),
    },
  };

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
  };
  let menuItems = [
    {
      name: "Eduhance",
      iconName: "menu",
    },
    {
      name: "Home",
      iconName: "home",
      type: "solid",
    },
    {
      name: "Explore",
      iconName: "compass",
      type: "solid",
    },
    {
      name: "Messages",
      iconName: "envelope",
      type: "solid",
    },
    {
      name: "Resources",
      iconName: "spreadsheet",
      type: "solid",
    },
    {
      name: "Starred",
      iconName: "star",
      type: "solid",
    },
    {
      name: "Settings",
      iconName: "cog",
      type: "solid",
    },
    {
      name: "Log Out",
      iconName: "log-out",
      color: "red",
      rotate: "180",
    },
  ];
    const [hovered, setHovered] = useState(null);
    const [active, setActive] = useState(1);
    const [animate, setAnimate] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [subExpanded, setSubExpanded] = useState(false);
    const [subExpanded2, setSubExpand2ed] = useState(false);
    const [subExpanded3, setSubExpand3ed] = useState(false);
    const [tempExpanded, setTempExpanded] = useState(false);
    const [manageExpanded, setManageExpanded] = useState(false);
    const changeSmall = useMediaQuery("(max-height: 550px)");
    let delay = 1;
    useEffect(() => {
      setAnimate(true);
      let timer = setTimeout(() => setAnimate(false), delay * 1000);
  
      return () => {
        clearTimeout(timer);
      };
    }, [active, delay]);

    //logout
    const handleLogout = () => {
      deleteCookie();
    };
  
    return (
      <>
      {props.location.pathname !== "/" &&
      props.location.pathname !== "/profile" &&
      props.location.pathname !== "/profile/" &&
      props.location.pathname !== "/my-orders" &&
      props.location.pathname !== "/my-orders/" &&
      props.location.pathname !== "/login" &&
      props.location.pathname !== "/login/" &&
      props.location.pathname !== "/sign-up" &&
      props.location.pathname !== "/sign-up/" &&
      props.location.pathname !== "/delivery-man-registration" &&
      props.location.pathname !== "/delivery-man-registration/" &&
      props.location.pathname !== "/installation" &&
      props.location.pathname !== "/installation/" &&
      props.location.pathname !== "/installation/permission-chcek" &&
      props.location.pathname !== "/installation/permission-chcek/" &&
      props.location.pathname !== "/installation/database-setup" &&
      props.location.pathname !== "/installation/database-setup/" &&
      props.location.pathname !== "/installation/import-database" &&
      props.location.pathname !== "/installation/import-database/" &&
      props.location.pathname !== "/installation/add-admin-user" &&
      props.location.pathname !== "/installation/add-admin-user/" &&
      props.location.pathname !== "/installation/congratulation" &&
      props.location.pathname !== "/installation/congratulation/" &&
      props.location.pathname !== "/dashboard/pos" &&
      props.location.pathname !== "/dashboard/pos/" &&
      props.location.pathname !== "/reset-password" &&
      props.location.pathname !== "/reset-password/" &&
      // !props.location.pathname.includes("/dashboard/pos") &&
      !props.location.pathname.includes("/set-new-password") ? (
        <div className="p-3 common_nav">
          <div className={`card custome_sidebar sm_shadow ${expanded && "custome_expanded"}`}>
            {/* menu head sections */}
            <div
              className={`card-header custome_boxicon-container sidebar_header ${
                expanded && "custome_expanded-boxicon-container pb-3 pt-3 border-bottom-0 w-100"
              }`}
            >
              <FiChevronsRight size={24} color="black" onClick={() => {
                  setExpanded(!expanded);
              }}/>
              <div
                className={`custome_description 
              ${expanded && "custome_show-description border-bottom-0"}
              `}
              >
                <div className="fk-brand fk-brand--sr-lg">
                  {getCookie() !== undefined
                    ? [
                        window.location.pathname === "/dashboard" ? (
                          <NavLink
                            to={{ pathname: "/refresh", state: "/dashboard" }}
                            exact
                            className="t-link w-100"
                            key="logokey"
                          >
                            <span
                              className="fk-brand__img fk-brand__img--fk"
                              style={style.logo}
                            ></span>
                          </NavLink>
                        ) : (
                          <NavLink
                            to="/dashboard"
                            exact
                            className="t-link w-100"
                            key="logokey"
                          >
                            <span
                              className="fk-brand__img fk-brand__img--fk"
                              style={style.logo}
                            ></span>
                          </NavLink>
                        ),
                      ]
                    : [
                        window.location.pathname === "/" ? (
                          <NavLink
                            to={{ pathname: "/refresh", state: "/" }}
                            exact
                            className="t-link w-100"
                          >
                            <span
                              className="fk-brand__img fk-brand__img--fk"
                              style={style.logo}
                            ></span>
                          </NavLink>
                        ) : (
                          <NavLink to="/" exact className="t-link w-100">
                            <span
                              className="fk-brand__img fk-brand__img--fk"
                              style={style.logo}
                            ></span>
                          </NavLink>
                        ),
                      ]}
                </div>
                <h4 className="font-weight-bold t-text-black">Smoke Hub</h4>
              </div>
            </div>
            {/* menu content sections */}
            
            <div className="card-body scroll p-0 overflow-sidebar w-100 border-top-0">
              <NavLink
                to="/dashboard"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard' && "custome_active"}`}>
                  {/* <img
                    src={`${props.location.pathname == '/dashboard' ? "/assets/icons/dashboard_white.svg" : "/assets/icons/dashboard_black.svg"}`}
                    alt=""
                    className={`img-fluid h-100`}
                  /> */}
                  <FiCpu size={24} color={`${props.location.pathname == '/dashboard' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard' && "custome_active-description"}
                `}
                >
                  {_t(t("Dashboard"))}
                </p>
              </NavLink>
              {/* {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Work period")
                ?
              <NavLink
                to="/dashboard/work-periods"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/work-periods' && "custome_active"}`}>
                  <MdAvTimer size={25} color={`${props.location.pathname == '/dashboard/work-periods' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/work-periods' && "custome_active-description"}
                `}
                >
                  {_t(t("Work Periods"))}
                </p>
              </NavLink>: null } */}

              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Work period")
                ?
              <NavLink
                to="/dashboard/reservation-list"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/reservation-list' && "custome_active"}`}>
                  <MdOutlineBrunchDining size={24} color={`${props.location.pathname == '/dashboard/reservation-list' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/reservation-list' && "custome_active-description"}
                `}
                >
                  {_t(t("Reservation"))}
                </p>
              </NavLink>: null }


              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Work period")
                ?
              <NavLink
                to="/dashboard/reservation-table"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/reservation-table' && "custome_active"}`}>
                  <MdBackupTable size={24} color={`${props.location.pathname == '/dashboard/reservation-table' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/reservation-table' && "custome_active-description"}
                `}
                >
                  {_t(t("Table Management"))}
                </p>
              </NavLink>: null }


              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("POS")
                ?
              <NavLink
                to="/dashboard/pos"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/pos' && "custome_active"}`}>
                  <MdMonitor size={23} color={`${props.location.pathname == '/dashboard/pos' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/pos' && "custome_active-description"}
                `}
                >
                  {_t(t("Point of Sale"))}
                </p>
              </NavLink>: null}


              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Order history")
                ?
              <NavLink
                to="/dashboard/orders"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/orders' && "custome_active"}`}>
                  <BiBookBookmark size={24} color={`${props.location.pathname == '/dashboard/orders' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/orders' && "custome_active-description"}
                `}
                >
                  {_t(t("Order History"))}
                </p>
              </NavLink>: null}


              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Report")
                ?
              <div
                // to="/dashboard/reports"
                // exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                ${props.location.pathname == '/dashboard/reports' || props.location.pathname == '/dashboard/daily-reports' || props.location.pathname == '/dashboard/monthly-reports' || props.location.pathname == '/dashboard/food-items-reports' || props.location.pathname == '/dashboard/tableVoidReports' || props.location.pathname == '/dashboard/branch-reports' || props.location.pathname == '/dashboard/orderReports' || props.location.pathname == '/dashboard/beverages-reports' || props.location.pathname == '/dashboard/customers' || props.location.pathname == '/dashboard/discount-reports' || props.location.pathname == '/dashboard/canceled-reserve-reports' ? "custome_active-boxicon-container": "" }
                `}
              >
                <NavLink to="/dashboard/reports" exact >
                  <div className={`menu_icon ${props.location.pathname == '/dashboard/reports' || props.location.pathname == '/dashboard/daily-reports' || props.location.pathname == '/dashboard/monthly-reports' || props.location.pathname == '/dashboard/food-items-reports' || props.location.pathname == '/dashboard/tableVoidReports' || props.location.pathname == '/dashboard/branch-reports' || props.location.pathname == '/dashboard/orderReports' || props.location.pathname == '/dashboard/beverages-reports' || props.location.pathname == '/dashboard/customers' || props.location.pathname == '/dashboard/discount-reports' || props.location.pathname == '/dashboard/canceled-reserve-reports' ? "custome_active": "" }`}>
                    <MdOutlineAssignment size={24} color={`${props.location.pathname == '/dashboard/reports' || props.location.pathname == '/dashboard/daily-reports' || props.location.pathname == '/dashboard/monthly-reports' || props.location.pathname == '/dashboard/food-items-reports' || props.location.pathname == '/dashboard/tableVoidReports' || props.location.pathname == '/dashboard/branch-reports' || props.location.pathname == '/dashboard/orderReports' || props.location.pathname == '/dashboard/beverages-reports' || props.location.pathname == '/dashboard/customers' || props.location.pathname == '/dashboard/discount-reports' || props.location.pathname == '/dashboard/canceled-reserve-reports' ? "white" : "black"}`}/>
                  </div>
                </NavLink>
                <div onClick={()=>{
                    setSubExpanded(!subExpanded);
                  }}
                  className={`custome_description has_submenu
                ${expanded && "custome_show-description"}
                ${subExpanded && "sidebar_submenu_show_main"}
                ${props.location.pathname == '/dashboard/reports' || props.location.pathname == '/dashboard/daily-reports' || props.location.pathname == '/dashboard/monthly-reports' || props.location.pathname == '/dashboard/food-items-reports' || props.location.pathname == '/dashboard/tableVoidReports' || props.location.pathname == '/dashboard/branch-reports' || props.location.pathname == '/dashboard/orderReports' || props.location.pathname == '/dashboard/beverages-reports' || props.location.pathname == '/dashboard/customers' || props.location.pathname == '/dashboard/discount-reports' || props.location.pathname == '/dashboard/canceled-reserve-reports' ? "custome_active-description": ""}
                `}
                >
                  {/* <NavLink to="/dashboard/reports" className={`${props.location.pathname == '/dashboard/reports' || props.location.pathname == '/dashboard/daily-reports' || props.location.pathname == '/dashboard/monthly-reports' || props.location.pathname == '/dashboard/food-items-reports' || props.location.pathname == '/dashboard/tableVoidReports' || props.location.pathname == '/dashboard/branch-reports' || props.location.pathname == '/dashboard/orderReports' || props.location.pathname == '/dashboard/beverages-reports' || props.location.pathname == '/dashboard/customers' || props.location.pathname == '/dashboard/discount-reports' ? "t-text-white" : "t-text-black"}`} exact > */}
                    {_t(t("Report"))}
                  {/* </NavLink> */}
                  <FiChevronDown size={24} color={`${props.location.pathname == '/dashboard/reports' || props.location.pathname == '/dashboard/daily-reports' || props.location.pathname == '/dashboard/monthly-reports' || props.location.pathname == '/dashboard/food-items-reports' || props.location.pathname == '/dashboard/tableVoidReports' || props.location.pathname == '/dashboard/branch-reports' || props.location.pathname == '/dashboard/orderReports' || props.location.pathname == '/dashboard/beverages-reports' || props.location.pathname == '/dashboard/customers' || props.location.pathname == '/dashboard/discount-reports' || props.location.pathname == '/dashboard/canceled-reserve-reports' ? "white" : "black"}`} onClick={()=>{
                    setSubExpanded(!subExpanded);
                  }}/>
                </div>
              </div> : null}
              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Report")
                ?
              <ul className={`t-list fk-pos-nav__sub sidebar_submenu list-group w-100
                ${expanded && subExpanded ? "sidebar_submenu_show d-block" : "d-none"}
                ${props.location.pathname == '/dashboard/reports' || props.location.pathname == '/dashboard/daily-reports' || props.location.pathname == '/dashboard/monthly-reports' || props.location.pathname == '/dashboard/food-items-reports' || props.location.pathname == '/dashboard/tableVoidReports' || props.location.pathname == '/dashboard/branch-reports' || props.location.pathname == '/dashboard/orderReports' || props.location.pathname == '/dashboard/beverages-reports' || props.location.pathname == '/dashboard/customers' || props.location.pathname == '/dashboard/discount-reports' || props.location.pathname == '/dashboard/canceled-reserve-reports' ? "sub_active": ""}
                `}>
                {/* dashboard */}
                {managePageHrefLink("/dashboard/reports", _t(t("Dashboard")))}

                {/* daily */}
                {managePageHrefLink("/dashboard/daily-reports", _t(t("Daily")))}

                {/* monthly */}
                {managePageHrefLink(
                  "/dashboard/canceled-reserve-reports",
                  _t(t("Reservation Reports"))
                )}

                {/* yearly */}
                {/* {managePageHrefLink(
                  "/dashboard/yearly-reports",
                  _t(t("Yearly"))
                )} */}

                {/* food item wise */}
                {managePageHrefLink(
                  "/dashboard/food-items-reports",
                  _t(t("Item Wise"))
                )}

                {/* food group wise */}
                {managePageHrefLink(
                  "/dashboard/customers",
                  _t(t("Delivery History"))
                )}

                {/* branch wise */}
                {/* {managePageHrefLink(
                  "/dashboard/branch-reports",
                  _t(t("Branch Wise"))
                )} */}

                {/* pos user wise */}
                {managePageHrefLink(
                  "/dashboard/beverages-reports",
                  _t(t("Beverages"))
                )}

                {/* food item wise */}
                {managePageHrefLink(
                  "/dashboard/orderReports",
                  _t(t("Order Reports"))
                )}

                {/* Service charge wise */}
                {managePageHrefLink(
                  "/dashboard/tableVoidReports",
                  _t(t("Table Void Reports"))
                )}

                {/* discount charge wise */}
                {managePageHrefLink(
                  "/dashboard/discount-reports",
                  _t(t("discount Wise"))
                )}
              </ul>: null }


              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Report")
                ?
              <div
                // to="/dashboard/reports"
                // exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                ${props.location.pathname == '/dashboard/expenses' || props.location.pathname == '/dashboard/expenseCategory' ? "custome_active-boxicon-container": "" }
                `}
              >
                <NavLink to="/dashboard/expenses" exact >
                  <div className={`menu_icon ${props.location.pathname == '/dashboard/expenses' || props.location.pathname == '/dashboard/expenseCategory' ? "custome_active": "" }`}>
                    <MdAttachMoney size={24} color={`${props.location.pathname == '/dashboard/expenses' || props.location.pathname == '/dashboard/expenseCategory'  ? "white" : "black"}`}/>
                  </div>
                </NavLink>
                <div onClick={()=>{
                    setSubExpand2ed(!subExpanded2);
                  }}
                  className={`custome_description has_submenu
                ${expanded && "custome_show-description"}
                ${subExpanded2 && "sidebar_submenu_show_main"}
                ${props.location.pathname == '/dashboard/expenses' || props.location.pathname == '/dashboard/expenseCategory' ? "custome_active-description": ""}
                `}
                >                  
                    {_t(t("Expense"))}
                  <FiChevronDown size={24} color={`${props.location.pathname == '/dashboard/expenses' || props.location.pathname == '/dashboard/expenseCategory' ? "white" : "black"}`} onClick={()=>{
                    setSubExpand2ed(!subExpanded2);
                  }}/>
                </div>
              </div> : null}
              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Report")
                ?
              <ul className={`t-list fk-pos-nav__sub sidebar_submenu list-group w-100
                ${expanded && subExpanded2 ? "sidebar_submenu_show d-block" : "d-none"}
                ${props.location.pathname == '/dashboard/expenses' || props.location.pathname == '/dashboard/expenseCategory' ? "sub_active": ""}
                `}>
                {/* Expenses */}
                {managePageHrefLink(
                  "/dashboard/expenses",
                  _t(t("Expense"))
                )}

                {/* Category */}
                {managePageHrefLink(
                  "/dashboard/expenseCategory",
                  _t(t("Expense Category"))
                )}
              </ul>: null }


              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Report")
                ?
              <div
                // to="/dashboard/reports"
                // exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                ${props.location.pathname == '/dashboard/booking-slots' ? "custome_active-boxicon-container": "" }
                `}
              >
                <NavLink to="/dashboard/booking-slots" exact >
                  <div className={`menu_icon ${props.location.pathname == '/dashboard/booking-slots' ? "custome_active": "" }`}>
                    <FiSettings size={24} color={`${props.location.pathname == '/dashboard/booking-slots' ? "white" : "black"}`}/>
                  </div>
                </NavLink>
                <div onClick={()=>{
                    setSubExpand3ed(!subExpanded3);
                  }}
                  className={`custome_description has_submenu
                ${expanded && "custome_show-description"}
                ${subExpanded3 && "sidebar_submenu_show_main"}
                ${props.location.pathname == '/dashboard/booking-slots' ? "custome_active-description": ""}
                `}
                >                  
                    {_t(t("Settings"))}
                  <FiChevronDown size={24} color={`${props.location.pathname == '/dashboard/booking-slots' ? "white" : "black"}`} onClick={()=>{
                    setSubExpand3ed(!subExpanded3);
                  }}/>
                </div>
              </div> : null}
              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Report")
                ?
              <ul className={`t-list fk-pos-nav__sub sidebar_submenu list-group w-100
                ${expanded && subExpanded3 ? "sidebar_submenu_show d-block" : "d-none"}
                ${props.location.pathname == '/dashboard/booking-slots' ? "sub_active": ""}
                `}>
                {/* Expenses */}
                {managePageHrefLink(
                  "/dashboard/booking-slots",
                  _t(t("Booking Slots"))
                )}

                {/* Category */}
                {/* {managePageHrefLink(
                  "/dashboard/expenseCategory",
                  _t(t("Expense Category"))
                )} */}
              </ul>: null }

              
              {/* {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Work period")
                ?
              <NavLink
                to="/dashboard/booking-slots"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/booking-slots' && "custome_active"}`}>
                  <MdTimelapse size={25} color={`${props.location.pathname == '/dashboard/booking-slots' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/booking-slots' && "custome_active-description"}
                `}
                >
                  {_t(t("Booking Slots"))}
                </p>
              </NavLink>: null }               */}

              
              
              {/* {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Order history")
                ?
              <NavLink
                to="/dashboard/orderReports"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/orderReports' && "custome_active"}`}>
                  <BiBookBookmark size={24} color={`${props.location.pathname == '/dashboard/orderReports' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/orderReports' && "custome_active-description"}
                `}
                >
                  {_t(t("Order Reports"))}
                </p>
              </NavLink>: null} */}



              {/* {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Order history")
                ?
              <NavLink
                to="/dashboard/tableVoidReports"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/tableVoidReports' && "custome_active"}`}>
                  <BiBookBookmark size={24} color={`${props.location.pathname == '/dashboard/tableVoidReports' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/tableVoidReports' && "custome_active-description"}
                `}
                >
                  {_t(t("Void Table Reports"))}
                </p>
              </NavLink>: null} */}



              {/* {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Customer")
                ?
              <NavLink
                to="/dashboard/customers"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/customers' && "custome_active"}`}>
                  <FiUser size={24} color={`${props.location.pathname == '/dashboard/customers' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/customers' && "custome_active-description"}
                `}
                >
                  {_t(t("Delivery Histories"))}
                </p>
              </NavLink> : null} */}



              {/* {authUserInfo.permissions !== null 
              // && authUserInfo.permissions.includes("Customer")
                ?
              <NavLink
                to="/dashboard/expenseCategory"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/expenseCategory' && "custome_active"}`}>
                  <BiCategory size={24} color={`${props.location.pathname == '/dashboard/expenseCategory' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/expenseCategory' && "custome_active-description"}
                `}
                >
                  {_t(t("Expense Category"))}
                </p>
              </NavLink> : null} */}


              
              {/* {authUserInfo.permissions !== null 
              // && authUserInfo.permissions.includes("Customer")
                ?
              <NavLink
                to="/dashboard/expenses"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/expenses' && "custome_active"}`}>
                  <MdAttachMoney size={24} color={`${props.location.pathname == '/dashboard/expenses' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/expenses' && "custome_active-description"}
                `}
                >
                  {_t(t("Expenses"))}
                </p>
              </NavLink> : null} */}


              {authUserInfo.permissions !== null 
              // && authUserInfo.permissions.includes("Customer")
                ?
              <NavLink
                to="/dashboard/dayClose"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/dayClose' && "custome_active"}`}>
                  <BiCalendarCheck size={24} color={`${props.location.pathname == '/dashboard/dayClose' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/dayClose' && "custome_active-description"}
                `}
                >
                  {_t(t("Day Close"))}
                </p>
              </NavLink> : null}
             
              {/* {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Kitchen")
                ?
              <NavLink
                to="/dashboard/kitchen"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/kitchen' && "custome_active"}`}>
                  <MdRestaurant size={23} color={`${props.location.pathname == '/dashboard/kitchen' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/kitchen' && "custome_active-description"}
                `}
                >
                  {_t(t("Kitchen"))}
                </p>
              </NavLink> : null} */}
              
              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Manage")
                ?
              <NavLink
                to="/dashboard/table-section"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/table-section' && "custome_active"}`}>
                  <MdSpaceDashboard size={24} color={`${props.location.pathname == '/dashboard/table-section' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/table-section' && "custome_active-description"}
                `}
                >
                  {_t(t("Table Section"))}
                </p>
              </NavLink>: null }
              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Manage")
                ?
              <div
                // to="/dashboard/manage/food/add-new"
                // exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                ${props.location.pathname.includes('manage') && "custome_active-boxicon-container"}
                `}
              >
                 <NavLink to="/dashboard/manage/food/add-new" exact >
                  <div className={`menu_icon ${props.location.pathname.includes('manage') && "custome_active"}`}>
                    <MdOutlineAdminPanelSettings size={24} color={`${props.location.pathname.includes('manage') ? "white" : "black"}`}/>
                  </div>
                </NavLink>
                <div
                onClick={()=>{
                  setManageExpanded(!manageExpanded);
                }}
                  className={`custome_description has_submenu
                ${expanded && "custome_show-description"}
                ${manageExpanded && "sidebar_submenu_show_main"}
                ${props.location.pathname.includes('manage') && "custome_active-description"}
                `}
                >
                  {/* <NavLink to="/dashboard/manage/food/add-new" className={`${props.location.pathname.includes('manage') ? "t-text-white" : "t-text-black"}`} exact > */}
                    {_t(t("Manage"))}
                  {/* </NavLink> */}
                  <FiChevronDown size={24} color={`${props.location.pathname.includes('manage') ? "white" : "black"}`} onClick={()=>{
                    setManageExpanded(!manageExpanded);
                  }}/>
                </div>
              </div> : null}
              {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Manage")
                ? <ul className={`t-list fk-pos-nav__sub sidebar_submenu has_child list-group w-100
                ${expanded && manageExpanded && "sidebar_submenu_show d-block"}
                ${props.location.pathname.includes('template') ? "sub_active": ""}
                `}>
                {/* Foods */}
                <li
                  className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                    window.location.pathname.includes("/food/") ? "active" : ""
                  }`}
                  onClick={subMenuClick}
                >
                  <div
                    className="w-100 t-text-dark rounded-0 text-left submenu_list"
                    rel="noopener noreferrer"
                    href="#"
                  >
                    {_t(t("Foods"))}
                    <FiChevronDown size={24} color={`${props.location.pathname.includes("manage") ? "white" : "black"}`}/>
                  </div>
                  <ul className="t-list fk-pos-nav__sub list-group">
                    {managePageHrefLink(
                      "/dashboard/manage/food/add-new",
                      _t(t("Add new item"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/food/all-items",
                      _t(t("All Items"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/food/groups",
                      _t(t("Groups"))
                    )}
    
                    {/* {managePageHrefLink(
                      "/dashboard/manage/food/units",
                      _t(t("Units"))
                    )} */}
    
                    {/* {managePageHrefLink(
                      "/dashboard/manage/food/properties",
                      _t(t("Properties"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/food/variations",
                      _t(t("Variations"))
                    )} */}
                  </ul>
                </li>
    
                {/* stock Management */}
                {showManageStock ? (
                  <li
                    className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                      window.location.pathname.includes("stock") ? "active" : ""
                    }`}
                    onClick={subMenuClick}
                  >
                    <button
                      className="w-100 t-text-dark rounded-0 text-left"
                      rel="noopener noreferrer"
                      href=""
                    >
                      {_t(t("Manage Stock"))}
                    </button>
                    <ul className="t-list fk-pos-nav__sub list-group">
                      {managePageHrefLink(
                        "/dashboard/manage/stock/food-purchase",
                        _t(t("Food Purchase"))
                      )}
                      {managePageHrefLink(
                        "/dashboard/manage/stock/purchase-history-food",
                        _t(t("Purchase History"))
                      )}
    
                      {managePageHrefLink(
                        "/dashboard/manage/stock/ingredient-group",
                        _t(t("Ingredient Group"))
                      )}
                      {managePageHrefLink(
                        "/dashboard/manage/stock/ingredient-item",
                        _t(t("Ingredient Item"))
                      )}
                      {managePageHrefLink(
                        "/dashboard/manage/stock/ingredient-purchase",
                        _t(t("Ingredient Purchase"))
                      )}
                      {managePageHrefLink(
                        "/dashboard/manage/stock/purchase-history-ingredient",
                        _t(t("Purchase History"))
                      )}
                      {/* {managePageHrefLink(
           "/dashboard/manage/stock/ingredient-return",
           _t(t("Ingredient Usage"))
         )} */}
                      {/* {managePageHrefLink(
           "/dashboard/manage/stock/ingredient-usage",
           _t(t("Ingredient Return"))
         )} */}
                      {managePageHrefLink(
                        "/dashboard/manage/stock/manage-supplier",
                        _t(t("Manage Supplier"))
                      )}
                      {managePageHrefLink(
                        "/dashboard/manage/stock/supplier-history",
                        _t(t("Supplier History"))
                      )}
                      {/* {managePageHrefLink(
           "/dashboard/manage/stock/stock-out-food",
           _t(t("Stock Out Food"))
         )}
         {managePageHrefLink(
           "/dashboard/manage/stock/stock-out-ingredient",
           _t(t("Stock Out Ingredient"))
         )} */}
                    </ul>
                  </li>
                ) : (
                  ""
                )}
    
                {/* User Management */}
                <li
                  className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                    window.location.pathname.includes("/roles") ||
                    window.location.pathname.includes("/user/")
                      ? "active"
                      : ""
                  }`}
                  onClick={subMenuClick}
                >
                  <div
                    className="w-100 t-text-dark rounded-0 text-left submenu_list"
                    rel="noopener noreferrer"
                    href="#"
                  >
                    {_t(t("User Management"))}
                    <FiChevronDown size={24} color={`${props.location.pathname.includes("manage") ? "white" : "black"}`}/>
                  </div>
                  <ul className="t-list fk-pos-nav__sub list-group">
                    {managePageHrefLink(
                      "/dashboard/manage/user/admin-staff",
                      _t(t("Admin / Staff"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/user/customers",
                      _t(t("Customers"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/user/waiters",
                      _t(t("Waiters"))
                    )}
    
                    {/* {managePageHrefLink(
                      "/dashboard/manage/roles-and-permissions",
                      _t(t("Role Groups"))
                    )} */}
    
                    {/* {managePageHrefLink(
                      "/dashboard/manage/user/delivery-men",
                      _t(t("Delivery user"))
                    )} */}
    
                    {/* {managePageHrefLink(
                      "/dashboard/manage/user/delivery-request",
                      _t(t("New Delivery user"))
                    )} */}
                  </ul>
                </li>
    
                {/* Restaurant */}
                <li
                  className={`fk-pos-nav__list fk-pos-nav__list-has-sub 
                  ${
                    window.location.pathname.includes("/restaurant") ? "active" : ""
                  }`}
                  onClick={subMenuClick}
                >
                  <div
                    className="w-100 t-text-dark rounded-0 text-left submenu_list"
                    rel="noopener noreferrer"
                    href="#"
                  >
                    {_t(t("Restaurant"))}
                    <FiChevronDown size={24} color={`${props.location.pathname.includes("manage") ? "white" : "black"}`}/>
                  </div>
                  <ul className="t-list fk-pos-nav__sub list-group">
                    {managePageHrefLink(
                      "/dashboard/manage/restaurant/branches",
                      _t(t("Branches"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/restaurant/dept-tags",
                      _t(t("Dept Tags"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/restaurant/tables",
                      _t(t("Tables"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/restaurant/payment-type",
                      _t(t("Payment Types"))
                    )}

                    {managePageHrefLink(
                      "/dashboard/manage/restaurant/company-payment-type",
                      _t(t("Company Payment Types"))
                    )}
                  </ul>
                </li>
    
                {/* Payment */}
                {/* <li
                  className={`fk-pos-nav__list fk-pos-nav__list-has-sub 
                  ${window.location.pathname.includes("/payment") ? "active" : ""}`}
                  onClick={subMenuClick}
                >
                  <div
                    className="w-100 t-text-dark rounded-0 text-left submenu_list"
                    rel="noopener noreferrer"
                    href="#"
                  >
                    {_t(t("Payment"))}
                    <FiChevronDown size={24} color={`${props.location.pathname.includes("manage") ? "white" : "black"}`}/>
                  </div>
                  <ul className="t-list fk-pos-nav__sub list-group">
                    {managePageHrefLink(
                      "/dashboard/manage/payment/setup-paypal",
                      _t(t("Paypal"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/payment/setup-stripe",
                      _t(t("Stripe"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/payment/setup-flutterwave",
                      _t(t("Flutterwave"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/payment/stripe-card-reader-crud",
                      _t(t("Strip card reader"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/payment/list-all-readers",
                      _t(t("All Readers"))
                    )}              
                  </ul>
                </li> */}
    
                {/* sms */}
                {/* <li
                  className={`fk-pos-nav__list fk-pos-nav__list-has-sub 
                  ${window.location.pathname.includes("/sms") ? "active" : ""}`}
                  onClick={subMenuClick}
                >
                  <div
                    className="w-100 t-text-dark rounded-0 text-left submenu_list"
                    rel="noopener noreferrer"
                    href="#"
                  >
                    {_t(t("Sms settings"))}
                    <FiChevronDown size={24} color={`${props.location.pathname.includes("manage") ? "white" : "black"}`}/>
                  </div>
                  <ul className="t-list fk-pos-nav__sub list-group">
                    {managePageHrefLink(
                      "/dashboard/manage/sms/setup-twilio",
                      _t(t("Twilio"))
                    )}
                  </ul>
                </li> */}
    
                {/* Settings */}
                {/* <li
                  className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                    window.location.pathname.includes("/settings/") ? "active" : ""
                  }`}
                  onClick={subMenuClick}
                >
                  <div
                    className="w-100 t-text-dark rounded-0 text-left submenu_list"
                    rel="noopener noreferrer"
                    href="#"
                  >
                    {_t(t("Settings"))}
                    <FiChevronDown size={24} color={`${props.location.pathname.includes("manage") ? "white" : "black"}`}/>
                  </div>
                  <ul className="t-list fk-pos-nav__sub list-group">
                    {managePageHrefLink(
                      "/dashboard/manage/settings/currencies",
                      _t(t("Currencies"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/settings/disable-frontend",
                      _t(t("Website"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/settings/languages",
                      _t(t("Languages"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/settings/smtp-settings",
                      _t(t("Email / Smtp"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/settings/general-settings",
                      _t(t("General Settings"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/settings/show-delivery-menu",
                      _t(t("Show deliverymen"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/settings/show-manage-stock-menu",
                      _t(t("Show manage stock"))
                    )}
    
                    {managePageHrefLink(
                      "/dashboard/manage/settings/show-google-capture",
                      _t(t("Google reCAPTCHA"))
                    )}
                  </ul>
                </li> */}
              </ul>: null}
              {/* {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Manage")
                ?
              <div
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                ${props.location.pathname.includes('template') && "custome_active-boxicon-container"}
                `}
              >
                <NavLink to="/dashboard/template/edit-hero" exact >
                  <div className={`menu_icon ${props.location.pathname.includes('template') && "custome_active"}`}>
                    <BiLayout size={24} color={`${props.location.pathname.includes('template') ? "white" : "black"}`}/>
                  </div>
                </NavLink>
                <div onClick={()=>{
                    setTempExpanded(!tempExpanded);
                  }}
                  className={`custome_description has_submenu
                ${expanded && "custome_show-description"}
                ${tempExpanded && "sidebar_submenu_show_main"}
                ${props.location.pathname.includes('template') && "custome_active-description"}
                `}
                >
                  
                    {_t(t("Template settings"))}
                  
                  <FiChevronDown size={24} color={`${props.location.pathname.includes('template') ? "white" : "black"}`} onClick={()=>{
                    setTempExpanded(!tempExpanded);
                  }}/>
                </div>
              </div> : null} */}
              {/* {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Manage")
                ?
                <>
              {templateValue == 1 ? (
                <ul className={`t-list fk-pos-nav__sub sidebar_submenu list-group w-100
                ${expanded && tempExpanded && "sidebar_submenu_show d-block"}
                ${props.location.pathname.includes('template') ? "sub_active": ""}
                `}>
                  {managePageHrefLink(
                    "/dashboard/template/hero-section",
                    _t(t("Hero"))
                  )}
                  {managePageHrefLink(
                    "/dashboard/template/promotions",
                    _t(t("Promotions"))
                  )}
                  {managePageHrefLink(
                    "/dashboard/template/hero-one-slider",
                    _t(t("Hero slider"))
                  )}
                  {managePageHrefLink(
                    "/dashboard/template/change-template",
                    _t(t("Choose template"))
                  )}
                </ul>
              ) : (
                <ul className={`t-list fk-pos-nav__sub sidebar_submenu list-group w-100
                ${expanded && tempExpanded && "sidebar_submenu_show d-block"}
                ${props.location.pathname.includes('template') ? "sub_active": ""}
                `}>
                  {managePageHrefLink(
                    "/dashboard/template/edit-hero",
                    _t(t("Hero section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/edit-about",
                    _t(t("About section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/edit-feature",
                    _t(t("Feature section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/edit-gellary",
                    _t(t("Gallery section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/edit-delivery",
                    _t(t("Delivery section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/change-template",
                    _t(t("Choose template"))
                  )}
                </ul>
              )}
              </>:null} */}
              {/* {authUserInfo.permissions !== null &&
              authUserInfo.permissions.includes("Manage")
                ?  <NavLink
                to="/dashboard/reservation/reservation-panel"
                exact
                className={`custome_boxicon-container w-100  ${
                  expanded && "custome_expanded-boxicon-container"
                }
                
                `}
              >
                <div className={`menu_icon ${props.location.pathname == '/dashboard/reservation/reservation-panel' && "custome_active"}`}>
                  <MdOutlineBrunchDining size={24} color={`${props.location.pathname == '/dashboard/reservation/reservation-panel' ? "white" : "black"}`}/>
                </div>
                <p
                  className={`custome_description 
                ${expanded && "custome_show-description"}
                ${props.location.pathname == '/dashboard/reservation/reservation-panel' && "custome_active-description"}
                `}
                >
                  {_t(t("Reservation"))}
                </p>
              </NavLink>
                : null} */}
              {SAAS_APPLICATION == "YES"
                ? [
                    authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Saas profile")
                      ? <NavLink
                      to="/dashboard/saas-profile"
                      exact
                      className={`custome_boxicon-container w-100  ${
                        expanded && "custome_expanded-boxicon-container"
                      }
                      
                      `}
                    >
                      <div className={`menu_icon ${props.location.pathname == '/dashboard/saas-profile' && "custome_active"}`}>
                        <FiLayout size={24} color={`${props.location.pathname == '/dashboard/saas-profile' ? "white" : "black"}`}/>
                      </div>
                      <p
                        className={`custome_description 
                      ${expanded && "custome_show-description"}
                      ${props.location.pathname == '/dashboard/saas-profile' && "custome_active-description"}
                      `}
                      >
                        {_t(t("Subscription"))}
                      </p>
                    </NavLink> : null,
                  ]
                : ""}
            </div>
            {/* menu footer section */}
            <div
              className={`card-footer p-0 custome_boxicon-container w-100 sidebar_footer ${
                expanded && "custome_expanded-boxicon-container"
              }
              `}
              onClick={handleLogout}
            >
              <div className={`menu_icon logout`}>
                <HiOutlineLogout size={24} color="black"/>
              </div>
              <p
                className={`custome_description 
              ${expanded && "custome_show-description"}
              `}
              >
                 {_t(t("Logout"))}
              </p>
            </div>
            {/* {menuItems.map((item, index) => {
              let middle = false;
              if (!(index === 0 || index === menuItems.length - 1)) {
                middle = true;
              }
              return (
                <div
                  className={`custome_boxicon-container ${
                    expanded && "custome_expanded-boxicon-container"
                  }`}
                  onMouseEnter={() => {
                    if (middle) {
                      setHovered(index);
                    }
                  }}
                  onMouseLeave={() => {
                    if (middle) {
                      setHovered(null);
                    }
                  }}
                  onClick={() => {
                    if (middle) {
                      setActive(index);
                    }
                    if (index === 0) {
                      setExpanded(!expanded);
                    }
                  }}
                  key={index}
                >
                  <box-icon
                    class={`${middle && "custome_boxicon"} 
                            ${!middle && "custome_first-and-last-trash-fix"}
                            ${active === index && "custome_active"}
                            `}
                    size={changeSmall ? "sm" : "md"}
                    name={item.iconName}
                    type={item.type}
                    color={
                      hovered === index || active === index ? "white" : item.color
                    }
                    animation={active === index && animate ? "tada" : ""}
                    rotate={item.rotate}
                  ></box-icon>
                  <p
                    className={`custome_description 
                  ${expanded && "custome_show-description"}
                  ${active === index && "custome_active-description"}`}
                  >
                    {item.name}
                  </p>
                </div>
              );
            })} */}
          </div>
        </div>
      ) : (
        <></>
      )}
      </>
      
    );
  };

  export default withRouter(Sidebar);