import React,{FC, useState, useEffect} from 'react'
import { saveExpense, getExpense, updateExpense, deleteExpense } from './_requests'
import { getCategories } from "../expense_category/_requests"
import { MdEdit, MdDelete } from "react-icons/md";
import { BiX } from "react-icons/bi";
import { _t } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const Expense = () => {

  const { t } = useTranslation();

  const[expense, setExpense] = useState([]);
  const[categories, setCategories] = useState([]);
  const[id, setId] = useState('');
  const[selectedCategory, setSelectedCategory] = useState('');

  const ExpenseList = async () => {
    const Data = await getExpense();
    if(Data != null) {
      setExpense(Data);
    }
  }

  const CategoryList = async () => {
    const Data = await getCategories();
    if(Data != null) {
      setCategories(Data);
    }
  }

  const saveExpenses = async () => {
    const exp = document.getElementById('expenseUpdate').value;
    const amt = document.getElementById('amountUpdate').value;
    const expDate = document.getElementById('expDateUpdate').value;
    if(!id) {
    let body = {
      "category": selectedCategory,
      "description": exp,
      "amount": amt,
      "expdate": expDate
  }
    const Data = await saveExpense(body);
    if(Data != null) {
      reset();
      setExpense(Data);
    }
  } else {
    let body = {
      "id": id,
      "category": selectedCategory,
      "description": exp,
      "amount": amt,
      "expdate": expDate
  }
    const Data = await updateExpense(body);
    if(Data != null) {
      reset();
      setExpense(Data);
      setId('');
    }
  }
  }

  const reset = () => {
    document.getElementById('expenseUpdate').value='';
    document.getElementById('amountUpdate').value='';
    document.getElementById('expDateUpdate').value='';
    setId('');
  }

  // const handleSetEdit = async () => {
  //   const cat = document.getElementById('categoryUpdate').value;
  //   let body = {
  //     "id": id,
  //     "category": cat
  //   }
  //   const Data = await updateExpense(body);
  //   if(Data != null) {
  //     document.getElementById('categoryUpdate').value='';
  //     setExpense(Data);
  //   }

  // }

  const handleDelete = async () => {
    const data = await deleteExpense(id)
    if(data != null) {
      setExpense(data);
    }
  }

  const handleSetCategoryId = async (id) => {
    setSelectedCategory(id)
    const Data = await getExpense();
    if(Data != null) {
      setExpense(Data);
    }
  }

    useEffect(() => {
      ExpenseList();
      CategoryList();
    }, []);

    return (
    <div className="container mt-2">
      <div className='modal fade' id={'deleteExpense'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Confirmation</h3>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal'>
                      <BiX size={20}/>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <p>Are you sure want to delete?</p>
                    <div className='d-flex align-items-center justify-content-end'>
                        <button className='btn btn-sm btn-secondary mt-3 mx-3' data-dismiss='modal'>
                            No
                        </button>
                        <button className='btn btn-sm text-primary mt-3' data-dismiss='modal' 
                        onClick={(e) => handleDelete()}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='modal fade' id={'addExpense'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                  {id ? <h3>Update Expense</h3> :
                    <h3>Add Expense</h3>}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal' onClick={() => reset()}>
                      <BiX size={20}/>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                <div className='row'>
                  <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 py-3'>
                    <Select
                      options={categories && categories}
                      components={makeAnimated()}
                      getOptionLabel={(option) => option.category}
                      getOptionValue={(option) => option.id}
                      classNamePrefix="select"
                      className="xsm-text w-100"
                      onChange={(option) => handleSetCategoryId(option.id)}
                      maxMenuHeight="200px"
                      placeholder={
                        _t(t("Please select a category")) + ".."
                      }
                    />
                  </div>
                  <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 py-3'>
                    <input type="text" placeholder="Expense..." id='expenseUpdate' className="form-control border-0 form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 py-3'>
                    <input type="number" placeholder="Amount..." id='amountUpdate' className="form-control border-0 form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 py-3'>
                    <input type="date" id='expDateUpdate' className="form-control border-0 form-control--light-1 rounded-0"/>
                  </div>
                </div>
                </div>
                <div className='modal-footer'>                  
                  <div className='d-flex justify-content-end'>
                    <button type='button' onClick={() => reset()} className='btn btn-transparent btn-secondary sm-text text-dark mr-2 text-uppercase w-100' data-dismiss='modal'>Cancel</button>
                    <button type='button' onClick={saveExpenses} className='btn btn-transparent btn-primary sm-text text-uppercase w-100' data-dismiss='modal'>Save</button>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <form>
      <div className='w-100'>
        <div className='t-bg-white sm_shadow sm_card expense_card overflow-auto'>
        <ul className="t-list fk-breadcrumb p-4 pt-4 pb-0" >
          <li className="fk-breadcrumb__list">
            <span className="t-link fk-breadcrumb__link text-capitalize">
              {_t(t("Expense"))}
            </span>
          </li>
        </ul>
          <div className='row d-flex justify-content-end container'>            
            <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-3 pt-0'>
                <button type='button' data-toggle="modal"
                  data-target={"#addExpense"} className='btn btn-transparent btn-primary sm-text text-uppercase w-100'>Add New</button>
            </div>
          </div>
          <div className='' data-simplebar>
            <div className="t-pl-15 t-pr-15 overflow-auto">
              <div>
              <table className='table table-bordered table-hover mt-4'> 
              <thead className="align-middle">
                <tr>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">No.</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Expense</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Amount</th>
                  {/* <th scope="col" className="sm-text align-middle text-center border-1 border">Created at</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Expiry Date</th> */}
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Actions</th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {expense.map((cate, i) => {
                  return(
                <>                
                <tr>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{i+1}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.description}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.amount}</td>
                  {/* <td scope="col" className="sm-text align-middle text-center border-1 border">
                    <Moment format="DD-MMMM-YYYY">
                    {new Date(cate.created_at)}
                    </Moment></td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">
                    <Moment format="DD-MMMM-YYYY">
                    {new Date(cate.expdate
                      )}
                    </Moment></td> */}
                  <td scope="col" className="sm-text align-middle text-center border-1 border">
                  <button 
                    data-toggle="modal"
                    data-target={"#addExpense"}
                    type="button" className="btn btn-sm btn-outline-primary border-0 mx-1"
                    onClick={() => {
                    setId(cate.id);
                    document.getElementById('expenseUpdate').value = cate.description;
                    document.getElementById('amountUpdate').value = cate.amount;
                    document.getElementById('expDateUpdate').value = cate.expdate;
                    }}>
                      <MdEdit size={20}/>
                    </button>
                    <button 
                    data-toggle="modal"
                    data-target={"#deleteExpense"}
                    type="button" className="btn btn-sm btn-outline-danger border-0 mx-1"
                    onClick={() => setId(cate.id)}>
                      <MdDelete size={20}/>
                    </button>
                  </td>
                </tr></>)})}
              </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
    )
}

export default Expense

