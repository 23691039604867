import { BASE_URL } from "../../../../BaseUrl";
import { getCookie } from "../../../../functions/Functions";
import axios from "axios";


export const GET_TABLES_URL = `${BASE_URL}/settings/get-table`;
export const GET_FOOD_ITEMS_URL = `${BASE_URL}/settings/get-food-item`;
export const CANCEL_SUBMITTED_ORDER_URL = `${BASE_URL}/settings/cancel-submitted-order`;
export const SHIFT_TABLE_URL = `${BASE_URL}/settings/shift-table`;
export const GST_UPDATE_URL = `${BASE_URL}/settings/gst-update`;
export const UPDATE_ITEM_QUNTITY_URL = `${BASE_URL}/settings/update-order-item`;
export const UPDATE_DISCOUNT_AMOUNT_URL = `${BASE_URL}/settings/update-order-discount-amount`;


export function getGetTables(id) {
    return axios.get(GET_TABLES_URL+'/'+id, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response))
}

export function updateDiscountAmount(id, obj) {
    return axios.put(UPDATE_DISCOUNT_AMOUNT_URL+'/'+id, obj, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response))
}

export function getFoodItems(id) {
    return axios.get(GET_FOOD_ITEMS_URL+'/'+id, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response))
}

export function cancelSbumittedOrder(data) {
    return axios.post(CANCEL_SUBMITTED_ORDER_URL, data, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response))
}

export function tableShift(data) {
    return axios.post(SHIFT_TABLE_URL, data, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response))
}

export function uppdateGST(data) {
    return axios.post(GST_UPDATE_URL, data, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response))
}

export function uppdateItemQuantity(id, data) {
    return axios.put(UPDATE_ITEM_QUNTITY_URL+'/'+id, data, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response))
}