import React,{FC, useState, useEffect} from 'react'
import { saveCategories, getCategories, updateCategories, deleteCategories } from './_requests'
import { MdEdit, MdDelete } from "react-icons/md";
import { BiX } from "react-icons/bi";
import { _t } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

const ExpenseCategory = () => {

  const { t } = useTranslation();

  const[categories, setCategories] = useState([]);
  const[id, setId] = useState('');

  const CategoryList = async () => {
    const Data = await getCategories();
    if(Data != null) {
      setCategories(Data);
    }
  }

  const saveCategory = async () => {
    const cat = document.getElementById('category').value;
    let body = {
      "category": cat
    }
    const Data = await saveCategories(body);
    if(Data != null) {
      document.getElementById('category').value='';
      setCategories(Data);
    }
  }

  const handleSetEdit = async () => {
    const cat = document.getElementById('categoryUpdate').value;
    let body = {
      "id": id,
      "category": cat
    }
    const Data = await updateCategories(body);
    if(Data != null) {
      document.getElementById('categoryUpdate').value='';
      setCategories(Data);
    }

  }

  const handleDelete = async () => {
    const data = await deleteCategories(id)
    if(data != null) {
      setCategories(data);
    }
  }

    useEffect(() => {
      CategoryList();
    }, []);

    return (
    <div className="container mt-2">
      <div className='modal fade' id={'deleteCategory'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Confirmation</h3>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal'>
                      <BiX size={20}/>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <p>Are you sure want to delete?</p>
                    <div className='d-flex align-items-center justify-content-end'>
                        <button className='btn btn-sm btn-secondary mt-3 mx-3' data-dismiss='modal'>
                            No
                        </button>
                        <button className='btn btn-sm text-primary mt-3' data-dismiss='modal' 
                        onClick={(e) => handleDelete()}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='modal fade' id={'updateCategory'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Confirmation</h3>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal'>
                      <BiX size={20}/>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                <div className='row'>
                  <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 py-3'>
                    <input type="text" placeholder="Category..." id='categoryUpdate' className="form-control border-0 form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 py-3 px-2'>
                    <button type='button' onClick={handleSetEdit} className='btn btn-transparent btn-primary sm-text text-uppercase w-100' data-dismiss='modal'>Save</button>
                  </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <form>
      <div className='w-100'>
        <div className='card t-bg-white sm_shadow sm_card expense_card overflow-hidden'>
          <div className='row d-flex justify-content-between container'>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 pt-3 d-flex align-items-center pb-0'>
              <ul className="t-list fk-breadcrumb">
                <li className="fk-breadcrumb__list">
                  <span className="t-link fk-breadcrumb__link text-capitalize">
                    {_t(t("Expense Categories"))}
                  </span>
                </li>
              </ul>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-3 pb-0'>
              <div className='row'>
                <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 pt-3'>
                  <input type="text" placeholder="Category..." id='category' className="form-control border-0 form-control--light-1 rounded-0"/>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 pt-3 px-2 pb-0'>
                  <button type='button' onClick={saveCategory} className='btn btn-transparent btn-primary sm-text text-uppercase w-100'>Save</button>
                </div>
              </div>
            </div>
          </div>
          <div className='card-body overflow-auto pt-0'>
            <div className="t-pl-15 t-pr-15">
              <div>
              <table className='table table-bordered table-hover mt-4'> 
              <thead className="align-middle">
                <tr>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">No.</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Categories</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Actions</th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {categories.map((cate, i) => {
                  return(
                <>                
                <tr>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{i+1}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.category}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">
                  <button 
                    data-toggle="modal"
                    data-target={"#updateCategory"}
                    type="button" className="btn btn-sm btn-outline-primary border-0 mx-1"
                    onClick={() => {
                    setId(cate.id);
                    document.getElementById('categoryUpdate').value = cate.category;
                    }}>
                      <MdEdit size={20}/>
                    </button>
                    <button 
                    data-toggle="modal"
                    data-target={"#deleteCategory"}
                    type="button" className="btn btn-sm btn-outline-danger border-0 mx-1"
                    onClick={() => setId(cate.id)}>
                      <MdDelete size={20}/>
                    </button>
                  </td>
                </tr></>)})}
              </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
    )
}

export default ExpenseCategory

