import React,{FC, useState, useEffect, useContext} from 'react'
import { UserContext } from "../../../../contexts/User";
import { _t } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { getReservationReports } from "./_requests"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdEdit, MdDelete } from "react-icons/md";
import { BiX } from "react-icons/bi";
import Moment from "react-moment";

const ReservationReports = () => {

    const { t } = useTranslation();

    const {authUserInfo} = useContext(UserContext);

    const [loading, setLoading] = useState(false)
    const [reserveReportList, setReserveReportList] = useState([])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
   
    const reservationReportList = async () => {
        const body = {
            "fromDate": startDate,
            "toDate": endDate
        }
        const response = await getReservationReports(body);
        setReserveReportList(response[2])
    }
    console.log('reserveReportList', reserveReportList)
    // useEffect(() => {
    //     reservationReportList()
    // }, []);

    return (
        <>
        <div className="container mt-2">
      {/* <div className='modal fade' id={'deleteExpense'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Confirmation</h3>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal'>
                      <BiX size={20}/>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <p>Are you sure want to delete?</p>
                    <div className='d-flex align-items-center justify-content-end'>
                        <button className='btn btn-sm btn-secondary mt-3 mx-3' data-dismiss='modal'>
                            No
                        </button>
                        <button className='btn btn-sm text-primary mt-3' data-dismiss='modal' 
                        // onClick={(e) => handleDelete()}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* <div className='modal fade' id={'addDayClose'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <div className='pb-0'>
                        <div className='p-3 d-flex justify-content-end'>
                            <input type="date" id='orderReportDate' onChange={(e) => searchDateOrders(e.target.value)} className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                <div className='row'>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                    <input type="date" placeholder="Date..." id='dayCloseDate' className="form-control form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                    <input type="number" placeholder="Total Sale..." id='dayCloseSale' className="form-control form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                    <input type="number" placeholder="Total Expense..." id='dayCloseExpense' className="form-control form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                    <input type="number" placeholder="Cash..." id='dayCloseCash' className="form-control form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                    <input type="number" placeholder="Online (UPI)..." id='dayCloseOnline' className="form-control form-control--light-1 rounded-0"/>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                    <input type="number" placeholder="Card Payment..." id='dayClosecard' className="form-control form-control--light-1 rounded-0"/>
                  </div>
                </div>
                </div>
                <div className='modal-footer'>                  
                  <div className='d-flex justify-content-end'>
                    <button type='button' onClick={() => reset()} className='btn btn-transparent btn-secondary sm-text text-dark mr-2 text-uppercase w-100' data-dismiss='modal'>Cancel</button>
                    <button type='button' onClick={dayCloseSave} className='btn btn-transparent btn-primary sm-text text-uppercase w-100' data-dismiss='modal'>Save</button>
                  </div>
                </div>
            </div>
        </div>
    </div> */}
    <form id='dayCloseForm'>
      <div className='w-100'>
        <div className='t-bg-white sm_shadow sm_card expense_card overflow-auto'>
          <div className='row w-100 justify-content-between'>
            <ul className="col-md-3 t-list fk-breadcrumb pl-4 pt-4 pb-0" >
              <li className="fk-breadcrumb__list">
                <span className="t-link fk-breadcrumb__link text-capitalize">
                  {_t(t("Reservation Report"))}
                </span>
              </li>
            </ul>            
            <div className="row t-mt-10 gx-2 mt-2 justify-content-end">
                <div className="col-12 col-md-2 d-md-block">
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control sm-text py-2 t-mb-15 mb-md-0"
                    placeholderText={_t(t("From date"))}
                    shouldCloseOnSelect={false}
                />
                </div>
                <div className="col-12 col-md-2 t-mb-15 mb-md-0">
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control sm-text py-2"
                    placeholderText={_t(t("To date"))}
                    shouldCloseOnSelect={false}
                />
                </div>
                <div className="col-5 col-md-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                <button type='button'
                    className="btn btn-block btn-primary text-uppercase sm-text py-2"
                    onClick={reservationReportList}
                >
                    {_t(t("Generate Report"))}
                </button>
                </div>
                {/* <div className="col-5 col-md-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                <Link
                    to='/dashboard/online-food-items-reports'
                    className="btn btn-block btn-secondary text-uppercase sm-text py-2"

                >
                    {_t(t("online orders"))}
                </Link>
                </div> */}

                <div className="col-5 col-md-8 t-mb-15 mb-md-0 d-block d-md-none">
                <button type='button'
                    className="btn btn-block btn-primary text-uppercase sm-text"
                    onClick={reservationReportList}
                >
                    {_t(t("Generate Report"))}
                </button>
                </div>
            </div>
          </div>
          <div className='' data-simplebar>
            <div className="t-pl-15 t-pr-15 overflow-auto">
              <div>
                {reserveReportList.length != 0 &&
                <table className='table table-bordered table-hover mt-4'> 
                <thead className="align-middle">
                    <tr>
                    <th scope="col" className="sm-text align-middle text-center border-1 border">No.</th>
                    <th scope="col" className="sm-text align-middle text-center border-1 border">Date</th>
                    <th scope="col" className="sm-text align-middle text-center border-1 border">Wait Listed</th>
                    <th scope="col" className="sm-text align-middle text-center border-1 border">Arrived</th>
                    <th scope="col" className="sm-text align-middle text-center border-1 border">Confirmed</th>
                    <th scope="col" className="sm-text align-middle text-center border-1 border">Partial Seated</th>
                    <th scope="col" className="sm-text align-middle text-center border-1 border">Canceled</th>
                    </tr>
                </thead>
                <tbody className="align-middle">
                    {reserveReportList.map((cate, i) => {
                    return(
                    <>                
                    <tr>
                    <td scope="col" className="sm-text align-middle text-center border-1 border">{i+1}</td>
                    <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.reservation_date}</td>
                    <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.total}</td>
                    <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.status}</td>
                    <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.status}</td>
                    <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.status}</td>
                    <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.status}</td>
                    </tr></>)})}
                </tbody>
                </table>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
        </>
    )
}
export default ReservationReports