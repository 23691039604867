import React, { useEffect, useContext, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { _t, getCookie, currencySymbolLeft, formatPrice, currencySymbolRight, getSystemSettings, } from "../../../../functions/Functions";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { foodList } from "../pos/Pos";
import { UserContext } from "../../../../contexts/User";
import { FoodContext } from "../../../../contexts/Food";
import Moment from "react-moment";
import { SettingsContext } from "../../../../contexts/Settings";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calculator from "../pos/calc/Calculator";
import { WithContext as ReactTags } from 'react-tag-input';
import { MdPerson, MdCall, MdGroups, MdManageAccounts } from "react-icons/md";
import { printBillStatusChange } from "../pos/requests"
import { getGetTables, getFoodItems, cancelSbumittedOrder, tableShift, uppdateGST, uppdateItemQuantity, updateDiscountAmount } from "./requests"
import Skeleton from "react-loading-skeleton";
import { getAvalilableTables } from "../smReservation/requests"
import { BiX } from "react-icons/bi";
import { date } from "yup";
// import 'bootstrap/dist/css/bootstrap.css';

let reserveTags = [
  'BirthDay Party',
  'Reunion',
  'Family Party'
]

const suggestions = reserveTags.map(country => {
  return {
    id: country,
    text: country
  };
});

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
  
const ReservationTable = () => {

  const [tableKaData, setTableKaData] = useState(0);
  const [qdty, setQty] = useState(null);
  const [btnEnable, setBtnEnable] = useState(false);
  const [selectedTableData, setSelectedTableData] = useState({});
  console.log('tableKaData3423', tableKaData)

  const addDiscount = async (id) => {
    let discount = document.getElementById('discountAmount12343425345345')?.value;
    let body ={
      'discount': discount,
      'order_bill': totalPayable,
      'total_payable': totalPayable,
      'paid_amount': totalPayable,
      'discount_type': discountType,
    }
    const response = await updateDiscountAmount(id, body)
    if(response) {
      toast.success(`${_t(t("Discount Applied Successfully"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }

  const onTableSelect = async (table) => {
    if(orderDetails.discount_type == 'flat') {
      setTotalPaybale(totalPayable-orderDetails.discount)
    } else {
      setTotalPaybale(totalPayable * orderDetails.discount/100)
    }    
    setSelectedtableOrderId(table.orderid);
    if(table.print_status == 1) {
    setPrintStatus('printed');
    }
    console.log('thisisthetable', table)
    setOnTableLoading(true);
    // api call for table data get
    const url = BASE_URL + "/settings/get-submitted-orders-bytable?orderid="+table.orderid;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      console.log('tableRes', res.data[0].data[0]);
      setTableKaData(res.data[0]?.data[0]?.advance)
      let tableData = res.data[0].data[0];
      console.log('tableData', res.data[0]?.data[0]?.advance);
      setOnTableLoading(false);
      setSelectedTable(table);
      setSelectedTableData(tableData);
      setSelectedOrderId(tableData.id)
      let orderList = tableData?.orderedItems;
      console.log(orderList);
      let newOrderList = [];
      for(let i = 0; i < orderList.length; i++){
        let data = {
          "item": {
            "id": orderList[i].id,
            "food_group_id": orderList[i].food_group_id,
            "food_group": orderList[i].food_group,
            "name": orderList[i].food_item,
            "item_id": orderList[i].food_item_id,
            "cancel_status": orderList[i].cancel_status,
            "slug": orderList[i].slug,
            "is_special": orderList[i].is_special,
            "image": orderList[i].image,
            "has_variation": orderList[i].variation != null ? '1': '0',
            "price": orderList[i].price,
            "has_property": orderList[i].properties != null ? '1' : '0'
          },
        "variation":orderList[i].variation,
        "quantity":orderList[i].quantity,
        
        }
        newOrderList.push(data);
        totalPrice(newOrderList);
      }
      console.log(tableData.service_charge);
      setOrderDetails({
        ...orderDetails,
        total_guest: tableData.total_guest,
        bill_no: tableData.bill_no,
        serviceCharge: parseFloat(
          tableData.service_charge
        ),
        discount: parseFloat(
          tableData.discount
        ),
        discount_type: tableData.discount_type,
        branch: tableData.theBranch,
        customer: {
          "id": tableData.customer_id,
          "name": tableData.customer_name,
          "email": "customer@gmail.com",
          "type": null,
          "phn_no": "1234567890",
          "address": "erwrwe",
          "gst_name": tableData.gst_name,
          "gst_number": tableData.gst_number,
          "branch_id": tableData.branch_id,
          "branch_name": tableData.branch_name,
          "slug": tableData.customer_id+'-'+tableData.customer_name
        },
        debtor: null,
        table: {
          "id": tableData.table_id,
          "name": tableData.table_name,
          "slug": "6No-1663089321-table-04",
          "capacity": "08",
          "assigned_to_section":"no",
          "branch_id": tableData.branch_id,
          "status":"open",
          "reservation_infos":null,
          "waiters":null,
          "branch_name":tableData.branch_name
        },
        waiter: {
          "id": tableData.waiter_id,
          "name": tableData.waiter_name,
          "phn_no": "1231231230",
          "branch_id": tableData.branch_id,
          "branch_name": tableData.branch_name,
          "slug":"NsO-1663090243-captan2",
          "image": null
        },
        dept_tag: null,
        payment_type: null,
        payment_amount: null,
        total_guest: tableData.total_guest,
        orderItems: newOrderList
      });
      // orderDetails.serviceCharge = tableData.service_charge;
      // orderDetails.discount = tableData.discount;
      setNewOrder(newOrderList);
      
    } catch { 
      setOnTableLoading(false);
      setSelectedTable(null);
      setSelectedOrderId(null);
      setSelectedTableData({});
    }
  }

    const [resTags, setResTags] = React.useState([
      { id: 'BirthDay Party', text: 'BirthDay Party' },
      { id: 'Reunion', text: 'Reunion' },
      { id: 'Family Party', text: 'Family Party' },
    ]);

    const handleTagDelete = i => {
      setResTags(resTags.filter((resTag, index) => index !== i));
    };  

    const updateQuanity = async (qty, prc, act) => {
      setQty(prc)
      if(printStatus == 'printed' && act == 'less') {
        document.getElementById('lessReason')?.click();        
      }else if (act == 'less') {
        let body = {
          'action': act,
          'quantity': qty,
          'order_id': selectedOrderId,
          'reason': '',
          'price': prc.item.price
        }
        const response = await uppdateItemQuantity(prc.item.id, body)
        if(response) {
          handleOrderItemQty("-");
          resTablePOS();
        }
      }
       else {
      console.log('Quantity', prc)
      let body = {
        'action': act,
        'quantity': qty,
        'order_id': selectedOrderId,
        'reason': '',
        'price': prc.item.price
      }
      const response = await uppdateItemQuantity(prc.item.id, body)
      if(response) {
        handleOrderItemQty("+");
        resTablePOS();
      } 
    }
    }

    const afterPrintCanceling = async () => {      
      if(document.getElementById('qtyLessReason')?.value) {
      let body = {
        'action': 'less',
        'quantity': qdty.quantity-1,
        'reason': document.getElementById('qtyLessReason')?.value,
        'order_id': selectedOrderId,
        'price': qdty.item.price
      }
      const response = await uppdateItemQuantity(qdty.item.id, body) 
      if(response) {
        document.getElementById('qtyLessClose')?.click();
        handleOrderItemQty("-");
        resTablePOS();
      }
    } else {
      toast.error(`${_t(t("Enter the reason..."))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
    }

    const handleAddition = resTag => {
      setResTags([...resTags, resTag]);
    };

    const handleDrag = (resTag, currPos, newPos) => {
      const newTags = resTags.slice();
  
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, resTag);
  
      // re-render
      setResTags(newTags);
    };
  
    const handleTagClick = index => {
      console.log('The tag at index ' + index + ' was clicked');
    };

    const {
        //common
        loading,
        setLoading,
        generalSettings,
        showManageStock,
      } = useContext(SettingsContext);

    const [orderDate, setOrderDate] = useState(null);
      
    const { t } = useTranslation();
    const [foodItem, setFoodItem] = useState({
        foodGroup: null,
        items: null,
        selectedItem: null,
        variations: null,
        properties: null,
      });

      
    //orderFoodGroups
    const [orderFoodGroups, setOrderFoodGroups] = useState(null);
    // show settle
    const [showSettle, setShowSettle] = useState(false);
    //checked variations
    const [selectedVariation, setSelectedVariation] = useState([]);
    //calculator
    const [calculatorMenu, setCalculatorMenu] = useState(false);
    //selected groups
    const [selectedPropertyGroup, setSelectedPropertyGroup] = useState([]);
    //checked properties
    const [selectedProperties, setSelectedProperties] = useState([]);

    // search state
    const [searchFoodItems, setSearchFoodItems] = useState([]);
    const [availableTables, setAvailableTables] = useState([]);
    const [onTableLoading, setOnTableLoading] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedTable, setSelectedTable] = useState(null);
    
    const [selectedtableOrderId, setSelectedtableOrderId] = useState(0);
    const [newOrder, setNewOrder] = useState(null);
    //total payable
    const [totalPayable, setTotalPaybale] = useState(0);
    // paidMoney
    const [paidMoney, setPaidMoney] = useState(0);
    //return
    const [activeItemInOrder, setActiveItemInOrder] = useState(null);
    const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);
    const [theVat, setTheVat] = useState(0);
    const [deptCommission, setDeptCommission] = useState(0);
    const [theSubTotal, setTheSubTotal] = useState(0);
    //online order counter
    const [onlineCounter, setOnlineCounter] = useState(0);
    const [printStatus, setPrintStatus] = useState('none');
    const [slotId, setSlotId] = useState('');
    
    //vat settings
    const [newSettings, setNewSettings] = useState(null);
    //show stripe card reader button
    const [showCardReaderBUtton, setshowCardReaderBUtton] = useState("NO");
    const [discountType, setDiscountType] = useState("percentage");
    const [isSlotEmpty, setIsSlotEmpty] =  useState(true);
    const [tablesVisible, setTablesVisible] =  useState(false);
    const [comment, setComment] =  useState(true);
    
    //order details users
    const [orderDetailUsers, setOrderDetailusers] = useState({
      theCustomers: null,
      theTables: null,
      theWaiters: null,
    });

    let [filteredTableSection, setFilteredTableSection] = useState({
      list: null,
      searched: false,
    });

    const {
        getBranch,
        tableList,
        dataLoading,
        setTableList,
        bookingSlotForSearch,
        branchForSearch,
        deptTagForSearch,
        workPeriodForSearch,
        setWorkPeriodListForSearch,
        // table section
        tableSectionForSearch,
        //table
        tableForSearch,
        paymentTypeForSearch,
        getTable
    } = useContext(RestaurantContext);
    console.log('dataLoading', selectedTable)
    //order details
    const [orderDetails, setOrderDetails] = useState({
        branch: null,
        customer: null,
        debtor: null,
        table: null,
        waiter: null,
        dept_tag: null,
        payment_type: null,
        payment_amount: null,
        total_guest: 1,
        newCustomer: false,
        newCustomerInfo: {
        name: "",
        number: "",
        },
        token: null,
        serviceCharge: 0,
        discount: 0,
        discount_type: 'percentage',
    });

    console.log('orderDetails', orderDetails)
    
    const {
        //food
        foodForSearch,
        //food group
        foodGroupForSearch,
        getFoodGroup,
        //property group
        propertyGroupForSearch,
        //food stock
        setFoodItemsSlotId,
        foodItemStock,
    } = useContext(FoodContext);

    const {
        authUserInfo,
        //customer
        customerForSearch,
        setCustomerForSearch,
        //waiter
        waiterForSearch,
      } = useContext(UserContext);

    let branchPromise = new Promise((resolve, reject) => {
        let theBranch;
        if (authUserInfo.details) {
             theBranch = branchForSearch &&
              branchForSearch.find((branch) => {
                if(authUserInfo.details.user_type !== "staff"){
                    return
                } else {
                    return parseInt(authUserInfo.details.branch_id) === branch.id;
                }
              });
        }
        resolve(theBranch);
    });

    const handleSetSlot = async(slot) => {
      const response = await getGetTables(slot.id)
      setTableList(response)
      setTablesVisible(true)
      // const foodResponse = await getFoodItems(slot.id)
      setFoodItemsSlotId(slot.id)
      
    }

    const availableTablelist = async () => {
      console.log('reservation_id', selectedTableData.reservation_id)
      const response = await getAvalilableTables(selectedTableData.reservation_id, moment(new Date()).format('DD-MM-YYYY'), selectedTableData.slot_id)
        setAvailableTables(response)
    }

    useEffect(() => {
      availableTablelist();
    }, [selectedTableData?.reservation_id]);

  //show total price
  const totalPrice = (allOrderItems) => {
    let subTotal = 0;
    console.log('allOrderItems price');
    console.log(allOrderItems);
    allOrderItems.map((orderItem) => {
      console.log(orderItem);
      let price = 0;
      //check price * quantity (variation price / item price)
      if (parseInt(orderItem.item.has_variation) === 1) {
        price =
          parseFloat(orderItem.variation.food_with_variation_price) +
          parseFloat(orderItem.item.price); //kaj korsi
      } else {
        price = parseFloat(orderItem.item.price);
      }

      //calculate total price of properties
      if (orderItem.properties) {
        if (orderItem.properties.length > 0) {
          orderItem.properties.map((getEachItemPrice) => {
            let totalPropertyPrice =
              parseFloat(getEachItemPrice.item.extra_price) *
              getEachItemPrice.quantity;
            price = price + totalPropertyPrice;
          });
        }
      }
      subTotal = subTotal + price * orderItem.quantity;
    });
    setTheSubTotal(subTotal);

    let tempVat = 0;
    if (newSettings) {
      tempVat = (subTotal * parseFloat(newSettings.vat)) / 100;
      setTheVat(tempVat);
    }

    let tempCommission = 0;
    if (orderDetails && orderDetails.dept_tag) {
      tempCommission =
        (subTotal * parseFloat(orderDetails.dept_tag.commission)) / 100;
      setDeptCommission(tempCommission);
    }

    let totalPayable = 0;
    let localCurrency = JSON.parse(localStorage.getItem("currency"));

    let usdServiceCharge;
    let usdDiscount;
    if (orderDetails.discount_type === "flat") {
      usdServiceCharge =
        parseFloat(orderDetails.serviceCharge) / localCurrency.rate;
      usdDiscount = parseFloat(orderDetails.discount);
    } else {
      usdServiceCharge =
        subTotal * (parseFloat(orderDetails.serviceCharge) / 100);
      usdDiscount = subTotal * (parseFloat(orderDetails.discount) / 100);
    }

    if(selectedTableData?.discount_type == "percentage") {
      totalPayable =
      subTotal + tempVat + usdServiceCharge - usdDiscount - tempCommission - tableKaData;
      setTimeout(() => {
        setTotalPaybale(totalPayable - 100);
      }, 1000)
    } else {
      totalPayable =
      subTotal + tempVat + usdServiceCharge - usdDiscount - tempCommission - tableKaData;
      setTotalPaybale(totalPayable - parseInt(orderDetails.discount));
      console.log('selectedTableData', selectedTableData)
    }
    
      
    // setTotalPaybale(totalPayable);

    console.log('Advance', tableKaData)
    console.log('totalPayable', totalPayable)

    //calculate paid amount to set return amount
    handleCalculatePaid(orderDetails.payment_amount, orderDetails.payment_type);
  };

    // department tag
  const handleSetDeptTag = (dept_tag) => {
    setOrderDetails({
      ...orderDetails,
      dept_tag,
    });

    let allOrderItems = newOrder;
    if (allOrderItems) {
      let subTotal = 0;
      allOrderItems.map((orderItem) => {
        let price = 0;
        //check price * quantity (variation price / item price)
        if (parseInt(orderItem.has_variation) === 1) {
          price =
            parseFloat(orderItem.variation.food_with_variation_price) +
            parseFloat(orderItem.price); //kaj korsi
        } else {
          price = parseFloat(orderItem.price);
        }

        //calculate total price of properties
        if (orderItem.properties) {
          if (orderItem.properties.length > 0) {
            orderItem.properties.map((getEachItemPrice) => {
              let totalPropertyPrice =
                parseFloat(getEachItemPrice.extra_price) *
                getEachItemPrice.quantity;
              price = price + totalPropertyPrice;
            });
          }
        }
        subTotal = subTotal + price * orderItem.quantity;
      });
      setTheSubTotal(subTotal);

      let tempVat = 0;
      if (newSettings) {
        tempVat = (subTotal * parseFloat(newSettings.vat)) / 100;
        setTheVat(tempVat);
      }

      let tempCommission = 0;
      tempCommission = (subTotal * parseFloat(dept_tag.commission)) / 100;
      setDeptCommission(tempCommission);

      let totalPayable = 0;
      let localCurrency = JSON.parse(localStorage.getItem("currency"));

      let usdServiceCharge;
      let usdDiscount;
      if (orderDetails.discount_type === "flat") {
        usdServiceCharge =
          parseFloat(orderDetails.serviceCharge) / localCurrency.rate;
        usdDiscount = parseFloat(orderDetails.discount);
      } else {
        usdServiceCharge =
          subTotal * (parseFloat(orderDetails.serviceCharge) / 100);
        usdDiscount = subTotal * (parseFloat(orderDetails.discount) / 100);
      }

      totalPayable =
        subTotal + tempVat + usdServiceCharge - usdDiscount - tempCommission - tableKaData;
      setTotalPaybale(totalPayable);

      //calculate paid amount to set return amount
      handleCalculatePaid(
        orderDetails.payment_amount,
        orderDetails.payment_type
      );
    }
  };

  //handle order item quantity
  const handleOrderItemQty = (action) => {
    if (activeItemInOrder !== null) {
      if (newOrder) {
        let newOrderItemTemp = null; //to edit selected item
        let oldOrderItems = []; //array to push order items
        newOrder.map((newOrderItem, index) => {
          if (index === activeItemInOrder) {
            //update qty here
            if (action === "+") {
              
              // if manage is stock is enable
              if (showManageStock) {
                if (
                  handleGetStock(newOrderItem.id) >
                  handleAlreadyOrderedQty(newOrderItem.id)
                ) {
                  //increase qty
                  newOrderItemTemp = {
                    ...newOrderItem,
                    quantity: newOrderItem.quantity + 1,
                  };
                } else {
                  newOrderItemTemp = {
                    ...newOrderItem,
                    quantity: newOrderItem.quantity,
                  };
                  toast.error(`${_t(t("Reached Stock Limit"))}`, {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  });
                }
              } else {
                //increase qty
                newOrderItemTemp = {
                  ...newOrderItem,
                  quantity: newOrderItem.quantity + 1,
                };
                console.log('newOrderItemTemp', newOrderItem)
              }
            } else {
              //if qty !==1 decrease
              if (newOrderItem.quantity !== 1) {
                newOrderItemTemp = {
                  ...newOrderItem,
                  quantity: newOrderItem.quantity - 1,
                };
              } else {
                newOrderItemTemp = {
                  ...newOrderItem,
                  quantity: newOrderItem.quantity,
                };
              }
            }
            oldOrderItems.push(newOrderItemTemp);
          } else {
            // set other items as it was which are not selected to edit
            oldOrderItems.push(newOrderItem);
          }
        });

        //calculate subTotalPrice
        totalPrice(oldOrderItems);
        //set updated order list
        setNewOrder(oldOrderItems);
      }
    }
  };

  //remove item from order list
  const handleRemoveItemFromOrderList = async (removeIndex, removeItem) => {
    let oldOrderItems = []; //array to push order items
    console.log('removeItem------');
    console.log(removeItem.item.id);

    const url = BASE_URL + "/settings/delete-order-item/"+removeItem.item.id;

    try {
      const requestOptions = {
          method: 'PUT',
          headers: { Authorization: `Bearer ${getCookie()}` },
      };

      
      if(selectedTableData.is_print == 1) {
        
      let body = {
        'reason': '' 
      }
        await fetch(url, body, requestOptions)
      } else {
        await fetch(url, requestOptions)
      }
      
      newOrder.map((newOrderItem, index) => {
        if (index !== removeIndex) {
          //push item to orderlist
          oldOrderItems.push(newOrderItem);
        }
      });
  
      //delete variations here
      let tempSelectedVariations = selectedVariation.filter(
        (selectedVariationItem, variationIndex) => {
          return variationIndex !== removeIndex;
        }
      );
      //delete properties here
      let tempSelectedProperties = selectedProperties.filter(
        (selectedPropertyItem, propertyIndex) => {
          return propertyIndex !== removeIndex;
        }
      );
  
      //delete property group here
      let tempSelectedPropertyGroup = selectedPropertyGroup.filter(
        (selectedPropertyGroupItem, propertyGroupIndex) => {
          return propertyGroupIndex !== removeIndex;
        }
      );
  
      //set property group here
      setSelectedPropertyGroup(tempSelectedPropertyGroup);
  
      //set variations here
      setSelectedVariation(tempSelectedVariations);
  
      //set properties here
      setSelectedProperties(tempSelectedProperties);
  
      //set updated order list
      setNewOrder(oldOrderItems);
  
      //set active item in order list
      setActiveItemInOrder(null);

      setOrderDetails({
        ...orderDetails,
        orderItems: oldOrderItems
      });
  
      //calculate subTotalPrice
      totalPrice(oldOrderItems);
  
      //set order unique food groups
      let newSelectedGroup = [];
      // oldOrderItems.map((eachOrderGroup) => {
      //   let theGroup = foodGroupForSearch.find((grpFilter, grpFilterIndex) => {
      //     console.log('gggggggg');
      //     return grpFilter.id === parseInt(eachOrderGroup.food_group_id);
      //   });
  
      //   if (!newSelectedGroup.includes(parseInt(theGroup.id))) {
      //     newSelectedGroup.push(parseInt(theGroup.id));
      //   }
      // });
      // let theOrderFoodGroups = [];
      // newSelectedGroup.forEach((thisItem, thisItemIndex) => {
      //   let findThegroup = foodGroupForSearch.find(
      //     (filterThisGroup, filterThisGroupIndex) => {
      //       return filterThisGroup.id === thisItem;
      //     }
      //   );
      //   theOrderFoodGroups.push(findThegroup);
      // });
  
      // setOrderFoodGroups(theOrderFoodGroups);
  
      //sound
      if (getSystemSettings(generalSettings, "play_sound") === "1") {
        let beep = document.getElementById("myAudio");
        beep.play();
      }
    } catch {

    }
  };

  //show price of each item in order list
  const showPriceOfEachOrderItem = (itemIndex) => {
    if (newOrder) {
      let price = 0;
      let orderItem = newOrder[itemIndex];
      //check price * quantity (variation price / item price)
      if(orderItem.hasOwnProperty('item')){
        if (parseInt(orderItem.item.has_variation) === 1) {
          price =
            parseFloat(orderItem.variation.food_with_variation_price) +
            parseFloat(orderItem.item.price); //kaj korsi
        } else {
          price = parseFloat(orderItem.item.price);
        }
  
        //calculate total price of properties
        if (orderItem.properties) {
          if (orderItem.properties.length > 0) {
            orderItem.properties.map((getEachItemPrice) => {
              let totalPropertyPrice =
                parseFloat(getEachItemPrice.item.extra_price) *
                getEachItemPrice.quantity;
              price = price + totalPropertyPrice;
            });
          }
        }
      } else {
        if (parseInt(orderItem.has_variation) === 1) {
          price =
            parseFloat(orderItem.variation.food_with_variation_price) +
            parseFloat(orderItem.price); //kaj korsi
        } else {
          price = parseFloat(orderItem.price);
        }

        //calculate total price of properties
        if (orderItem.properties) {
          if (orderItem.properties.length > 0) {
            orderItem.properties.map((getEachItemPrice) => {
              let totalPropertyPrice =
                parseFloat(getEachItemPrice.extra_price) *
                getEachItemPrice.quantity;
              price = price + totalPropertyPrice;
            });
          }
        }
    }

      let formattedPrice = formatPrice(price * orderItem.quantity);
      return formattedPrice;
    }
  };

  //show price of each item in print
  const showPriceOfEachOrderItemPrint = (itemIndex) => {
    if (newOrder) {
      let price = 0;
      let orderItem = newOrder[itemIndex];
      //check price * quantity (variation price / item price)
      if (parseInt(orderItem.item.has_variation) === 1) {
        price =
          parseFloat(orderItem.item.variation.food_with_variation_price) +
          parseFloat(orderItem.item.price); //kaj korsi
      } else {
        price = parseFloat(orderItem.item.price);
      }

      let formattedPrice = formatPrice(price * orderItem.quantity);
      return formattedPrice;
    }
  };

  // handleStripeCardPayment
  const handleStripeCardPayment = async (e) => {
    e.preventDefault();
    if (newOrder && newOrder.length > 0) {
      if (orderDetails && orderDetails.dept_tag !== null) {
        checkStripePayments();
      } else {
        toast.error(`${_t(t("Please select a department tag"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      toast.error(`${_t(t("Please add items in order list"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //payment type
  const handleSetpaymentType = (payment_type) => {
    setOrderDetails({
      ...orderDetails,
      payment_type,
    });

    //calculate paid amount to set return amount
    handleCalculatePaid(orderDetails.payment_amount, payment_type);
  };

  //payment type pos 2
  const handleSetpaymentTypeSingle = (payment_type) => {
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    let theUsdPaid = paidMoney / localCurrency.rate;
    if (theUsdPaid < totalPayable) {
      setReturnMoneyUsd(0);
      toast.error(
        `${_t(
          t("Please enter paid amount atleast equal to the total bill amount")
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    } else {
      setOrderDetails({
        ...orderDetails,
        payment_type: [payment_type],
      });
      let theReturnMoney = theUsdPaid - totalPayable;
      setReturnMoneyUsd(theReturnMoney);
    }
  };

  //payment type amount
  const handlePaymentTypeAmount = (e) => {
    let tempPaymentAmount = {
      ...orderDetails.payment_amount,
      [e.target.name]: e.target.value,
    };

    setOrderDetails({
      ...orderDetails,
      payment_amount: tempPaymentAmount,
    });

    //calculate paid amount to set return amount
    handleCalculatePaid(tempPaymentAmount, orderDetails.payment_type);
  };

  //settle button
  const handleSettleOrderButton = (e) => {
    if (newOrder && newOrder.length > 0) {
      if (orderDetails && orderDetails.dept_tag !== null) {
        setShowSettle(true);
      } else {
        toast.error(`${_t(t("Please select a department tag"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      toast.error(`${_t(t("Please add items in order first"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  // getStripVerifyToken
  const getStripVerifyToken = () => {
    const url = BASE_URL + "/settings/get-stripe-card-reader";

    let formData = {
      totalPayable: totalPayable,
    };

    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data == "succeeded") {
          axiosRequestForSettle();
        } else {
          console.log(res); //will be replaced after testing
          toast.error(`${_t(t("Something went wrong!"))}`, {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const checkStripePayments = () => {
    if (newOrder && newOrder.length > 0) {
      if (paidMoney < totalPayable) {
        toast.error(
          `${_t(
            t("Please enter paid amount atleast equal to the total bill amount")
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      } else {
        getStripVerifyToken();
      }
    } else {
      toast.error(`${_t(t("Please add items in order list"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //for pos manager
  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
        handleOnlyPrint2();
      }
    },
  });

const handleStatusChange = async (data) => {
  console.log('handleStatusChange', selectedTableData.customer_id)
  const response = await printBillStatusChange(selectedTableData.id)
  if(response != null) {
    setPrintStatus('printed')
  }
}

  //for kithcen
  const handleOnlyPrint2 = useReactToPrint({
    content: () => component2Ref.current,
  });

  //stock
  const handleGetStock = (id) => {
    if (orderDetails.branch === null) {
      return 0;
    }
    let stock = foodItemStock.find((item) => {
      return (
        parseInt(item.food_id) === parseInt(id) &&
        parseInt(item.branch_id) === orderDetails.branch.id
      );
    });
    if (stock === undefined || stock.qty < 0) {
      return 0;
    }
    return stock.qty;
  };

   //calculate paid amount
   const handleCalculatePaid = (paymentAmount, paymentType) => {
    let paidAmount = 0;
    if (paymentAmount !== null && paymentType !== null) {
      let thePaymentArray = [];
      if (paymentAmount) {
        thePaymentArray = Object.entries(paymentAmount);
      }
      thePaymentArray.map((eachPaymentItem) => {
        let thePaymentType = paymentType.find((paymentTypeItem) => {
          return paymentTypeItem.id === parseInt(eachPaymentItem[0]);
        });
        if (eachPaymentItem[1] !== "") {
          if (
            thePaymentType &&
            thePaymentType.id === parseInt(eachPaymentItem[0])
          ) {
            paidAmount = paidAmount + parseFloat(eachPaymentItem[1]);
          }
        }
      });
      let localCurrency = JSON.parse(localStorage.getItem("currency"));
      paidAmount = paidAmount / localCurrency.rate;
      let theReturnMoney = paidAmount - totalPayable;
      setReturnMoneyUsd(theReturnMoney);
    } else {
      setReturnMoneyUsd(0);
    }
    setPaidMoney(paidAmount);
  };

  //get already ordered qty
  const handleAlreadyOrderedQty = (id) => {
    let temp = 0;
    if (newOrder) {
      newOrder.map((item) => {
        if (parseInt(item.id) === id) {
          temp += parseInt(item.quantity);
        }
      });
    }
    return temp;
  };


  const resTablePOS = async () => {
    const url = BASE_URL + "/settings/get-submitted-orders-bytable?orderid="+selectedtableOrderId;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      console.log('tableRes', res.data[0].data[0]);
      setTableKaData(res.data[0]?.data[0]?.advance)
      let tableData = res.data[0].data[0];
      console.log('tableData', res.data[0]?.data[0]?.advance);
      setOnTableLoading(false);
      setSelectedTableData(tableData);
      setSelectedOrderId(tableData.id)
      let orderList = tableData?.orderedItems;
      console.log(orderList);
      let newOrderList = [];
      for(let i = 0; i < orderList.length; i++){
        let data = {
          "item": {
            "id": orderList[i].id,
            "food_group_id": orderList[i].food_group_id,
            "food_group": orderList[i].food_group,
            "name": orderList[i].food_item,
            "item_id": orderList[i].food_item_id,
            "cancel_status": orderList[i].cancel_status,
            "slug": orderList[i].slug,
            "is_special": orderList[i].is_special,
            "image": orderList[i].image,
            "has_variation": orderList[i].variation != null ? '1': '0',
            "price": orderList[i].price,
            "has_property": orderList[i].properties != null ? '1' : '0'
          },
        "variation":orderList[i].variation,
        "quantity":orderList[i].quantity,
        
        }
        newOrderList.push(data);
        totalPrice(newOrderList);
      }
      console.log(tableData.service_charge);
      setOrderDetails({
        ...orderDetails,
        total_guest: tableData.total_guest,
        bill_no: tableData.bill_no,
        serviceCharge: parseFloat(
          tableData.service_charge
        ),
        discount: parseFloat(
          tableData.discount
        ),
        discount_type: tableData.discount_type,
        branch: tableData.theBranch,
        customer: {
          "id": tableData.customer_id,
          "name": tableData.customer_name,
          "email": "customer@gmail.com",
          "type": null,
          "phn_no": "1234567890",
          "address": "erwrwe",
          "gst_name": tableData.gst_name,
          "gst_number": tableData.gst_number,
          "branch_id": tableData.branch_id,
          "branch_name": tableData.branch_name,
          "slug": tableData.customer_id+'-'+tableData.customer_name
        },
        debtor: null,
        table: {
          "id": tableData.table_id,
          "name": tableData.table_name,
          "slug": "6No-1663089321-table-04",
          "capacity": "08",
          "assigned_to_section":"no",
          "branch_id": tableData.branch_id,
          "status":"open",
          "reservation_infos":null,
          "waiters":null,
          "branch_name":tableData.branch_name
        },
        waiter: {
          "id": tableData.waiter_id,
          "name": tableData.waiter_name,
          "phn_no": "1231231230",
          "branch_id": tableData.branch_id,
          "branch_name": tableData.branch_name,
          "slug":"NsO-1663090243-captan2",
          "image": null
        },
        dept_tag: null,
        payment_type: null,
        payment_amount: null,
        total_guest: tableData.total_guest,
        orderItems: newOrderList
      });
      // orderDetails.serviceCharge = tableData.service_charge;
      // orderDetails.discount = tableData.discount;
      setNewOrder(newOrderList);
      
    } catch { 
      setOnTableLoading(false);
      setSelectedTable(null);
      setSelectedOrderId(null);
      setSelectedTableData({});
    }
  }

  //add new item to order list
  const handleOrderItem = async (tempFoodItem) => {
    // if manage is stock is enable
    // console.log('tempFoodItem', tempFoodItem)
    if (showManageStock) {
      
      if (
        handleGetStock(tempFoodItem.id) >
        handleAlreadyOrderedQty(tempFoodItem.id)
      ) {
        let oldOrderItems = [...newOrder];
        let temp = null;
        let newOrderItem = newOrder.map((item) => item.item.item_id);
        console.log('thbrthtjujyujuyjyuj', newOrderItem, tempFoodItem.food_group_id)
        if (newOrder) {

          temp = {
            item: tempFoodItem,
            variation:
              parseInt(tempFoodItem.has_variation) === 1
                ? tempFoodItem.variations[0]
                : null,
            quantity: 1,
          };
          oldOrderItems.push(newOrderItem);
        } else {
          //if no item in newOrder List
          setOrderDetails({
            //set token here on first order item add,
            ...orderDetails,
            token: {
              time: new Date().getTime(),
              id: newSettings.workPeriod.token,
            },
          });
          newOrderItem = {
            //add new order item
            item: tempFoodItem,
            variation:
              parseInt(tempFoodItem.has_variation) === 1
                ? tempFoodItem.variations[0]
                : null,
            quantity: 1,
          };
          oldOrderItems.push(newOrderItem);
        }

        //set new order list with new array of all order items
        // setNewOrder(oldOrderItems);

        //set order list active item index to add class "actve"
        setActiveItemInOrder(oldOrderItems.length - 1);

        //calculate subTotalPrice
        totalPrice(oldOrderItems);

        //set order unique food groups
        let newSelectedGroup = [];
        // oldOrderItems.map((eachOrderGroup) => {
        //   let theGroup = foodGroupForSearch.find(
        //     (grpFilter, grpFilterIndex) => {
        //       return (
        //         grpFilter.id === parseInt(eachOrderGroup.food_group_id)
        //       );
        //     }
        //   );

        //   if (!newSelectedGroup.includes(parseInt(theGroup.id))) {
        //     newSelectedGroup.push(parseInt(theGroup.id));
        //   }
        // });
        // let theOrderFoodGroups = [];
        // newSelectedGroup.forEach((thisItem, thisItemIndex) => {
        //   let findThegroup = foodGroupForSearch.find(
        //     (filterThisGroup, filterThisGroupIndex) => {
        //       return filterThisGroup.id === thisItem;
        //     }
        //   );
        //   theOrderFoodGroups.push(findThegroup);
        // });

        // setOrderFoodGroups(theOrderFoodGroups);

        //sound
        if (getSystemSettings(generalSettings, "play_sound") === "1") {
          let beep = document.getElementById("myAudio");
          beep.play();
        }
      } else {
        toast.error(`${_t(t("Stock Out"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      
      let oldOrderItems = [...newOrder];
        let temp = null;
        let newOrderItem = newOrder.map((item) => item.item.item_id);
        function checkAdult(age) {
          return age == tempFoodItem.id;
        }
        function addQTY(age) {
          if(age.item.item_id == tempFoodItem.id) {
            return tempFoodItem
          }           
        }
        console.log('thbrthtjujyujuyjyuj', newOrderItem, oldOrderItems, tempFoodItem)
      if (newOrder && newOrderItem.filter(checkAdult).length == 0) {     
        //add new order item
        temp = {
          item: tempFoodItem,
          variation: null,
          quantity: 1,
        };
        
        oldOrderItems.push(temp);
        setNewOrder(oldOrderItems);
      } else {
        //if no item in newOrder List
        // setOrderDetails({
        //   //set token here on first order item add,
        //   ...orderDetails,
        //   token: {
        //     time: new Date().getTime(),
        //     id: newSettings.workPeriod.token,
        //   },
        // });
        // // newOrderItem = {
        // //   //add new order item
        // //   item: tempFoodItem,
        // //   variation:
        // //     parseInt(tempFoodItem.has_variation) === 1
        // //       ? tempFoodItem.variations[0]
        // //       : null,
        // //   quantity: 1,
        // // };

        // // oldOrderItems.push(newOrderItem);
        // handleOrderItemQty("+");
        // const itemsData = newOrder.map((item) => item.item.item_id);
        if(newOrder.length == activeItemInOrder) {
          handleOrderItemQty("+");
          resTablePOS();
        }
      }

      //set new order list with new array of all order items
      setNewOrder(oldOrderItems);
      newOrderItem = newOrder.map((item) => item.item.item_id);

      //set order list active item index to add class "actve"
      setActiveItemInOrder(oldOrderItems.length - 1);


      //calculate subTotalPrice
      totalPrice(oldOrderItems);

      const orderPromise = new Promise((resolve, reject) => {
        resolve(oldOrderItems);
      });    

      //set order unique food groups
      let newSelectedGroup = [];
      // oldOrderItems.map((eachOrderGroup) => {
      //   console.log('fffffffff------')
      //   console.log(eachOrderGroup)
      //   let theGroup = foodGroupForSearch.find((grpFilter, grpFilterIndex) => {
      //     console.log(grpFilter)
      //     return grpFilter.id === parseInt(eachOrderGroup.food_group_id);
      //   });

      //   if (!newSelectedGroup.includes(parseInt(theGroup.id))) {
      //     newSelectedGroup.push(parseInt(theGroup.id));
      //   }
      // });
      // let theOrderFoodGroups = [];
      // newSelectedGroup.forEach((thisItem, thisItemIndex) => {
      //   let findThegroup = foodGroupForSearch.find(
      //     (filterThisGroup, filterThisGroupIndex) => {
      //       return filterThisGroup.id === thisItem;
      //     }
      //   );
      //   theOrderFoodGroups.push(findThegroup);
      // });

      // setOrderFoodGroups(theOrderFoodGroups);

      //sound
      if (getSystemSettings(generalSettings, "play_sound") === "1") {
        let beep = document.getElementById("myAudio");
        beep.play();
      }
    }
  };

  //handle settle order
  const handleSettleOrder = (e) => {
    e.preventDefault();
    console.log('on settle order');
    console.log('orderDetails', newOrder);
    if (newOrder && newOrder.length > 0) {
      if (paidMoney < totalPayable) {
        toast.error(
          `${_t(
            t("Please enter paid amount atleast equal to the total bill amount")
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      } else {
        axiosRequestForSettle(selectedTableData.id);
      }
    } else {
      toast.error(`${_t(t("Please add items in order list"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  const cancelTheOrder = async () => {
    let note = document.getElementById('cancelReason')?.value;
    let data = {
      'id': selectedTableData.id,
      'reason': note,
      'vat': theVat,
      'order_bill': totalPayable,
      'total_payable': totalPayable,
    }
    const response = await cancelSbumittedOrder(data)
    if(response != null) {
      setSelectedTable(null);
      setTableList({});
      setPrintStatus('none');
      handleSetSlot(slotId);
      handleOnlyPrint();
      handleStatusChange();
      document.getElementById('cancelReason').value = '';
      toast.success(`${_t(t("Order Canceled Successfully"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }

  //axios request for settlement
  const axiosRequestForSettle = (o_id) => {
    let url = BASE_URL + "/settings/settle-order";
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    
    let formData = {
      branch: orderDetails.branch,
      customer: orderDetails.customer,
      debtor: orderDetails.debtor,
      table: orderDetails.table,
      waiter: orderDetails.waiter,
      dept_tag: orderDetails.dept_tag,
      payment_type: orderDetails.payment_type,
      payment_amount: orderDetails.payment_amount,
      newCustomer: orderDetails.newCustomer ? 1 : 0,
      newCustomerInfo: orderDetails.newCustomerInfo,
      token: orderDetails.token,
      total_guest: orderDetails.total_guest,
      orderItems: newOrder,
      serviceCharge: orderDetails.serviceCharge,
      discount: orderDetails.discount,
      discount_type: orderDetails.discount_type,
      subTotal: totalPayable,
      totalPayable: totalPayable,
      paidMoney: totalPayable,
      theVat: theVat,
      dept_commission: deptCommission,
      localCurrency: localCurrency,
      workPeriod: newSettings.workPeriod,
      order_id: o_id
    };
    console.log('settle formData');
    console.log(JSON.stringify(formData));
    setLoading(true);
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log('settle response');
        console.log(res.data);
        toast.success(`${_t(t("Order Settled Successfully"))}`, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setLoading(false);
        // let tempTables =
        //   tableForSearch !== null &&
        //   tableForSearch.filter((eachTable) => {
        //     return parseInt(eachTable.branch_id) === branch.id;
        //   });
        setTimeout(
          () => {
            setSelectedTable(null);
            getTable();
            // setTableList({
            //   ...tableList,
            //   data : tableForSearch
            // });
          },
          1000
        );
        // if (res.data !== "ended") {
        //   if (res.data !== "paymentIssue") {
        //     getFoodGroup();
        //     handlePrint();
        //     setCustomerForSearch(res.data[0][1]);
        //     setWorkPeriodListForSearch(res.data[1][1]);
        //     let tempCustomers =
        //       res.data[0][1] !== null &&
        //       res.data[0][1].filter((eachCustomer) => {
        //         return (
        //           parseInt(eachCustomer.branch_id) === orderDetails.branch.id
        //         );
        //       });
        //     setOrderDetailusers({
        //       ...orderDetailUsers,
        //       theCustomers: tempCustomers,
        //     });
        //     //set work period
        //     let theWorkPeriod = null;
        //     if (
        //       orderDetails.branch !== undefined &&
        //       orderDetails.branch !== null
        //     ) {
        //       theWorkPeriod =
        //         res.data[1][1] &&
        //         res.data[1][1].filter((tempWorkPeriod) => {
        //           return (
        //             orderDetails.branch.id ===
        //             parseInt(tempWorkPeriod.branch_id)
        //           );
        //         });

        //       //filter it with ended_at === null;
        //       theWorkPeriod =
        //         theWorkPeriod &&
        //         theWorkPeriod.find((endAtNullItem) => {
        //           return endAtNullItem.ended_at === null;
        //         });
        //     }
        //     //set work period here
        //     setNewSettings({
        //       ...newSettings,
        //       vat:
        //         generalSettings &&
        //         getSystemSettings(generalSettings, "type_vat"),
        //       workPeriod: theWorkPeriod !== undefined ? theWorkPeriod : null,
        //     });
        //   } else {
        //     setLoading(false);
        //     toast.error(
        //       `${_t(
        //         t(
        //           "Please enter paid amount atleast equal to the total bill amount"
        //         )
        //       )}`,
        //       {
        //         position: "bottom-center",
        //         closeButton: false,
        //         autoClose: 10000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         className: "text-center toast-notification",
        //       }
        //     );
        //   }
        // } else {
        // setLoading(false);
        // toast.error(`${_t(t("Please restart the work period"))}`, {
        //   position: "bottom-center",
        //   closeButton: false,
        //   autoClose: 10000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   className: "text-center toast-notification",
        // });
        // }
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //axios request for submit
  const axiosRequest = () => {
    let url = BASE_URL + "/settings/new-order";
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    if(orderDetails.table != null){
      let formData = {
        branch: orderDetails.branch,
        customer: orderDetails.customer,
        table: orderDetails.table,
        waiter: orderDetails.waiter,
        dept_tag: orderDetails.dept_tag,
        payment_type: orderDetails.payment_type,
        payment_amount: orderDetails.payment_amount,
        newCustomer: orderDetails.newCustomer ? 1 : 0,
        newCustomerInfo: orderDetails.newCustomerInfo,
        token: orderDetails.token,
        total_guest: orderDetails.total_guest,
        orderItems: newOrder,
        serviceCharge: orderDetails.serviceCharge,
        discount: orderDetails.discount,
        subTotal: theSubTotal,
        totalPayable: totalPayable,
        paidMoney: paidMoney,
        theVat: theVat,
        dept_commission: deptCommission,
        localCurrency: localCurrency,
        workPeriod: newSettings.workPeriod,
      };
      setLoading(true);
      axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data !== "ended") {
            getFoodGroup();
            handlePrint();
            setCustomerForSearch(res.data[0][1]);
            setWorkPeriodListForSearch(res.data[1][1]);
            let tempCustomers =
              res.data[0][1] !== null &&
              res.data[0][1].filter((eachCustomer) => {
                return (
                  parseInt(eachCustomer.branch_id) === orderDetails.branch.id
                );
              });
            setOrderDetailusers({
              ...orderDetailUsers,
              theCustomers: tempCustomers,
            });
  
            //set work period
            let theWorkPeriod = null;
            if (
              orderDetails.branch !== undefined &&
              orderDetails.branch !== null
            ) {
              theWorkPeriod =
                res.data[1][1] &&
                res.data[1][1].filter((tempWorkPeriod) => {
                  return (
                    orderDetails.branch.id === parseInt(tempWorkPeriod.branch_id)
                  );
                });
  
              //filter it with ended_at === null;
              theWorkPeriod =
                theWorkPeriod &&
                theWorkPeriod.find((endAtNullItem) => {
                  return endAtNullItem.ended_at === null;
                });
            }
            //set work period here
            setNewSettings({
              ...newSettings,
              vat:
                generalSettings && getSystemSettings(generalSettings, "type_vat"),
              workPeriod: theWorkPeriod !== undefined ? theWorkPeriod : null,
            });
  
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(`${_t(t("Please restart the work period"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(`${_t(t("Please choose table to place order"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //send server req
  const handleSubmitOrder = (e) => {
    e.preventDefault();
    if (newOrder && newOrder.length > 0) {
      if (paidMoney !== 0) {
        toast.error(
          `${_t(
            t("Settle order to add payments, or remove payment's amount")
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      } else {
        axiosRequest();
      }
    } else {
      toast.error(`${_t(t("Please add items in order list"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //print bills
  const componentRef = useRef();
  const component2Ref = useRef();

  //for pos manager
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
        handlePrint2();
      } else {
        handleOrderSubmitSuccessful();
      }
    },
  });

  //for kithcen
  const handlePrint2 = useReactToPrint({
    content: () => component2Ref.current,
    onAfterPrint: () => {
      handleOrderSubmitSuccessful();
    },
  });

  //call after successful order submit and settle
  const handleOrderSubmitSuccessful = () => {
    setNewOrder(null);
    setActiveItemInOrder(null);
    setSelectedVariation([]);
    setSelectedPropertyGroup([]);
    setSelectedProperties([]);
    setTheSubTotal(0);
    setTheVat(0);
    setTotalPaybale(0);
    setReturnMoneyUsd(0);
    setPaidMoney(0);
    setOrderDetails({
      branch: orderDetails.branch,
      customer: null,
      table: null,
      waiter: null,
      dept_tag: null,
      payment_type: null,
      payment_amount: null,
      total_guest: 1,
      newCustomer: false,
      newCustomerInfo: {
        name: "",
        number: "",
      },
      token: null,
      serviceCharge: 0,
      discount: 0,
    });

    toast.success(`${_t(t("Order has been taken"))}`, {
      position: "bottom-center",
      closeButton: false,
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });

    setShowSettle(false);

    setLoading(false);
    //sound
    if (getSystemSettings(generalSettings, "play_sound") === "1") {
      let beep = document.getElementById("myAudio");
      beep.play();
    }
  };

  const getShowReaderButton = () => {
    const url = BASE_URL + "/settings/show-stripe-card-reader-button";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setshowCardReaderBUtton(res.data);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const addgst = async () => {
    let gstName = document.getElementById('gstName')?.value;
    let gstNumber = document.getElementById('gstNumber')?.value;
    let body = {
      'order_id': selectedOrderId,
      'gst_name': gstName,
      'gst_number': gstNumber,
    }
    
    const response = await uppdateGST(body)
    if(response != null) {
      toast.success(`${_t(t("GST updated Successfully"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }

  const handleSetBranch = (branch) => {
    setLoading(true);
    let searchedList = tableSectionForSearch.filter((item) => {
      return item.branch_id == branch.id;
    });
    setFilteredTableSection({
      ...filteredTableSection,
      list: searchedList,
      searched: true,
    });
    let tempCustomers =
      customerForSearch !== null &&
      customerForSearch.filter((eachCustomer) => {
        return parseInt(eachCustomer.branch_id) === branch.id;
      });
    let tempTables =
      tableForSearch !== null &&
      tableForSearch.filter((eachTable) => {
        return parseInt(eachTable.branch_id) === branch.id;
      });
    let tempWaiters =
      waiterForSearch !== null &&
      waiterForSearch.filter((eachWaiter) => {
        return parseInt(eachWaiter.branch_id) === branch.id;
      });
    setOrderDetailusers({
      theCustomers: tempCustomers,
      theTables: tempTables,
      theWaiters: tempWaiters,
    });
    console.log('tempTables');
    console.log(tempTables);
    console.log(tableList);
    setTableList({
      ...tableList,
      data : tempTables
    });
    setNewOrder(null);
    setActiveItemInOrder(null);
    setSelectedVariation([]);
    setSelectedPropertyGroup([]);
    setSelectedProperties([]);
    setTheSubTotal(0);
    setTheVat(0);
    setTotalPaybale(0);
    setReturnMoneyUsd(0);
    setPaidMoney(0);
    setOrderDetails({
      branch: branch,
      customer: null,
      debtor: null,
      table: null,
      waiter: null,
      dept_tag: null,
      payment_type: null,
      payment_amount: null,
      total_guest: 1,
      newCustomer: false,
      newCustomerInfo: {
        name: "",
        number: "",
      },
      token: null,
      serviceCharge: 0,
      discount: 0,
    });
    // set work period according to branch for admin on branch change
    let theWorkPeriod = null;
    theWorkPeriod =
      workPeriodForSearch &&
      workPeriodForSearch.filter((tempWorkPeriod) => {
        return branch.id === parseInt(tempWorkPeriod.branch_id);
      });

    theWorkPeriod = theWorkPeriod.find((endAtNullItem) => {
      return endAtNullItem.ended_at === null;
    });
    setNewSettings({
      ...newSettings,
      workPeriod: theWorkPeriod !== undefined ? theWorkPeriod : null,
      vat: newSettings.vat,
    });

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  useEffect(() => {
    console.log('tableList ------');
    console.log('tableList', tableList);
    console.log(tableForSearch);
    // branchPromise.then((val) => {
    //     console.log(val);
    // }).catch((e) => {
    //     console.log(e)
    // })       
  }, [])

    //useEffect- to get data on render
  useEffect(() => {
    if (foodGroupForSearch) {
      let tempItems =
        foodForSearch &&
        foodForSearch.filter((tempItem) => {
          return parseInt(tempItem.food_group_id) === foodGroupForSearch[0].id;
        });
      if (tempItems && tempItems.length > 0) {
        // initial group & item being active here, variations, properties
        setFoodItem({
          foodGroup: foodGroupForSearch[0],
          items: tempItems,
          selectedItem: tempItems[0],
          variations:
            parseInt(tempItems[0].has_variation) === 1
              ? tempItems[0].variations
              : null,
          properties:
            parseInt(tempItems[0].has_property) === 1
              ? tempItems[0].properties
              : null,
        });
      }
    }

    if (authUserInfo.details) {
      let theBranch =
        branchForSearch &&
        branchForSearch.find((branch) => {
          // console.log('searchedList', parseInt(authUserInfo.details.branch_id))
          return parseInt(authUserInfo.details.branch_id) === branch.id;          
        });
      setOrderDetails({
        ...orderDetails,
        branch: theBranch !== undefined ? theBranch : null,
      });

      let searchedList = tableSectionForSearch?.filter((item) => {
        return item.branch_id == authUserInfo.details.branch_id;
        
      });
      setFilteredTableSection({
        ...filteredTableSection,
        list: searchedList,
        searched: true,
      });

      //set work period
      let theWorkPeriod = null;
      if (theBranch !== undefined && theBranch !== null) {
        theWorkPeriod =
          workPeriodForSearch &&
          workPeriodForSearch.filter((tempWorkPeriod) => {
            return theBranch.id === parseInt(tempWorkPeriod.branch_id);
          });

        //filter it with ended_at === null;
        theWorkPeriod =
          theWorkPeriod &&
          theWorkPeriod.find((endAtNullItem) => {
            return endAtNullItem.ended_at === null;
          });
      }
      //set work period here
      setNewSettings({
        ...newSettings,
        vat: generalSettings && getSystemSettings(generalSettings, "type_vat"),
        workPeriod: theWorkPeriod !== undefined ? theWorkPeriod : null,
      });
    } else {
      // user type admin
      setNewSettings({
        ...newSettings,
        vat: generalSettings && getSystemSettings(generalSettings, "type_vat"),
      });
    }
    setOrderDetailusers({
      theCustomers: customerForSearch ? customerForSearch : null,
      theTables: tableForSearch ? tableForSearch : null,
      theWaiters: waiterForSearch ? waiterForSearch : null,
    });

    // show button
    getShowReaderButton();

    //check online orders
    // const interval = setInterval(() => {
    //   const pendingUrl = BASE_URL + "/website/pending-order";
    //   return axios
    //     .get(pendingUrl, {
    //       headers: { Authorization: `Bearer ${getCookie()}` },
    //     })
    //     .then((res) => {
    //       if (res.data > 0) {
    //         setOnlineCounter(res.data);
    //         let order = document.getElementById("myAudioOrder");
    //         order.play();
    //       }
    //     })
    //     .catch((err) => {});
    // }, 30000);
  }, [authUserInfo, generalSettings, foodForSearch, foodGroupForSearch]);

  const onTableEmpty = () => {
    console.log('table empty');
    toast.error(
      `${_t(
        t("Table is empty")
      )}`,
      {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      }
    );
  }

  console.log('orderDetails', orderDetails)

  const reset = () => {
    document.getElementById('cancelReason').value = '';
    document.getElementById('qtyLessReason').value = '';
  }

  const handleShiftTable = async (data) => {
    let body = {
      'order_id': data.id, 
      'table_id': selectedOrderId
    }
    const response = await tableShift(body)
    if(response != null) {
      setSelectedTable(null);
      toast.success(`${_t(t("table Shifted Successfully"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }

  function sumArray(array){
    let sum = 0
    for (let i = 0; i < 
    array?.length; i++) {
    sum += array[i]
    }
    return sum
  }

  // function totalCalc(val) {
  //   let sum = 0;
  //   let dis = 0;
  //   // sum = val + theVat;
  //   dis = val/
  // }

  const today = new Date();
  
    return (
        <>
        <Helmet>
            <title>{_t(t("Reservation Table"))}</title>
        </Helmet>


{/* Print bill */}
    <div className="d-none">
        <div ref={componentRef}>
          <div className="fk-print pt-4">
            <div className="container-bill">
              <h2 className="d-flex justify-content-center font-weight-bold">SMOKE HUB BARBEQUE - <span className="text-uppercase">{orderDetails.branch?.name}</span></h2>
              <div className="font-size-14">
                <p className="container-bill-address d-flex xsm-text justify-content-center text-capitalize text-center">{orderDetails.branch?.address}</p>
                <p className="d-flex xsm-text justify-content-center">{orderDetails.branch?.phn_no}</p>
                <p className="d-flex xsm-text justify-content-center">FSSAI NO: {orderDetails.branch?.fssai_no}</p>
                <p className="d-flex xsm-text justify-content-center">GPS FOOD FACTORY</p>
                <p className="d-flex xsm-text justify-content-center">GST NO: {orderDetails.branch?.gst_no}</p>
                <p className="d-flex justify-content-center font-weight-bold">Dine In</p>
                <p className="d-flex xsm-text justify-content-center pb-2">---------------BILL MEMO---------------</p>
                <p className="d-flex xsm-text justify-content-center">Online Order Id: <span className="font-weight-bold">{' '+selectedOrderId}</span></p>
                <p className="d-flex xsm-text justify-content-center">Bill No: {orderDetails.bill_no}</p>
                <p className="d-flex xsm-text justify-content-center">Table: {orderDetails.table?.name}</p>
                <p className="d-flex xsm-text justify-content-center">By: {orderDetails.branch?.mail_id}</p>
                <p className="d-flex xsm-text justify-content-center">Token Number: {orderDetails.token}</p>
                <p className="d-flex xsm-text justify-content-center">Date: {moment(today).format('DD-MMMM-YYYY')}</p>
                <p className="d-flex xsm-text justify-content-center">Time: {moment(today).format('hh:mm a')}</p>
                <p className="d-flex xsm-text justify-content-center">Order Date: </p>
                <p className="d-flex xsm-text justify-content-center">Captain: {orderDetails.branch?.mail_id}</p>
              </div>
              <div className="container d-flex justify-content-center pt-2">
                <table className="table mb-0 table-borderless akash-table-for-print-padding bill-width">
                  <thead>
                    <tr className="border-top border-bottom">
                      <th
                        scope="col"
                        className="fk-print-text xsm-text text-capitalize border-0"
                      >
                      {_t(t("item"))}
                      </th>
                      <th
                        scope="col"
                        className="fk-print-text xsm-text text-capitalize text-right border-0"
                      >
                      {_t(t("rate"))}
                      </th>
                      <th
                        scope="col"
                        className="fk-print-text xsm-text text-capitalize border-0"
                      >
                        {_t(t("qty"))}
                      </th>
                      <th
                        scope="col"
                        className="fk-print-text xsm-text text-capitalize text-right border-0"
                      >
                        {_t(t("amt"))}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-bottom">
                    {orderDetails.orderItems?.map((item, i) => {
                      return (
                          <tr key={i}>
                            <td className="fk-print-text xsm-text text-capitalize text-left border-0">
                              {item.item?.name}
                            </td>
                            <td className="fk-print-text xsm-text text-capitalize text-right border-0">
                              {item.item?.price}
                            </td>
                            <td className="fk-print-text xsm-text text-capitalize text-left border-0">
                              {item.quantity}
                            </td>
                            <td className="fk-print-text xsm-text text-capitalize text-right border-0">
                              {item.item?.price * item.quantity}
                            </td>
                          </tr>
                      )})}                                                   
                  </tbody>
                  <tbody className="border-bottom">
                    <tr>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0">Total</td>
                      <td colSpan={1} className="fk-print-text xsm-text text-capitalize text-left border-0">{sumArray(orderDetails.orderItems?.map((item, i) => parseInt(item.quantity)))}</td>
                      <td colSpan={1} className="fk-print-text xsm-text text-capitalize text-right border-0">{theSubTotal}</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="fk-print-text xsm-text text-capitalize text-left border-0">Discount</td>
                      <td colSpan={1} className="fk-print-text xsm-text text-capitalize text-right border-0"><span>{orderDetails.discount_type == 'percentage' ? '%' : '₹'}</span>{orderDetails.discount ?? 0}</td>
                    </tr>
                  </tbody>
                  <tbody className="border-bottom">
                    <tr>
                      <td colSpan={3} className="fk-print-text xsm-text text-capitalize text-left border-0">cgst+sgst(2.5+2.5)%:</td>
                      <td colSpan={1} className="fk-print-text xsm-text text-capitalize text-right border-0">{theVat}</td>
                    </tr>
                  </tbody>
                  <tbody className="border-bottom">
                    <tr>
                      <td colSpan={3} className="fk-print-text xsm-text text-capitalize text-left border-0">Total Bill</td>
                      <td colSpan={1} className="fk-print-text xsm-text text-capitalize text-right border-0">{theSubTotal + theVat}</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="fk-print-text xsm-text text-capitalize text-left border-0">After Round Of</td>
                      <td colSpan={1} className="fk-print-text xsm-text text-capitalize text-right border-0">{Math.round(theSubTotal + theVat)}</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="fk-print-text xsm-text text-capitalize text-left border-0">Advance</td>
                      <td colSpan={1} className="fk-print-text xsm-text text-capitalize text-right border-0">{selectedTableData.advance ?? 0}</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="fk-print-text text-capitalize text-left border-0 font-weight-bold">Grand Total</td>
                      <td colSpan={1} className="fk-print-text text-capitalize text-right border-0 font-weight-bold">{totalPayable}</td>
                    </tr>
                  </tbody>
                  <tbody className="border-bottom">
                    <tr>
                      <td colSpan={4} className="fk-print-text xsm-text text-capitalize text-center border-0">Thank You</td>
                    </tr>
                  </tbody>
                  <tbody className="border-bottom">
                    <tr>
                      <td colSpan={4} className="fk-print-text xsm-text text-capitalize text-center border-0 font-weight-bold">Customer Info</td>
                    </tr>
                  </tbody>
                  <tbody className="border-bottom">
                    <tr>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0">Name</td>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0">{orderDetails.customer?.name}</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0">Phone</td>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0">{orderDetails.customer?.phn_no}</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0">Address</td>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0">{orderDetails.customer?.address}</td>
                    </tr>
                  </tbody>                  
                  <tbody className="">
                    <tr>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0"></td>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0"></td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0"></td>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0"></td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0"></td>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0"></td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0"></td>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0"></td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0"></td>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0"></td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0">Special Occasion</td>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0">Birthday Celebration</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0">Total Guests</td>
                      <td colSpan={2} className="fk-print-text xsm-text text-capitalize text-left border-0">12</td>
                    </tr>
                  </tbody>                  
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>
      <div className='modal fade' id={"addGST7685876864"} aria-hidden='true'>
          <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                  <div className='modal-header'>
                    <h3>Add GST</h3>
                      <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal'>
                        <BiX size={20}/>
                      </div>
                  </div>
                  <div className='modal-body py-lg-10 px-lg-10'>
                  <div className='row'>
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                      <input placeholder="GST Name..." id='gstName' className="form-control border-0 form-control--light-1 rounded-0"/>
                    </div>
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3'>
                      <input placeholder="GST Number..." id='gstNumber' className="form-control border-0 form-control--light-1 rounded-0"/>
                    </div>
                  </div>
                  </div>
                  <div className='modal-footer'>                  
                    <div className='d-flex justify-content-end'>
                      <button type='button' className='btn btn-transparent btn-secondary sm-text text-dark mr-2 text-uppercase w-100' data-dismiss='modal'>Cancel</button>
                      <button type='button' onClick={addgst} className='btn btn-transparent btn-primary sm-text text-uppercase w-100' data-dismiss='modal'>Save</button>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div className='modal fade' id={"cancelOrderReason23423423423423"} aria-hidden='true'>
          <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                  <div className='modal-header'>
                    <h3>Cancelation Note</h3>
                      <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal' onClick={() => reset()}>
                        <BiX size={20}/>
                      </div>
                  </div>
                  <div className='modal-body py-lg-10 px-lg-10'>
                  <div className='row'>
                    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 py-3'>
                      <textarea placeholder="Cancel Reason..." id='cancelReason' className="form-control border-0 form-control--light-1 rounded-0"/>
                    </div>
                  </div>
                  </div>
                  <div className='modal-footer'>                  
                    <div className='d-flex justify-content-end'>
                      <button type='button' onClick={() => reset()} className='btn btn-transparent btn-secondary sm-text text-dark mr-2 text-uppercase w-100' data-dismiss='modal'>Cancel</button>
                      <button type='button' onClick={cancelTheOrder} className='btn btn-transparent btn-primary sm-text text-uppercase w-100' data-dismiss='modal'>Save</button>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <button type="button"
          data-toggle="modal"
          data-target={"#lessReason324535345345"}
          className="d-none"
          id="lessReason"
      >
          pop
      </button>
      <div className='modal fade' id={"lessReason324535345345"} aria-hidden='true'>
          <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                  <div className='modal-header'>
                    <h3>Reason</h3>
                      <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal' onClick={() => reset()}>
                      <BiX size={20}/>
                      </div>
                  </div>
                  <div className='modal-body py-lg-10 px-lg-10'>
                  <div className='row'>
                    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 py-3'>
                      <textarea placeholder="Reason..." id='qtyLessReason' className="form-control border-0 form-control--light-1 rounded-0"/>
                    </div>
                  </div>
                  </div>
                  <div className='modal-footer'>                  
                    <div className='d-flex justify-content-end'>
                      <button type='button' id="qtyLessClose" onClick={() => reset()} className='btn btn-transparent btn-secondary sm-text text-dark mr-2 text-uppercase w-100' data-dismiss='modal'>Cancel</button>
                      <button type='button' onClick={afterPrintCanceling} className='btn btn-transparent btn-primary sm-text text-uppercase w-100'>OK</button>
                    </div>
                  </div>
              </div>
          </div>
      </div>      
      <div className='modal fade' id={"shiftTable46546"} aria-hidden='true'>
          <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                  <div className='modal-header'>
                    <h3>Shift Table</h3>
                      <div className='btn btn-sm btn-icon btn-active-color-primary' id="shiftTableClose" data-dismiss='modal'>
                        <BiX size={20}/>
                      </div>
                  </div>
                  <div className='modal-body py-lg-10 px-lg-10'>
                  <div className='row'>
                    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 py-3'>
                    <Select
                      options={
                        availableTables !== null &&
                        availableTables
                      }
                      components={makeAnimated()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      classNamePrefix="select"
                      className=""
                      onChange={(val) => { 
                          handleShiftTable(val);
                          document.getElementById('shiftTableClose')?.click();
                      }}
                      maxMenuHeight="100px"
                      placeholder={_t(t("Table")) + ".."}
                  />
                    </div>
                  </div>
                  </div>
                  {/* <div className='modal-footer'>                  
                    <div className='d-flex justify-content-end'>
                      <button type='button' className='btn btn-transparent btn-secondary sm-text text-dark mr-2 text-uppercase w-100' data-dismiss='modal'>Cancel</button>
                      <button type='button' onClick={cancelTheOrder} className='btn btn-transparent btn-primary sm-text text-uppercase w-100' data-dismiss='modal'>Save</button>
                    </div>
                  </div> */}
              </div>
          </div>
      </div>
        <main id="main" data-simplebar>        
            <div className="container">
                <div className="row t-mt-10 gx-2">
                    {/* <div className="col-12 t-mb-30 mb-lg-10">
                        <div className="t-bg-white sm_shadow sm_card">
                            <div className="row t-mt-10 gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                                <div className="col">
                                    <NavLink
                                        to="/dashboard/pos/online-orders"
                                        className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-primary xsm-text text-uppercase text-center w-100"
                                    >
                                        <i className="fa fa-list-alt mr-3 fa-lg"></i>
                                        {_t(t("Online Orders"))}{" "}
                                        {onlineCounter > 0 && (
                                        <span className="bg-danger text-white p-2 rounded-md">
                                            ({onlineCounter})
                                        </span>
                                        )}
                                    </NavLink>
                                </div>
                                <div className="col">
                                    {window.location.pathname ===
                                    "/dashboard/pos/settled" ? (
                                        <NavLink
                                        to="/refresh"
                                        className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-outline-secondary xsm-text text-uppercase text-center w-100"
                                        >
                                        {_t(t("Settled"))}
                                        </NavLink>
                                    ) : (
                                        <NavLink
                                        to="/dashboard/pos/settled"
                                        className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-outline-secondary xsm-text text-uppercase text-center w-100"
                                        >
                                        {_t(t("Settled"))}
                                        </NavLink>
                                    )}
                                </div>
                                <div className="col">
                                    {window.location.pathname ===
                                    "/dashboard/pos/submitted" ? (
                                        <NavLink
                                        to="/refresh"
                                        className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-outline-secondary xsm-text text-uppercase text-center w-100"
                                        >
                                        {_t(t("Submitted"))}
                                        </NavLink>
                                    ) : (
                                        <NavLink
                                        to="/dashboard/pos/submitted"
                                        className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-outline-secondary xsm-text text-uppercase text-center w-100"
                                        >
                                        {_t(t("Submitted"))}
                                        </NavLink>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {!onTableLoading ?
                    <div className="col-12 t-mb-30 mb-lg-0">
                        <div className="t-bg-white sm_shadow sm_card">
                            <div className="row t-mt-10 gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                                <div className="col-12 t-mb-15">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <ul className="t-list fk-breadcrumb">
                                            <li className="fk-breadcrumb__list">
                                            <span className="t-link fk-breadcrumb__link text-capitalize t-text-black">
                                                {selectedTable != null ? selectedTable.name : _t(t("Select Table"))}
                                            </span>
                                            </li>
                                        </ul>
                                        {selectedTable != null && <ul className="t-list fk-sort align-items-center justify-content-center">
                                          {/* <li className="fk-sort__list mb-0">
                                          <Select
                                                options={
                                                  availableTables !== null &&
                                                  availableTables
                                                }
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.name}
                                                classNamePrefix="select"
                                                className="xsm-text table_shift"
                                                onChange={(val) => { 
                                                    handleShiftTable(val)
                                                }}
                                                maxMenuHeight="100px"
                                                placeholder={_t(t("Table")) + ".."}
                                            /></li> */}
                                            <li className="fk-sort__list mb-0 flex-grow-1">
                                            <button type="button" className="w-100 btn btn-dark sm-text text-uppercase" data-toggle="modal"
                                                data-target={"#addGST7685876864"}>{_t(t("Add GST Number"))}</button>
                                            </li>
                                            <li className="fk-sort__list mb-0 flex-grow-1">
                                            <button type="button"
                                                data-toggle="modal"
                                                data-target={"#cancelOrderReason23423423423423"}
                                                className="w-100 btn btn-danger sm-text text-uppercase"
                                            >
                                                {_t(t("Void"))}
                                            </button>
                                            </li>
                                            <li className="fk-sort__list mb-0 flex-grow-1">
                                            <button type="button"
                                                data-toggle="modal"
                                                data-target={"#shiftTable46546"}
                                                className="w-100 btn btn-danger sm-text text-uppercase"
                                            >
                                                {_t(t("Shift Table"))}
                                            </button>
                                            </li>
                                            <li className="fk-sort__list mb-0 flex-grow-1">
                                            <button type="button"
                                                // data-toggle="modal"
                                                // data-target="#addSlot"
                                                onClick={() => {
                                                setSelectedTable(null);
                                                setTableList({});
                                                setPrintStatus('none')
                                                }}
                                                className="w-100 btn btn-dark sm-text text-uppercase"
                                            >
                                                {_t(t("Back"))}
                                            </button>
                                            </li>                                            
                                        </ul>}
                                        
                                    </div>
                                </div>
                                {selectedTable == null ? 
                                <>
                                  {authUserInfo.details &&
                                  authUserInfo.details.user_type !== "staff" && (
                                  <div className="col-xl-2 col-lg-3 col-xxl-2 col-md-3">
                                    <Select
                                      options={
                                        branchForSearch &&
                                        branchForSearch.filter(
                                          (branch) => branch.active == 1
                                        )
                                      }
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      classNamePrefix="select"
                                      className="xsm-text"
                                      onChange={handleSetBranch}
                                      maxMenuHeight="200px"
                                      placeholder={_t(t("Branch")) + ".."}
                                    />
                                  </div>
                                  )}
                                    <div className="col-xl-2 col-lg-3 col-xxl-2 col-md-3">
                                    <Select
                                        options={
                                          bookingSlotForSearch !== null &&
                                          bookingSlotForSearch
                                        }
                                        components={makeAnimated()}
                                        getOptionLabel={(option) => option.in_time +'-'+option.out_time}
                                        getOptionValue={(option) => option.in_time +'-'+option.out_time}
                                        classNamePrefix="select"
                                        className={`${isSlotEmpty ? "xsm-text is-invalid": "xsm-text is-valid"}`}
                                        onChange={(val) => { 
                                          handleSetSlot(val);
                                          setIsSlotEmpty(false);
                                          setSlotId(val.od)
                                        }}
                                        maxMenuHeight="100px"
                                        placeholder={_t(t("Time Slots")) + ".."}
                                    />
                                    </div>
                                    {isSlotEmpty && <small className="error_text invalid-feedback">Please fill this field</small>}
                                  
                                  <div className="col-12 mt-3 position_relative min_height_sec">
                                    {newSettings && newSettings.workPeriod === null && (
                                      <div className="fk-left-overlay">
                                        <div className="fk-left-overlay__content text-center m-auto">
                                          <h5
                                            className={`text-primary text-uppercase ${
                                              authUserInfo.details &&
                                              authUserInfo.details.user_type !== "staff" &&
                                              "mb-0"
                                            }`}
                                          >
                                            {authUserInfo.details &&
                                            authUserInfo.details.user_type !== "staff"
                                              ? _t(t("Select branch to active Reservation"))
                                              : _t(t("start workperiod"))}
                                          </h5>
                                          {authUserInfo.details &&
                                            authUserInfo.details.user_type !== "staff" && (
                                              <>
                                                <h6 className="mt-1 text-uppercase xsm-text mb-1">
                                                  {_t(
                                                    t("Start workperiod if it is not started")
                                                  )}
                                                </h6>
                                                <small className="d-flex justify-content-center text-lowercase xsm-text mt-0 mb-2">
                                                  (
                                                  {_t(
                                                    t(
                                                      "Use staff account not to see this multiple times"
                                                    )
                                                  )}
                                                  )
                                                </small>
                                              </>
                                            )}
                                          <NavLink
                                            to="/dashboard"
                                            className="t-heading-font btn btn-dark btn-sm text-uppercase sm-text"
                                          >
                                            {_t(t("Dashboard"))}
                                          </NavLink>
                                        </div>
                                      </div>
                                    )}
                                    {/* {authUserInfo.details &&
                                    authUserInfo.details.user_type !== "staff" && ( */}
                                    {dataLoading ?
                                    <div className="row"> 
                                      <div className="col-md-6 col-lg-4 t-mb-30">
                                        <Skeleton
                                          style={{ height: "250px" }}
                                          className="bg-white"
                                        />
                                      </div>
                                      <div className="col-md-6 col-lg-4 t-mb-30">
                                        <Skeleton
                                          style={{ height: "250px" }}
                                          className="bg-white"
                                        />
                                      </div>
                                      <div className="col-md-6 col-lg-4 t-mb-30">
                                        <Skeleton
                                          style={{ height: "250px" }}
                                          className="bg-white"
                                        />
                                      </div>
                                      </div> :
                                      <>
                                        <ul className={tablesVisible && tableList.data != null ? "nav nav-pills mb-3" : "d-none"} id="pills-tab" role="tablist">
                                          {filteredTableSection.list != null ? filteredTableSection.list.map((tableSec, index) => {
                                            return (
                                              <li className="nav-item">
                                                <a className={index == 0 ? "nav-link active" : "nav-link"} id={'section_tab_'+tableSec.id} data-toggle="pill" href={'#section_content_'+tableSec.id} role="tab" aria-controls={'section_tab_'+tableSec.id} aria-selected="true">{tableSec.section_name}</a>
                                              </li>
                                            )
                                          }): <></>}
                                        </ul>
                                        <div className={tablesVisible && tableList.data != null ? "tab-content" : "d-none"} id="pills-tabContent">
                                        {filteredTableSection.list != null ? filteredTableSection.list.map((tableSec, index) => {
                                            return (
                                          <div className={index == 0 ? "tab-pane fade show active": "tab-pane fade"} id={'section_content_'+tableSec.id} role="tabpanel" aria-labelledby="pills-home-tab">
                                            {/* {tableSec.section_name} */}
                                            <div className="row t-mt-10 gx-2">
                                                {tableList.data != null ? tableList.data.map((table, index) => {
                                                    return (
                                                      table.section_id == tableSec.id ? 
                                                      <div id="46356346843kjghkg"
                                                    className={
                                                        // table.status == "booked" ? "col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-4" : 
                                                         "col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-4 table_available"}
                                                    key={table.id}
                                                    onClick={() => {
                                                      table.status == "booked" 
                                                      ? onTableSelect(table)
                                                      : onTableEmpty()
                                                    }}
                                                    >
                                                        <div className={table.status == "booked" ? table.print_status == 1 ? "reserve_table_sec t-text-black bg-danger" : "reserve_table_sec t-text-black bg-successs" : "reserve_table_sec t-text-black bg-gray"} 
                                                        // style={{
                                                        //       backgroundImage: `url(${
                                                        //         table.status == "booked"
                                                        //           ? table.print_status == 1 ? "/assets/img/canceled_table.png" : "/assets/img/booked_table.png"
                                                        //           : "/assets/img/available_table.png"
                                                        //     })`,
                                                        //     }}
                                                            >
                                                              {table.status == "booked"
                                                                  ? <div className="row">
                                                            <p className="col-12 text-center">{table.guest_name}</p>
                                                            <p className="col-12 text-center">{table.total_payable ? '₹'+table.total_payable : ''}</p></div> : <p>{table.name}</p> } 
                                                        </div>
                                                    </div>
                                                    : <div className="d-none align-items-center justify-content-center">No tables available</div>
                                                    )})
                                                : <></>}
                                            </div>
                                          </div>
                                          )
                                        }): <></>}
                                        </div>
                                      </>}
                                  </div>
                                </>
                                : 
                                <div className="col-12">
                                  <div className="row">
                                    <div className="col-xl-7 col-lg-7 col-md-6">
                                      <div className="col-xl-12 col-12 mb-3">
                                        <div className="d-flex align-items-center gap-2">
                                          <div className="d-flex align-items-center gap-1">
                                            <MdPerson size={22} color="black"/>
                                            <p>{selectedTableData != {} ? selectedTableData?.customer_name : ''}</p>
                                          </div>
                                          {/* <div className="d-flex align-items-center gap-1">
                                            <MdManageAccounts size={22} color="black"/>
                                            <p>{selectedTableData != {} ? selectedTableData?.waiter_name : ''}</p>
                                          </div> */}
                                          <div className="d-flex align-items-center gap-1">
                                            <MdGroups size={22} color="black"/>
                                            <p>{selectedTableData != {} ? selectedTableData?.total_guest : ''}</p>
                                          </div>
                                          {/* <div className="d-flex align-items-center gap-1">                                            
                                            <button type="button" className="btn btn-sm btn-dark" data-toggle="modal"
                                                data-target={"#addGST7685876864"}>Add GST Number</button>
                                          </div> */}
                                        </div>
                                      </div>
                                      {/* <div className="col-md-12">
                                        <div className="reserve_form row">
                                          <div className="form-group col-md-4 mb-2">
                                            <label for="phone">Phone</label>
                                            <input type="phone" className="form-control" placeholder="Enter Phone" id="phone"/>
                                          </div>
                                          <div className="form-group col-md-4 mb-2">
                                            <label for="guest">Guest Name</label>
                                            <input type="guest" className="form-control" placeholder="Enter Name" id="guest"/>
                                          </div>
                                          <div className="form-group col-md-4 mb-2">
                                            <label for="guest">Order Date</label>
                                            <DatePicker
                                              selected={orderDate}
                                              onChange={(date) => setOrderDate(date)}
                                              peekNextMonth
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              className="form-control xsm-text py-2"
                                              placeholderText={_t(t("Order Date"))}
                                              shouldCloseOnSelect={false}
                                            />
                                          </div>
                                          <div className="form-group col-md-3 mb-2">
                                            <label for="adults_count">No. of Adults</label>
                                            <input type="adults_count" className="form-control" placeholder="0" id="adults_count"/>
                                          </div>
                                          <div className="form-group col-md-3 mb-2">
                                            <label for="kids_count">No. of Kids</label>
                                            <input type="kids_count" className="form-control" placeholder="0" id="kids_count"/>
                                          </div>
                                          <div className="form-group col-md-3 mb-2">
                                            <label for="males_count">No. of Males</label>
                                            <input type="males_count" className="form-control" placeholder="0" id="males_count"/>
                                          </div>
                                          <div className="form-group col-md-3 mb-2">
                                            <label for="females_count">No. of Females</label>
                                            <input type="females_count" className="form-control" placeholder="0" id="females_count"/>
                                          </div>
                                          <div className="form-group col-md-2 mb-2">
                                            <label for="guest">Reserved By</label>
                                            <input type="guest" className="form-control" placeholder="Enter Name" id="guest"/>
                                          </div>
                                          <div className="form-group col-md-5 sm_tags mb-2">
                                            <label for="guest">Tags</label>
                                            <ReactTags
                                              tags={resTags}
                                              suggestions={suggestions}
                                              delimiters={delimiters}
                                              handleDelete={handleTagDelete}
                                              handleAddition={handleAddition}
                                              handleDrag={handleDrag}
                                              handleTagClick={handleTagClick}
                                              inputFieldPosition="bottom"
                                              autocomplete
                                            />
                                          </div>
                                          <div className="form-group col-md-5 mb-2">
                                            <label for="guest">Comments</label>
                                            <textarea className="form-control" rows="3" id="comment"></textarea>
                                          </div>
                                        </div>
                                      </div> */}
                                      <div className="col-md-12 col-xl-12 mb-3">
                                        <div className="" data-simplebar>
                                          <ul className="t-list fk-pos-nav list-group cate_list">
                                              {getSystemSettings(
                                              generalSettings,
                                              "pos_screen"
                                              ) === "1" &&
                                              deptTagForSearch && (
                                                  <>
                                                  {deptTagForSearch.map(
                                                      (deptItem, deptItemIndex) => {
                                                      return (
                                                          <li
                                                          className={`t-mb-10 ${
                                                              deptItemIndex ===
                                                              deptTagForSearch.length - 1
                                                              ? "t-mb-30"
                                                              : ""
                                                          }`}
                                                          >
                                                          <style></style>
                                                          <button type="button"
                                                              className={`button arrow text-uppercase ${
                                                              orderDetails &&
                                                              orderDetails.dept_tag &&
                                                              orderDetails.dept_tag.id ===
                                                                  deptItem.id
                                                                  ? "active"
                                                                  : ""
                                                              }`}
                                                              onClick={() => {
                                                              handleSetDeptTag(deptItem);
                                                              }}
                                                          >
                                                              {deptItem.name}
                                                          </button>
                                                          </li>
                                                      );
                                                      }
                                                  )}
                                                  </>
                                              )}

                                              {/* Food groups */}
                                              {foodGroupForSearch &&
                                              foodGroupForSearch.map(
                                                  (groupItem, groupIndex) => {
                                                  return (
                                                      <li
                                                      className="fk-pos-nav__list"
                                                      key={groupIndex}
                                                      >
                                                      <button
                                                          type="button"
                                                          onClick={() => {
                                                          // set active group, group items, initial item active, todo:: set variations and properties
                                                          let tempItems =
                                                              foodForSearch &&
                                                              foodForSearch.filter((tempItem) => {
                                                              return (
                                                                  parseInt(
                                                                  tempItem.food_group_id
                                                                  ) === groupItem.id
                                                              );
                                                              });

                                                          if (
                                                              tempItems &&
                                                              tempItems.length > 0
                                                          ) {
                                                              setFoodItem({
                                                              ...foodItem,
                                                              foodGroup: groupItem,
                                                              items: tempItems,
                                                              selectedItem:
                                                                  tempItems && tempItems[0],
                                                              variations:
                                                                  tempItems &&
                                                                  parseInt(
                                                                  tempItems[0].has_variation
                                                                  ) === 1
                                                                  ? tempItems[0].variations
                                                                  : null,
                                                              properties:
                                                                  tempItems &&
                                                                  parseInt(
                                                                  tempItems[0].has_property
                                                                  ) === 1
                                                                  ? tempItems[0].properties
                                                                  : null,
                                                              });
                                                          } else {
                                                              setFoodItem({
                                                              foodGroup: groupItem,
                                                              items: null,
                                                              selectedItem: null,
                                                              variations: null,
                                                              properties: null,
                                                              });
                                                          }

                                                          //set active item in order list
                                                          setActiveItemInOrder(null);
                                                          setSearchFoodItems([]);
                                                          }}
                                                          //set active or !
                                                          className={`w-100 btn btn-outline-dark ${
                                                          foodItem.foodGroup &&
                                                          foodItem.foodGroup.id ===
                                                              groupItem.id &&
                                                          "active"
                                                          }`}
                                                      >
                                                          {groupItem.name}
                                                      </button>
                                                      </li>
                                                  );
                                                  }
                                              )}
                                              {/* Food groups */}
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-xl-12 col-xxl-12 order-xl-1">
                                        <div className="fk-dish--scroll reserve_scroll position-relative" data-simplebar>
                                          <div className="list-group fk-dish row t-mt-10 gx-2">
                                            {/* all items are showing here */}

                                            {searchFoodItems.length > 0
                                              ? searchFoodItems.map(
                                                  (tempFoodItem, tempFoodItemIndex) => {
                                                    return (
                                                      <div
                                                        onClick={() => {
                                                          if (showManageStock) {
                                                            if (
                                                              handleGetStock(
                                                                tempFoodItem.id
                                                              ) === 0
                                                            ) {
                                                              toast.error(
                                                                `${_t(
                                                                  t("Out of stock")
                                                                )}`,
                                                                {
                                                                  position:
                                                                  "bottom-center",
                                                                  closeButton: false,
                                                                  autoClose: 10000,
                                                                  hideProgressBar: false,
                                                                  closeOnClick: true,
                                                                  pauseOnHover: true,
                                                                  className:
                                                                  "text-center toast-notification",
                                                                }
                                                              );
                                                            } else {
                                                              // set variations, properties and selected items here
                                                              setFoodItem({
                                                                ...foodItem,
                                                                selectedItem:
                                                                  tempFoodItem,
                                                                variations:
                                                                  tempFoodItem &&
                                                                  parseInt(
                                                                    tempFoodItem.has_variation
                                                                  ) === 1
                                                                    ? tempFoodItem.variations
                                                                    : null,
                                                                properties:
                                                                  tempFoodItem &&
                                                                  parseInt(
                                                                    tempFoodItem.has_property
                                                                  ) === 1
                                                                    ? tempFoodItem.properties
                                                                    : null,
                                                              });
                                                               
                                                              const url = BASE_URL + "/settings/addexistingorder";
                                                              try {
                                                                var bodyData =   
                                                                {
                                                                  "orderDetails":
                                                                    [{
                                                                      "order_id": selectedOrderId
                                                                    }],
                                                                  "orderItems": [
                                                                    {
                                                                      "item": tempFoodItem,
                                                                      "variation": null,
                                                                      "quantity": 1,
                                                                    }
                                                                  ]
                                                                };
                                                                const res = axios.post(url, bodyData, {
                                                                  headers: { Authorization: `Bearer ${getCookie()}` },
                                                                });
                                                                if(res) {
                                                                  document.getElementById('46356346843kjghkg')?.click();
                                                                }
                                                              } catch {
                                                        
                                                              }
                                                              handleOrderItem(
                                                                tempFoodItem
                                                              );
                                                            }
                                                          } else {
                                                            // set variations, properties and selected items here
                                                            console.log('add tempFoodItem')
                                                            console.log(tempFoodItem)
                                                            setFoodItem({
                                                              ...foodItem,
                                                              selectedItem:
                                                                tempFoodItem,
                                                              variations:
                                                                tempFoodItem &&
                                                                parseInt(
                                                                  tempFoodItem.has_variation
                                                                ) === 1
                                                                  ? tempFoodItem.variations
                                                                  : null,
                                                              properties:
                                                                tempFoodItem &&
                                                                parseInt(
                                                                  tempFoodItem.has_property
                                                                ) === 1
                                                                  ? tempFoodItem.properties
                                                                  : null,
                                                            });
                                                            let newOrderItem = newOrder.map((item) => item.item.item_id);
                                                              function checkAdult(age) {
                                                                return age == tempFoodItem.id;
                                                              } 
                                                            const url = BASE_URL + "/settings/addexistingorder";
                                                            try {
                                                              var bodyData =   
                                                              {
                                                                "orderDetails":
                                                                  [{
                                                                    "order_id": selectedOrderId
                                                                  }],
                                                                "orderItems": [
                                                                  {
                                                                    "item": tempFoodItem,
                                                                    "variation": null,
                                                                    "quantity": 1,
                                                                  }
                                                                ]
                                                              };
                                                              const res = axios.post(url, bodyData, {
                                                                headers: { Authorization: `Bearer ${getCookie()}` },
                                                              });
                                                              if(res) {
                                                                document.getElementById('46356346843kjghkg')?.click();
                                                              }
                                                            } catch {
                                                      
                                                            }
                                                            handleOrderItem(
                                                              tempFoodItem
                                                            );
                                                          }
                                                        }}
                                                        className={`fk-dish__link t-mb-10 col-md-6 col-lg-4 col-xl-6 col-xxl-4 t-link border-0 pointer-cursor ${
                                                          foodItem.selectedItem &&
                                                          foodItem.selectedItem.id ===
                                                            tempFoodItem.id &&
                                                          "active"
                                                        }`}
                                                      >
                                                        {/* single item here */}
                                                        <div className="fk-dish-card w-100">
                                                          <div className="fk-dish-card__img w-100">
                                                            <img
                                                              src={tempFoodItem.image}
                                                              alt=""
                                                              className="img-fluid m-auto w-100"
                                                            />
                                                          </div>
                                                          <span className="fk-dish-card__title text-center text-uppercase pb-0">
                                                            {tempFoodItem.name} <br />
                                                            {/* check is manage menu is active or not  */}
                                                            {showManageStock ? (
                                                              <>
                                                                {_t(t("In Stock"))}:{" "}
                                                                {handleGetStock(
                                                                  tempFoodItem.id
                                                                )}
                                                              </>
                                                            ) : null}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )
                                              : foodItem.items &&
                                                foodItem.items.map(
                                                  (tempFoodItem, tempFoodItemIndex) => {
                                                    // console.log('tempFoodItem');
                                                    // console.log(tempFoodItem);
                                                    return (
                                                      <div
                                                        className={`fk-dish__link t-mb-10 col-md-6 col-lg-4 col-xl-4 col-xxl-3 t-link border-0 pointer-cursor px-2 ${
                                                          foodItem.selectedItem &&
                                                          foodItem.selectedItem.id ===
                                                            tempFoodItem.id &&
                                                          "active"
                                                        }`}
                                                        key={tempFoodItem.id}
                                                        onClick={() => {
                                                          if (showManageStock) {
                                                            if (
                                                              handleGetStock(
                                                                tempFoodItem.id
                                                              ) === 0
                                                            ) {
                                                              toast.error(
                                                                `${_t(
                                                                  t("Out of stock")
                                                                )}`,
                                                                {
                                                                  position:
                                                                    "bottom-center",
                                                                  closeButton: false,
                                                                  autoClose: 10000,
                                                                  hideProgressBar: false,
                                                                  closeOnClick: true,
                                                                  pauseOnHover: true,
                                                                  className:
                                                                    "text-center toast-notification",
                                                                }
                                                              );
                                                            } else {
                                                              // console.log('add tempFoodItem');
                                                              // console.log(tempFoodItem);
                                                              // set variations, properties and selected items here
                                                              setFoodItem({
                                                                ...foodItem,
                                                                selectedItem:
                                                                  tempFoodItem,
                                                                variations:
                                                                  tempFoodItem &&
                                                                  parseInt(
                                                                    tempFoodItem.has_variation
                                                                  ) === 1
                                                                    ? tempFoodItem.variations
                                                                    : null,
                                                                properties:
                                                                  tempFoodItem &&
                                                                  parseInt(
                                                                    tempFoodItem.has_property
                                                                  ) === 1
                                                                    ? tempFoodItem.properties
                                                                    : null,
                                                              });
                                                              let newOrderItem = newOrder.map((item) => item.item.item_id);
                                                              function checkAdult(age) {
                                                                return age == tempFoodItem.id;
                                                              }  
                                                              const url = BASE_URL + "/settings/addexistingorder";
                                                              try {
                                                                var bodyData =   
                                                                {
                                                                  "orderDetails":
                                                                    [{
                                                                      "order_id": selectedOrderId
                                                                    }],
                                                                  "orderItems": [
                                                                    {
                                                                      "item": tempFoodItem,
                                                                      "variation": null,
                                                                      "quantity": 1,
                                                                    }
                                                                  ]
                                                                };
                                                                const res = axios.post(url, bodyData, {
                                                                  headers: { Authorization: `Bearer ${getCookie()}` },
                                                                });
                                                                if(res) {
                                                                  document.getElementById('46356346843kjghkg')?.click();
                                                                }
                                                              } catch {
                                                        
                                                              }
                                                              handleOrderItem(
                                                                tempFoodItem
                                                              );
                                                            }
                                                          } else {
                                                            console.log('tempFoodItem ----');
                                                            console.log(tempFoodItem);
                                                            // set variations, properties and selected items here
                                                            setFoodItem({
                                                              ...foodItem,
                                                              selectedItem:
                                                                tempFoodItem,
                                                              variations:
                                                                tempFoodItem &&
                                                                parseInt(
                                                                  tempFoodItem.has_variation
                                                                ) === 1
                                                                  ? tempFoodItem.variations
                                                                  : null,
                                                              properties:
                                                                tempFoodItem &&
                                                                parseInt(
                                                                  tempFoodItem.has_property
                                                                ) === 1
                                                                  ? tempFoodItem.properties
                                                                  : null,
                                                            });
                                                            let newOrderItem = newOrder.map((item) => item.item.item_id);
                                                              function checkAdult(age) {
                                                                return age == tempFoodItem.id;
                                                              } 
                                                            const url = BASE_URL + "/settings/addexistingorder";
                                                            try {
                                                              var bodyData =   
                                                              {
                                                                "orderDetails":
                                                                  [{
                                                                    "order_id": selectedOrderId
                                                                  }],
                                                                "orderItems": [
                                                                  {
                                                                    "item": tempFoodItem,
                                                                    "variation": null,
                                                                    "quantity": 1,
                                                                  }
                                                                ]
                                                              };
                                                              const res = axios.post(url, bodyData, {
                                                                headers: { Authorization: `Bearer ${getCookie()}` },
                                                              });
                                                              if(res) {
                                                                document.getElementById('46356346843kjghkg')?.click();
                                                              }
                                                            } catch {
                                                      
                                                            }
                                                            handleOrderItem(
                                                              tempFoodItem
                                                            );
                                                            resTablePOS()
                                                          }
                                                        }}
                                                      >
                                                        {/* single item here */}
                                                        <div className="fk-dish-card w-100 sm_shadow">
                                                          <div className="fk-dish-card__img w-100">
                                                            <img
                                                              src={tempFoodItem.image}
                                                              alt=""
                                                              className="img-fluid m-auto w-100 sdsdsd"
                                                            />
                                                          </div>
                                                          {/* <div className="fk-dish-card__body pb-2"> */}
                                                            <div className="">
                                                              <span className="fk-dish-card__title">
                                                                {tempFoodItem.name} <br />
                                                                {/* check is manage menu is active or not  */}
                                                                {showManageStock ? (
                                                                  <>
                                                                    {_t(t("In Stock"))}:{" "}
                                                                    {handleGetStock(
                                                                      tempFoodItem.id
                                                                    )}
                                                                  </>
                                                                ) : null}
                                                              </span>
                                                              <p className="fk-dish-card__price text-start">
                                                                {currencySymbolLeft()}
                                                                {formatPrice(tempFoodItem.price)}
                                                                {currencySymbolRight()}
                                                              </p>
                                                            </div>
                                                            {/* <button className="fk-dish-card__btn btn-sm btn btn-dark" >Add</button> */}
                                                          </div>
                                                        {/* </div> */}
                                                      </div>
                                                    );
                                                  }
                                                )}

                                            {/* seach items here */}
                                          </div>
                                          {selectedTableData.comments != null && <> 
                                          <div className={comment ? "position-absolute bottom-0 w-100 p-3 pb-4" : "d-none"}>
                                            <div className="card rounded_1">
                                                <div className="card-header border-0  d-flex justify-content-between">
                                                  <h3>Order Notes</h3>
                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => {
                                                      setComment(false)
                                                      }}>
                                                      <BiX size={20}/>
                                                    </div>
                                                </div>
                                                <div className="card-body border-0">
                                                    <p>{selectedTableData.comments}</p>
                                                </div>
                                            </div>
                                          </div></>}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-6">
                                      <div className="fk-right-nav t-bg-white">
                                        <div className="h-100 w-100 d-none d-md-block">
                                          <div className="fk-price-table">
                                            <div className="fk-price-table__head">
                                              <div className="row gx-0 align-items-center">
                                                <div className="col-12 text-left">
                                                  <span className="d-block sm-text font-weight-bold text-uppercase">
                                                    {/* {_t(t("Order token"))}:{" "}
                                                    {newOrder ? (
                                                      <>
                                                        #{orderDetails.token.id} -{" "}
                                                        <Moment format="LT">
                                                          {orderDetails.token.time}
                                                        </Moment>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )} */}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="fk-price-table__body t-mt-10">
                                              <div className="fk-price-table__body-top">
                                                <div className="fk-table">
                                                  <div className="fk-table__head t-bg-black t-text-white">
                                                    <div className="row g-0 border">
                                                      <div className="col-1 text-center border-right">
                                                        <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                          {_t(t("S/L"))}
                                                        </span>
                                                      </div>
                                                      <div className="col-6 text-center border-right">
                                                        <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                          {_t(t("food item"))}
                                                        </span>
                                                      </div>
                                                      <div className="col-2 text-center border-right">
                                                        <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                          {_t(t("QTY"))}
                                                        </span>
                                                      </div>
                                                      <div className="col-3 text-center">
                                                        <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                          {_t(t("price"))}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="fk-table__body table_page"
                                                    data-simplebar
                                                  >
                                                    <div className="sky-is-blue reverse-this">
                                                      {/* loop through order list items */}
                                                      {newOrder && newOrder.length > 0 ? (
                                                        newOrder.map(
                                                          (
                                                            orderListItem,
                                                            orderListItemIndex
                                                          ) => {
                                                            // console.log('orderListItem table')
                                                            // console.log(orderListItem)
                                                            return (
                                                              <>
                                                              {/* ${
                                                                    orderListItemIndex ===
                                                                      activeItemInOrder &&
                                                                    "active"
                                                                  }  */}
                                                                <div
                                                                  className={`fk-table-container-order ${
                                                                    orderListItemIndex ===
                                                                      activeItemInOrder &&
                                                                    "active"
                                                                  }`}
                                                                  onClick={(e) => {
                                                                    e.preventDefault();
                                                                    //orderListItem's group wise all items
                                                                    let tempItems =
                                                                      foodForSearch &&
                                                                      foodForSearch.filter(
                                                                        (tempItem) => {
                                                                          return (
                                                                            tempItem.food_group_id ===
                                                                            orderListItem.item
                                                                              .food_group_id
                                                                          );
                                                                        }
                                                                      );

                                                                    //orderListItem's group
                                                                    let foodGroup =
                                                                      foodGroupForSearch &&
                                                                      foodGroupForSearch.find(
                                                                        (groupItem) => {
                                                                          return (
                                                                            groupItem.id ===
                                                                            parseInt(
                                                                              orderListItem.item
                                                                                .food_group_id
                                                                            )
                                                                          );
                                                                        }
                                                                      );

                                                                    // selected pos item
                                                                    let selectedItemTemp =
                                                                      tempItems &&
                                                                      tempItems.find(
                                                                        (tempSelectedItem) => {
                                                                          return (
                                                                            tempSelectedItem.id ===
                                                                            orderListItem.item
                                                                              .id
                                                                          );
                                                                        }
                                                                      );

                                                                    // Set variations, properties, selected item
                                                                    setFoodItem({
                                                                      ...foodItem,
                                                                      foodGroup: foodGroup,
                                                                      items: tempItems,
                                                                      selectedItem:
                                                                        selectedItemTemp,
                                                                      variations:
                                                                        selectedItemTemp &&
                                                                        parseInt(
                                                                          selectedItemTemp.has_variation
                                                                        ) === 1
                                                                          ? selectedItemTemp.variations
                                                                          : null,
                                                                      properties:
                                                                        selectedItemTemp &&
                                                                        parseInt(
                                                                          selectedItemTemp.has_property
                                                                        ) === 1
                                                                          ? selectedItemTemp.properties
                                                                          : null,
                                                                    });

                                                                    //set active order list index for background color of selected
                                                                    setActiveItemInOrder(
                                                                      orderListItemIndex
                                                                    );
                                                                  }}
                                                                >
                                                                  <div
                                                                    className={`row g-0 border-top-0 border-bottom `}
                                                                  >
                                                                    <div className="col-1 text-center border-left d-flex justify-content-center align-items-center">
                                                                      {newOrder.length -
                                                                        orderListItemIndex}
                                                                    </div>
                                                                    <div
                                                                      className={`col-6 border-left border-right py-2`}
                                                                    >
                                                                      <div className="d-flex justify-content-between">
                                                                        <span className="text-capitalize d-block t-pt-5 t-pb-5 t-pl-5 t-pr-5 sm-text font-weight-bold t-mr-8">
                                                                          {
                                                                            orderListItem.item
                                                                              .name
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                      <div className="row g-0">
                                                                        {/* if item has variations show the selected in order list */}
                                                                        {parseInt(
                                                                          orderListItem.item
                                                                            .has_variation
                                                                        ) === 1 && (
                                                                          <div className="col-12">
                                                                            <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pr-5 t-pl-5">
                                                                              variation :
                                                                            </span>
                                                                            <span className="text-capitalize xsm-text d-inline-block badge rounded-pill bg-warning text-dark font-weight-md">
                                                                              {orderListItem.variation
                                                                                ? orderListItem
                                                                                    .variation
                                                                                    .variation_name
                                                                                : "-"}
                                                                            </span>
                                                                          </div>
                                                                        )}

                                                                        {/* if item has properties show the selected in order list, loop here  */}
                                                                        {orderListItem.properties &&
                                                                          orderListItem
                                                                            .properties.length >
                                                                            0 &&
                                                                          selectedPropertyGroup[
                                                                            orderListItemIndex
                                                                          ] !== undefined &&
                                                                          selectedPropertyGroup[
                                                                            orderListItemIndex
                                                                          ].map(
                                                                            (thisIsGroup) => {
                                                                              let theGroup =
                                                                                propertyGroupForSearch &&
                                                                                propertyGroupForSearch.find(
                                                                                  (theItem) => {
                                                                                    return (
                                                                                      theItem.id ===
                                                                                      thisIsGroup
                                                                                    );
                                                                                  }
                                                                                );
                                                                              return (
                                                                                <div className="col-12">
                                                                                  <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pr-5 t-pl-5">
                                                                                    {theGroup &&
                                                                                      theGroup.name}{" "}
                                                                                    :
                                                                                  </span>
                                                                                  {orderListItem.properties.map(
                                                                                    (
                                                                                      propertyName
                                                                                    ) => {
                                                                                      if (
                                                                                        parseInt(
                                                                                          propertyName
                                                                                            .item
                                                                                            .property_group_id
                                                                                        ) ===
                                                                                        theGroup.id
                                                                                      ) {
                                                                                        return (
                                                                                          <span className="text-capitalize xsm-text d-inline-block badge rounded-pill bg-warning text-dark font-weight-md mr-1">
                                                                                            {
                                                                                              propertyName
                                                                                                .item
                                                                                                .name
                                                                                            }{" "}
                                                                                            <span>
                                                                                              {" "}
                                                                                              {propertyName.quantity >
                                                                                                1 &&
                                                                                                "(" +
                                                                                                  propertyName.quantity +
                                                                                                  ")"}
                                                                                            </span>
                                                                                          </span>
                                                                                        );
                                                                                      } else {
                                                                                        return true;
                                                                                      }
                                                                                    }
                                                                                  )}
                                                                                </div>
                                                                              );
                                                                            }
                                                                          )}

                                                                        {/* if item has properties show the selected in order list  */}
                                                                      </div>
                                                                    </div>
                                                                    {/* Quantity */}
                                                                    <div className="col-2 text-center border-right d-flex justify-content-center align-items-center">
                                                                      <div className="fk-qty t-pt-5 t-pb-5 justify-content-center px-1">
                                                                        <span
                                                                          className="fk-qty__icon"
                                                                          onClick={() => {
                                                                            orderListItemIndex ===
                                                                      activeItemInOrder &&                                         
                                                                            updateQuanity(orderListItem.quantity - 1, orderListItem, "less")
                                                                          }}
                                                                        >
                                                                          <i className="las la-minus"></i>
                                                                        </span>
                                                                        <input
                                                                          type="text"
                                                                          value={
                                                                            orderListItem.quantity
                                                                          }
                                                                          className="fk-qty__input t-bg-clear"
                                                                          readOnly
                                                                        />
                                                                        <span
                                                                          className="fk-qty__icon"
                                                                          onClick={() => {
                                                                            orderListItemIndex ===
                                                                      activeItemInOrder &&
                                                                            // handleOrderItemQty(
                                                                            //   "+"
                                                                            // );
                                                                            updateQuanity(orderListItem.quantity + 1, orderListItem, "add")
                                                                          }}
                                                                        >
                                                                          <i className="las la-plus"></i>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                    {/* Quantity */}

                                                                    {/* Price */}
                                                                    <div className="col-3 text-center border-right d-flex justify-content-center align-items-center">
                                                                      <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                                        {parseInt(
                                                                          orderListItem.item
                                                                            .has_variation
                                                                        ) === 1 ? (
                                                                          <>
                                                                            {currencySymbolLeft()}

                                                                            {showPriceOfEachOrderItem(
                                                                              orderListItemIndex
                                                                            )}
                                                                            {currencySymbolRight()}
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            {currencySymbolLeft()}
                                                                            {showPriceOfEachOrderItem(
                                                                              orderListItemIndex
                                                                            )}
                                                                            {currencySymbolRight()}
                                                                          </>
                                                                        )}
                                                                      </span>
                                                                    </div>
                                                                    {/* Price */}
                                                                  </div>
                                                                  <span className="text-capitalize t-pt-5 t-pb-5 t-pl-5 t-pr-5 sm-text font-weight-bold make-this-relative">
                                                                      <span
                                                                        className="text-capitalize"
                                                                        onClick={() => {
                                                                          handleRemoveItemFromOrderList(
                                                                            orderListItemIndex, orderListItem
                                                                          );
                                                                        }}
                                                                      >
                                                                        <i className="la la-times la-lg"></i>
                                                                      </span>
                                                                    </span>
                                                                </div>
                                                              </>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <div className="text-center d-flex flex-column gx-3 align-items-center justify-content-center h-100">
                                                          <img src="/assets/img/empty_cart.png" alt="" className="empty_cart_img img-fluid my-3"/>
                                                          {_t(
                                                            t(
                                                              "Select food item to add to the list"
                                                            )
                                                          )}
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="fk-price-table__body-bottom t-mt-10">
                                                <div className="fk-table__head">
                                                  <div className="row g-0 border_dashed_bottom border_dashed pb-2">
                                                    <div className="col-12 text-center">
                                                      <div className="d-flex align-items-center justify-content-between px-2">
                                                        <div className="">
                                                          <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                            {_t(t("sub total"))}
                                                          </span>
                                                        </div>
                                                        <div className="">
                                                          {newOrder ? (
                                                            <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                              {currencySymbolLeft()}
                                                              {formatPrice(theSubTotal)}
                                                              {currencySymbolRight()}
                                                            </span>
                                                          ) : (
                                                            <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                              {currencySymbolLeft()}
                                                              {formatPrice(0)}
                                                              {currencySymbolRight()}
                                                            </span>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                      <div className="d-flex align-items-center justify-content-between px-2">
                                                        <div className="">
                                                          <span className="xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                            {getSystemSettings(
                                                              generalSettings,
                                                              "vat_system"
                                                            ) === "igst"
                                                              ? `tax(${
                                                                  newSettings !== null &&
                                                                  newSettings.vat
                                                                })`
                                                              : getSystemSettings(
                                                                  generalSettings,
                                                                  "vat_system"
                                                                ) === "cgst"
                                                              ? "cgst+sgst" +
                                                                "(" +
                                                                getSystemSettings(
                                                                  generalSettings,
                                                                  "cgst"
                                                                ) +
                                                                "+" +
                                                                getSystemSettings(
                                                                  generalSettings,
                                                                  "sgst"
                                                                ) +
                                                                ")"
                                                              : `tax(${
                                                                  newSettings !== null &&
                                                                  newSettings.vat
                                                                })`}
                                                            %:
                                                          </span>
                                                        </div>
                                                        <div className="">
                                                          <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                            {currencySymbolLeft()}
                                                            {formatPrice(theVat)}
                                                            {currencySymbolRight()}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="col-12"
                                                      // style={{
                                                      //   textAlign: "start",
                                                      //   paddingRight: "50px",
                                                      // }}
                                                    >
                                                      {/* <div className="text-capitalize xsm-text d-flex align-items-center justify-content-between font-weight-bold px-2">
                                                        <span>
                                                        {_t(t("Department Tag Commission"))}
                                                        {orderDetails &&
                                                          orderDetails.dept_tag &&
                                                          " (" +
                                                            orderDetails.dept_tag
                                                              .commission +
                                                            "%" +
                                                            ")"}{" "}
                                                        :{" "}
                                                        </span>
                                                        {newOrder ? (
                                                          <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                            {currencySymbolLeft()}
                                                            {formatPrice(deptCommission)}
                                                            {currencySymbolRight()}
                                                          </span>
                                                        ) : (
                                                          <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                            {currencySymbolLeft()}
                                                            {formatPrice(0)}
                                                            {currencySymbolRight()}
                                                          </span>
                                                        )}
                                                      </div> */}
                                                      {/* {selectedTableData.advance &&  */}
                                                        <div className="text-capitalize xsm-text d-flex align-items-center justify-content-between font-weight-bold px-2">
                                                          <div>{_t(t("Advance Amount"))} :</div>
                                                          <div>₹{selectedTableData.advance ?? 0}</div>
                                                        </div>
                                                    </div>
                                                  </div>

                                                  {/* <div className="row g-0 border">
                                                    <div className="col-12 text-center border-right">
                                                      <div className="row g-0 justify-content-end">
                                                      </div>
                                                    </div>
                                                  </div> */}                                                
                                                    

                                                  {getSystemSettings(
                                                    generalSettings,
                                                    "sDiscount"
                                                  ) === "flat" && (
                                                    <div className="row g-0 p-2">
                                                      {/* <div className="d-none col-12 mb-2">
                                                        <div className="row g-0">
                                                          <div className="col-6">
                                                            <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                              {_t(t("service charge"))}
                                                            </span>
                                                          </div>
                                                          <div className="col-6">
                                                            <input
                                                              type="number"
                                                              step="0.01"
                                                              min="0"
                                                              className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5 form-control rounded-0 text-center"
                                                              onChange={(e) => {
                                                                if (e.target.value !== "") {
                                                                  setOrderDetails({
                                                                    ...orderDetails,
                                                                    serviceCharge: parseFloat(
                                                                      e.target.value
                                                                    ),
                                                                  });
                                                                  let totalPayable = 0;
                                                                  let localCurrency =
                                                                    JSON.parse(
                                                                      localStorage.getItem(
                                                                        "currency"
                                                                      )
                                                                    );
                                                                  let usdServiceCharge =
                                                                    parseFloat(e.target.value) /
                                                                    localCurrency.rate;
                                                                  let usdDiscount =
                                                                    parseFloat(
                                                                      orderDetails.discount
                                                                    ) / localCurrency.rate;

                                                                  totalPayable =
                                                                    theSubTotal +
                                                                    theVat +
                                                                    usdServiceCharge -
                                                                    usdDiscount;
                                                                  setTotalPaybale(totalPayable);
                                                                  setReturnMoneyUsd(
                                                                    totalPayable - paidMoney
                                                                  );
                                                                } else {
                                                                  setOrderDetails({
                                                                    ...orderDetails,
                                                                    serviceCharge: 0,
                                                                  });
                                                                  let totalPayable = 0;
                                                                  let localCurrency =
                                                                    JSON.parse(
                                                                      localStorage.getItem(
                                                                        "currency"
                                                                      )
                                                                    );
                                                                  let usdServiceCharge =
                                                                    parseFloat(0) /
                                                                    localCurrency.rate;
                                                                  let usdDiscount =
                                                                    parseFloat(
                                                                      orderDetails.discount
                                                                    ) / localCurrency.rate;

                                                                  totalPayable =
                                                                    theSubTotal +
                                                                    theVat +
                                                                    usdServiceCharge -
                                                                    usdDiscount;
                                                                  setTotalPaybale(totalPayable);
                                                                  setReturnMoneyUsd(
                                                                    totalPayable - paidMoney
                                                                  );
                                                                }
                                                              }}
                                                              value={
                                                                orderDetails.serviceCharge !==
                                                                  0 &&
                                                                orderDetails.serviceCharge
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div> */}
                                                      <div className="col-12 mb-2">
                                                        <div className="row g-0">
                                                          <div className="col-6">
                                                            <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                              {_t(t("discount"))}
                                                            </span>
                                                            
                                                          </div>
                                                          <div className="col-6">
                                                            <input
                                                              type="number"
                                                              step="0.01"
                                                              min="0"
                                                              className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5 form-control rounded-0 text-center"
                                                              onChange={(e) => {
                                                                if (e.target.value !== "") {
                                                                  setOrderDetails({
                                                                    ...orderDetails,
                                                                    discount: parseFloat(
                                                                      e.target.value
                                                                    ),
                                                                  });
                                                                  let totalPayable = 0;
                                                                  let localCurrency =
                                                                    JSON.parse(
                                                                      localStorage.getItem(
                                                                        "currency"
                                                                      )
                                                                    );
                                                                  let usdServiceCharge =
                                                                    parseFloat(
                                                                      orderDetails.serviceCharge
                                                                    ) / localCurrency.rate;
                                                                  let usdDiscount =
                                                                    parseFloat(e.target.value) /
                                                                    localCurrency.rate;

                                                                  totalPayable =
                                                                    theSubTotal +
                                                                    theVat +
                                                                    usdServiceCharge -
                                                                    usdDiscount;
                                                                  setTotalPaybale(totalPayable);
                                                                  setReturnMoneyUsd(
                                                                    totalPayable - paidMoney
                                                                  );
                                                                } else {
                                                                  setOrderDetails({
                                                                    ...orderDetails,
                                                                    discount: 0,
                                                                  });
                                                                  let totalPayable = 0;
                                                                  let localCurrency =
                                                                    JSON.parse(
                                                                      localStorage.getItem(
                                                                        "currency"
                                                                      )
                                                                    );
                                                                  let usdServiceCharge =
                                                                    parseFloat(
                                                                      orderDetails.serviceCharge
                                                                    ) / localCurrency.rate;
                                                                  let usdDiscount =
                                                                    parseFloat(0) /
                                                                    localCurrency.rate;

                                                                  totalPayable =
                                                                    theSubTotal +
                                                                    theVat +
                                                                    usdServiceCharge -
                                                                    usdDiscount;
                                                                  setTotalPaybale(totalPayable);
                                                                  setReturnMoneyUsd(
                                                                    totalPayable - paidMoney
                                                                  );
                                                                }
                                                              }}
                                                              value={
                                                                orderDetails.discount !== 0 &&
                                                                orderDetails.discount
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                  {getSystemSettings(
                                                    generalSettings,
                                                    "sDiscount"
                                                  ) === "percentage" && (
                                                    <div className="row g-0 p-2">
                                                      {/* <div className="col-12 mb-2">
                                                        <div className="row g-0">
                                                          <div className="col-6">
                                                            <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                              {_t(t("service charge"))} %
                                                            </span>
                                                          </div>
                                                          <div className="col-6">
                                                            <input
                                                              type="number"
                                                              step="0.01"
                                                              min="0"
                                                              className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5 form-control rounded-0 text-center"
                                                              onChange={(e) => {
                                                                if (e.target.value !== "") {
                                                                  setOrderDetails({
                                                                    ...orderDetails,
                                                                    serviceCharge: parseFloat(
                                                                      e.target.value
                                                                    ),
                                                                  });
                                                                  let totalPayable = 0;
                                                                  // let localCurrency = JSON.parse(
                                                                  //   localStorage.getItem(
                                                                  //     "currency"
                                                                  //   )
                                                                  // );
                                                                  // let usdServiceCharge =
                                                                  //   parseFloat(e.target.value) /
                                                                  //   localCurrency.rate;
                                                                  // console.log("coming");
                                                                  let usdServiceCharge =
                                                                    theSubTotal *
                                                                    (parseFloat(
                                                                      e.target.value
                                                                    ) /
                                                                      100);

                                                                  // let usdDiscount =
                                                                  //   parseFloat(
                                                                  //     orderDetails.discount
                                                                  //   ) / localCurrency.rate;

                                                                  let usdDiscount =
                                                                    theSubTotal *
                                                                    (parseFloat(
                                                                      orderDetails.discount
                                                                    ) /
                                                                      100);

                                                                  totalPayable =
                                                                    theSubTotal +
                                                                    theVat +
                                                                    usdServiceCharge -
                                                                    usdDiscount;
                                                                  setTotalPaybale(totalPayable);
                                                                } else {
                                                                  setOrderDetails({
                                                                    ...orderDetails,
                                                                    serviceCharge: 0,
                                                                  });
                                                                  let totalPayable = 0;
                                                                  // let localCurrency = JSON.parse(
                                                                  //   localStorage.getItem(
                                                                  //     "currency"
                                                                  //   )
                                                                  // );
                                                                  // let usdServiceCharge =
                                                                  //   parseFloat(0) /
                                                                  //   localCurrency.rate;
                                                                  let usdServiceCharge = 0;

                                                                  // let usdDiscount =
                                                                  //   parseFloat(
                                                                  //     orderDetails.discount
                                                                  //   ) / localCurrency.rate;

                                                                  let usdDiscount =
                                                                    theSubTotal *
                                                                    (parseFloat(
                                                                      orderDetails.discount
                                                                    ) /
                                                                      100);

                                                                  totalPayable =
                                                                    theSubTotal +
                                                                    theVat +
                                                                    usdServiceCharge -
                                                                    usdDiscount;
                                                                  setTotalPaybale(totalPayable);
                                                                }
                                                              }}
                                                              value={
                                                                orderDetails.serviceCharge !==
                                                                  0 &&
                                                                orderDetails.serviceCharge
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div> */}
                                                      <div className="col-12 mb-2">
                                                        <div className="row g-0">
                                                          <div className="col-6">
                                                            <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                              {_t(t("discount"))} 
                                                              <select className="rounded t-pt-5 t-pb-5 ml-2" value={orderDetails.discount_type} onChange={(e) => {
                                                                setDiscountType(e.target.value);
                                                                setOrderDetails({
                                                                  ...orderDetails,
                                                                  discount: 0,
                                                                  discount_type: e.target.value,
                                                                }); 
                                                                let totalPayable =
                                                                    theSubTotal +
                                                                    theVat;
                                                                  setTotalPaybale(totalPayable);
                                                                }}>
                                                              <option value="percentage">%</option>
                                                              <option value="flat">₹</option>
                                                            </select>
                                                            </span>
                                                          </div>
                                                          <div className="col-6 d-flex">
                                                            <input
                                                              type="number"
                                                              step="0.01"
                                                              min="0"
                                                              id="discountAmount12343425345345"
                                                              className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5 form-control rounded-0 text-center"
                                                              onChange={(e) => {
                                                                setBtnEnable(true);
                                                                if (e.target.value !== "") {
                                                                  setOrderDetails({
                                                                    ...orderDetails,
                                                                    discount: parseFloat(
                                                                      e.target.value
                                                                    ),
                                                                  });
                                                                  let totalPayable = 0;
                                                                  // let localCurrency = JSON.parse(
                                                                  //   localStorage.getItem(
                                                                  //     "currency"
                                                                  //   )
                                                                  // );
                                                                  // let usdServiceCharge =
                                                                  //   parseFloat(
                                                                  //     orderDetails.serviceCharge
                                                                  //   ) / localCurrency.rate;
                                                                  let usdServiceCharge =
                                                                    theSubTotal *
                                                                    (parseFloat(
                                                                      orderDetails.serviceCharge
                                                                    ) /
                                                                      100);

                                                                  // let usdDiscount =
                                                                  //   parseFloat(e.target.value) /
                                                                  //   localCurrency.rate;

                                                                  let usdDiscount = 0;
                                                                  if(discountType == "percentage") {
                                                                    usdDiscount = theSubTotal *
                                                                    (parseFloat(
                                                                      e.target.value
                                                                    ) /
                                                                      100);
                                                                    } else {
                                                                      usdDiscount = 
                                                                      parseFloat(
                                                                      e.target.value
                                                                    );
                                                                    }

                                                                  totalPayable =
                                                                    theSubTotal +
                                                                    theVat +
                                                                    usdServiceCharge -
                                                                    usdDiscount - tableKaData;
                                                                  setTotalPaybale(Math.round(totalPayable));
                                                                } else {
                                                                  setOrderDetails({
                                                                    ...orderDetails,
                                                                    discount: 0,
                                                                  });
                                                                  let totalPayable = 0;
                                                                  // let localCurrency = JSON.parse(
                                                                  //   localStorage.getItem(
                                                                  //     "currency"
                                                                  //   )
                                                                  // );

                                                                  let usdServiceCharge =
                                                                    theSubTotal *
                                                                    (parseFloat(
                                                                      orderDetails.serviceCharge
                                                                    ) /
                                                                      100);

                                                                  let usdDiscount = 0;

                                                                  totalPayable =
                                                                    theSubTotal +
                                                                    theVat +
                                                                    usdServiceCharge -
                                                                    usdDiscount - tableKaData;
                                                                  setTotalPaybale(totalPayable);
                                                                }
                                                              }}
                                                              value={
                                                                orderDetails.discount !== 0 &&
                                                                orderDetails.discount
                                                              }
                                                            />
                                                            {btnEnable &&
                                                            <button className="btn btn-sm btn-danger pl-2" onClick={() => {
                                                              addDiscount(selectedTableData.id);
                                                              setBtnEnable(false);
                                                            }}>Apply</button>}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="border_dashed_top bg-light t-pl-10 t-pr-10">
                                                  <div className="row">
                                                    <div className="col-6">
                                                      <span className="text-capitalize font-weight-bold d-block t-pt-8 t-pb-10">
                                                        {_t(t("total bill"))}
                                                      </span>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                      {totalPayable ? (
                                                        <span className="text-capitalize font-weight-bold d-block t-pt-8 t-pb-10">
                                                          {currencySymbolLeft()}
                                                          {formatPrice(totalPayable)}
                                                          {currencySymbolRight()}
                                                        </span>
                                                      ) : (
                                                        <span className="text-capitalize font-weight-bold d-block t-pt-8 t-pb-10">
                                                          {currencySymbolLeft()}
                                                          {formatPrice(0)}
                                                          {currencySymbolRight()}
                                                        </span>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div
                                                      className={`addons-list__item mx-1 payment-type-parent ${
                                                        orderDetails.payment_type !==
                                                          null && "mb-1"
                                                      }`}
                                                    >
                                                      <Select
                                                        options={
                                                          paymentTypeForSearch &&
                                                          paymentTypeForSearch
                                                        }
                                                        components={makeAnimated()}
                                                        getOptionLabel={(option) =>
                                                          option.name
                                                        }
                                                        getOptionValue={(option) =>
                                                          option.name
                                                        }
                                                        classNamePrefix="select"
                                                        className="xsm-text"
                                                        onChange={handleSetpaymentType}
                                                        maxMenuHeight="120px"
                                                        isMulti
                                                        backspaceRemovesValue={false}
                                                        clearIndicator={null}
                                                        placeholder={
                                                          _t(t("Payments")) + ".."
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6 payment-input-height overflow-auto">
                                                    {orderDetails.payment_type !==
                                                      null && (
                                                      // <div className="border mt-0 mb-2 change-background mx-1 rounded-lg">
                                                      //   <div className="xsm-text text-center text-white pt-1">
                                                      //     {_t(t("Amount"))}
                                                      //   </div>
                                                      <>
                                                        {orderDetails.payment_type.map(
                                                          (
                                                            eachPaymentType,
                                                            paymentTypeIndex
                                                          ) => {
                                                            return (
                                                              <div className="addons-list__item mx-1 mb-1">
                                                                <input
                                                                  type="number"
                                                                  min="0"
                                                                  step="0.01"
                                                                  name={
                                                                    eachPaymentType.id
                                                                  }
                                                                  autoComplete="off"
                                                                  className="form-control xsm-text pl-2"
                                                                  onChange={
                                                                    handlePaymentTypeAmount
                                                                  }
                                                                  placeholder={
                                                                    eachPaymentType.name
                                                                  }
                                                                  value={
                                                                    orderDetails.payment_amount &&
                                                                    orderDetails
                                                                      .payment_amount[
                                                                      eachPaymentType.id
                                                                    ]
                                                                  }
                                                                />
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </>
                                                      // </div>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="row g-0 align-items-center t-mt-30">
                                                  <div className="col-4">
                                                    <div className="t-mr-8">
                                                      <div className="fk-calculator-container">
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-secondary text-uppercase"
                                                          onClick={() =>
                                                            setCalculatorMenu(!calculatorMenu)
                                                          }
                                                        >
                                                          <i className="fa fa-calculator"></i>
                                                        </button>
                                                        <div
                                                          className={`calculator ${
                                                            calculatorMenu && "active"
                                                          }`}
                                                        >
                                                          <Calculator />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {getSystemSettings(
                                                    generalSettings,
                                                    "pos_screen"
                                                  ) === "0" && (
                                                    <div className="col-8">
                                                      <div className="d-flex justify-content-end align-items-center">
                                                        <div className="t-mr-8">
                                                          <button type="button"
                                                            onClick={() => {
                                                              console.log('newOrder sssssss');
                                                              console.log('newOrder', newOrder);
                                                              if (newOrder) {
                                                                handleOnlyPrint();
                                                                handleStatusChange();
                                                              } else {
                                                                toast.error(
                                                                  `${_t(
                                                                    t(
                                                                      "Please add items in order list to print bill"
                                                                    )
                                                                  )}`,
                                                                  {
                                                                    position: "bottom-center",
                                                                    closeButton: false,
                                                                    autoClose: 10000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    className:
                                                                      "text-center toast-notification",
                                                                  }
                                                                );
                                                              }
                                                            }}
                                                            className="t-heading-font btn btn-outline-secondary font-weight-bold text-uppercase sm-text mr-2"
                                                          >
                                                            {_t(t("print bill"))}
                                                          </button>
                                                          <button
                                                            type="button"
                                                            className="btn btn-dark sm-text text-uppercase font-weight-bold"
                                                            onClick={
                                                              !loading ? handleSettleOrder: ''
                                                            }
                                                          >
                                                            {!loading
                                                              ? _t(t("settle"))
                                                              : _t(t("Please wait"))}
                                                          </button>
                                                        </div>
                                                        {/* <div>
                                                          <button
                                                            type="button"
                                                            className="btn btn-dark sm-text text-uppercase font-weight-bold"
                                                            onClick={
                                                              !loading && handleSubmitOrder
                                                            }
                                                          >
                                                            {!loading
                                                              ? _t(t("submit"))
                                                              : _t(t("Please wait"))}
                                                          </button>
                                                        </div> */}
                                                        {/* stripe card */}
                                                        {showCardReaderBUtton == "YES" ? (
                                                          <div>
                                                            <button
                                                              type="button"
                                                              className="btn btn-info sm-text text-uppercase font-weight-bold ml-2"
                                                              onClick={
                                                                !loading &&
                                                                handleStripeCardPayment
                                                              }
                                                            >
                                                              {!loading
                                                                ? _t(t("card"))
                                                                : _t(t("Please wait"))}
                                                            </button>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {getSystemSettings(
                                                    generalSettings,
                                                    "pos_screen"
                                                  ) === "1" && (
                                                    <div className="col-8">
                                                      <div className="d-flex justify-content-end align-items-center">
                                                        <div className="t-mr-8">
                                                          <button
                                                            type="button"
                                                            className="btn btn-primary sm-text text-uppercase font-weight-bold"
                                                            onClick={handleSettleOrderButton}
                                                          >
                                                            {!loading
                                                              ? _t(t("settle"))
                                                              : _t(t("Please wait"))}
                                                          </button>
                                                        </div>
                                                        {/* <div>
                                                          <button
                                                            type="button"
                                                            className="btn btn-success sm-text text-uppercase font-weight-bold"
                                                            onClick={
                                                              !loading && handleSubmitOrder
                                                            }
                                                          >
                                                            {!loading
                                                              ? _t(t("submit"))
                                                              : _t(t("Please wait"))}
                                                          </button>
                                                        </div> */}
                                                        {/* stripe card */}
                                                        {showCardReaderBUtton == "YES" ? (
                                                          <div>
                                                            <button
                                                              type="button"
                                                              className="btn btn-info sm-text text-uppercase font-weight-bold ml-2"
                                                              onClick={
                                                                !loading &&
                                                                handleStripeCardPayment
                                                              }
                                                            >
                                                              {!loading
                                                                ? _t(t("card"))
                                                                : _t(t("Please wait"))}
                                                            </button>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                // <div className="">
                                //     {selectedTable.name}
                                // </div>
                                }
                            </div>
                        </div>
                    </div> : 
                    <div className="h-100 w-100 t-bg-white sm_shadow sm_card d-flex align-items-center justify-content-center">
                      <img src="/assets/img/preloader.gif" alt="" />
                    </div>
                    }
                    
                </div>
            </div>
        </main>
        </>
    )
}

export default ReservationTable;