import { BASE_URL } from "../../../../BaseUrl";
import { getCookie } from "../../../../functions/Functions";
import axios from "axios";


export const GET_ORDER_REPORTS_URL = `${BASE_URL}/settings/get-order-report`;
export const UPDATE_ORDER_PAYMENT_TYPE_URL = `${BASE_URL}/settings/update-order-payment-type`;

export function getOrderReports(obj) {
    return axios.get(GET_ORDER_REPORTS_URL+'?filterdate='+obj, {headers: { Authorization: `Bearer ${getCookie()}`}})
    .then((response => response.data))
}

export function updateOrderPaymentType(id, obj) {
    return axios.put(UPDATE_ORDER_PAYMENT_TYPE_URL+'/'+id, obj, {headers: { Authorization: `Bearer ${getCookie()}`}})
    .then((response => response.data))
}