import { BASE_URL } from "../../../../BaseUrl";
import { getCookie } from "../../../../functions/Functions";
import axios from "axios";


export const NEW_EXPENSE_URL = `${BASE_URL}/settings/new-expense`;
export const GET_EXPENSE_URL = `${BASE_URL}/settings/get-expense`;
export const UPDATE_EXPENSE_URL = `${BASE_URL}/settings/update-expense`;
export const DELETE_EXPENSE_URL = `${BASE_URL}/settings/delete-expense`;


export function saveExpense(obj) {
    return axios.post(NEW_EXPENSE_URL, obj, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}

export function getExpense() {
    return axios.get(GET_EXPENSE_URL, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}

export function updateExpense(obj) {
    return axios.post(UPDATE_EXPENSE_URL, obj, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}

export function deleteExpense(obj) {
    return axios.get(DELETE_EXPENSE_URL+'/'+obj, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}