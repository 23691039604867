import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
//functions
import {
    _t,
    getCookie,
    pageLoading
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL, demo } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GoogleRecapture = () => {

    const { t } = useTranslation();
    const history = useHistory();


    // hooks
    const [googleDetails, setgoogleDetails] = useState({
        siteKey: null,
        secretKey: null,
    });

    // loading
    const [loading, setloading] = useState(true);

    // checkbox state
    const [isChecked, setIsChecked] = useState(false);

    // get stripe info
    const getgoogleInfo = () => {
        setloading(true);
        const url = BASE_URL + `/settings/get-google-recapture-values`;
        axios.get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            setgoogleDetails({
                ...googleDetails,
                siteKey: res.data.siteKey,
                secretKey: res.data.secretKey,
            })

            if (res.data.show == "YES") {
                setIsChecked(true);
            } else {
                setIsChecked(false);
            }
            setloading(false);

        });
    }

    //useEffect == componentDidMount
    useEffect(() => {
        getgoogleInfo();
    }, []);

    // onchange
    const handleOnchange = (e) => {
        setgoogleDetails({ ...googleDetails, [e.target.name]: e.target.value });
    }

    // handle checkbox
    const handleCheckbox = (e) => {
        setIsChecked(e.target.checked);
    }

    // handle submit
    const handleSubmit = (e) => {
        e.preventDefault();

        if (demo == "YES") {
            toast.success(`${_t(t("Sorry,This is demo purpose only"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
        } else {

            const url = BASE_URL + `/settings/setup-google-recapture`;
            let data = {
                'siteKey': googleDetails.siteKey,
                "secretKey": googleDetails.secretKey,
                "show": isChecked
            }
            // let formData = new FormData();
            // formData.append("siteKey", googleDetails.siteKey);
            // formData.append("secretKey", googleDetails.secretKey);
            // formData.append("show", isChecked);
            return axios
                .post(url, data, {
                    headers: { Authorization: `Bearer ${getCookie()}` },
                })
                .then((res) => {
                    toast.success(`${_t(t("google recaptcha setup successfully"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                })
        }
    }

    return (
        <>
            <Helmet>
                <title>{_t(t("Stripe"))}</title>
            </Helmet>

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        {/* <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ManageSidebar />
                        </div> */}
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-12 col-xxl-12 t-mb-30 mb-lg-0">
                            <div className="t-bg-white p-3 p-md-3 sm_card sm_shadow">
                                <div>
                                    <h4 className="text-secodary my-4">Setup google recaptcha</h4>
                                </div>
                                <hr />

                                {loading == true ? pageLoading() :

                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="stripesiteKey" className="form-label">Site key</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="stripesiteKey"
                                                onChange={handleOnchange}
                                                name="siteKey"
                                                defaultValue={googleDetails.siteKey}
                                                placeholder="pk_test_51H2XGOKJQvOIJEOikQ62q1vuUW" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="stripesecretkey" className="form-label">Secret Key</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="stripesecretkey"
                                                onChange={handleOnchange}
                                                defaultValue={googleDetails.secretKey}
                                                name="secretKey" placeholder="sk_test_51H2XGOKJQvOIJEOiu" />
                                        </div>

                                        <div className="mb-3">
                                            <input type="checkbox"
                                                id="stripecheckbox"
                                                defaultValue={isChecked}
                                                onChange={handleCheckbox}
                                                checked={isChecked}
                                            />
                                            <label htmlFor="stripecheckbox" className="form-label ml-4">hide/show</label>
                                        </div>
                                        <div className="mb-3">
                                            <button className="btn btn-primary text-capitalize">setup</button>
                                        </div>
                                    </form>}

                            </div>


                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default GoogleRecapture;
