import { BASE_URL } from "../../../../BaseUrl";
import { getCookie } from "../../../../functions/Functions";
import axios from "axios";


export const GET_ORDER_VOID_REPORTS_URL = `${BASE_URL}/settings/get-order-void-report`;


export function getVoidOrderReports(obj) {
    return axios.get(GET_ORDER_VOID_REPORTS_URL+'?filterdate='+obj, {headers: { Authorization: `Bearer ${getCookie()}` }})
    .then((response => response.data))
}