import React, { useEffect, useState, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";

//jQuery initialization
import $ from "jquery";

//functions
import {
    _t,
    getCookie,
    modalLoading,
    getSystemSettings,
} from "../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

//context consumer
import { SettingsContext } from "../../contexts/Settings";
import { UserContext } from "../../contexts/User";
import { RestaurantContext } from "../../contexts/Restaurant";
import { FoodContext } from "../../contexts/Food";

const cookies = new Cookies();

const CheckOtp = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //getting context values here
    let {
        loading,
        setLoading,
        generalSettings,
    } = useContext(SettingsContext);





    //state hooks here
    const [otp, setOtp] = useState('');

    useEffect(() => {
        handleJquery();
        checkAuth();
    }, []);

    //jQuery
    const handleJquery = () => {
        //obj Image Animation
        var hoverLayer = $("body");
        var objImgOne = $(".fk-global-img__obj");

        //Animation Init
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 60;
            var valueY = (e.pageY * -1) / 80;
            if (objImgOne.length) {
                objImgOne.css({
                    transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
                });
            }
        });
    };

    //redirect if logged in
    const checkAuth = () => {
        getCookie() !== undefined && history.replace("/dashboard");
    };

    //set credentials here on input change
    const handleChange = (e) => {
        setOtp(e.target.value);
    };

    //try for login, submit credentials to server
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const url = BASE_URL + "/auth/check-register-otp";
        return axios
            .post(url, otp)
            .then((res) => {

                if (res.status == 200) {
                    toast.success(
                        `${_t(
                            t("Your registration has been successful, please login to continue")
                        )}`,
                        {
                            position: "bottom-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        }
                    );
                    history.push("/login");
                } else {
                    toast.warning(
                        `${_t(
                            t("OTP is wrong, please try again")
                        )}`,
                        {
                            position: "bottom-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        }
                    );
                }

            })
            .catch((error) => {
                console.log(error.response.data.errors);
                setLoading(false);
                if (error && error.response.data.errors) {
                    if (error.response.data.errors.phn_no) {
                        error.response.data.errors.phn_no.forEach((item) => {
                            if (item === "An user exists with this phone number") {
                                toast.error(
                                    `${_t(t("An user exists with this phone number"))}`,
                                    {
                                        position: "bottom-center",
                                        autoClose: 10000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        className: "text-center toast-notification",
                                    }
                                );
                            }
                        });
                    }
                    if (error.response.data.errors.email) {
                        error.response.data.errors.email.forEach((item) => {
                            if (item === "An user exists with this email") {
                                toast.error(`${_t(t("An user exists with this email"))}`, {
                                    position: "bottom-center",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    className: "text-center toast-notification",
                                });
                            }
                        });
                    }
                    if (error.response.data.errors.password) {
                        error.response.data.errors.password.forEach((item) => {
                            if (item === "Password confirmation does not match") {
                                toast.error(
                                    `${_t(t("Password confirmation does not match"))}`,
                                    {
                                        position: "bottom-center",
                                        autoClose: 10000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        className: "text-center toast-notification",
                                    }
                                );
                            }
                            if (item === "The password must be at least 6 characters.") {
                                toast.error(
                                    `${_t(t("The password must be at least 6 characters"))}`,
                                    {
                                        position: "bottom-center",
                                        autoClose: 10000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        className: "text-center toast-notification",
                                    }
                                );
                            }
                        });
                    }
                    if (error.response.data.errors.image) {
                        error.response.data.errors.image.forEach((item) => {
                            if (item === "Please select a valid image file") {
                                toast.error(`${_t(t("Please select a valid image file"))}`, {
                                    position: "bottom-center",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    className: "text-center toast-notification",
                                });
                            }
                            if (item === "Please select a file less than 5MB") {
                                toast.error(`${_t(t("Please select a file less than 5MB"))}`, {
                                    position: "bottom-center",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    className: "text-center toast-notification",
                                });
                            }
                        });
                    }
                }
            });
    };

    const style = {
        logo: {
            backgroundColor: "none",
            // generalSettings &&
            // getSystemSettings(generalSettings, "type_background"),
            backgroundImage:
                generalSettings &&
                `url(${getSystemSettings(generalSettings, "type_logo")})`,
        },
    };

    return (
        <>
            <Helmet>
                <title>{_t(t("Check otp"))}</title>
            </Helmet>
            <main>
                <div className="fk-global-access">
                    <div className="d-none d-lg-block">
                        <div className="fk-global-img text-center">
                            <img
                                src="/assets/img/verifiy-img.png"
                                alt="smokehub"
                                className="img-fluid mx-auto fk-global-img__is"
                            />
                            <img
                                src="/assets/img/obj-1.png"
                                alt="smokehub"
                                className="img-fluid fk-global-img__obj fk-global-img__obj-1"
                            />
                            <img
                                src="/assets/img/obj-8.png"
                                alt="smokehub"
                                className="img-fluid fk-global-img__obj fk-global-img__obj-2"
                            />
                            <img
                                src="/assets/img/obj-7.png"
                                alt="smokehub"
                                className="img-fluid fk-global-img__obj fk-global-img__obj-6"
                            />
                            <img
                                src="/assets/img/obj-9.png"
                                alt="smokehub"
                                className="img-fluid fk-global-img__obj fk-global-img__obj-8"
                            />
                        </div>
                    </div>
                    <div className="container my-md-auto">

                        <div className="row">
                            <div className="col-md-8 col-lg-6 col-xl-4 t-mt-50">
                                <div className="fk-global-form">
                                    <div key="loading">
                                        <>
                                            <h3 className="mt-0 text-capitalize font-weight-bold">
                                                {_t(t("Enter OTP"))}
                                            </h3>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-12 t-mb-15">
                                                        <input
                                                            onChange={handleChange}
                                                            type="text"
                                                            name="otp"
                                                            placeholder="ex: 4455"
                                                            required
                                                            autoComplete="off"
                                                            className="form-control border-0 rounded-1"
                                                        />
                                                    </div>



                                                    <div className="col-12">
                                                        <div className="d-flex align-items-center">
                                                            <div className="t-mr-8">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-success sm-text text-uppercase"
                                                                >
                                                                    {_t(t("verify"))}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                        </>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default CheckOtp;
