import React,{FC, useState, useEffect, useContext} from 'react'
import { getOrderReports, updateOrderPaymentType } from './_requests'
import { MdEdit, MdDelete } from "react-icons/md";
import { BiX } from "react-icons/bi";
import { _t } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { BsCreditCard } from "react-icons/bs";
import Select from "react-select";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import makeAnimated from "react-select/animated";

const OrderReports = () => {

  const { t } = useTranslation();
  const {
  paymentTypeForSearch
} = useContext(RestaurantContext);

  const[orderReports, setOrderReports] = useState({});
  const[paymentDetails, setPaymentDetails] = useState([]);
  const[orderReport, setOrderReport] = useState(null);
  const[payments, setPayments] = useState([]);
  const[id, setId] = useState('');
  const[currentDate, setCurrentDate] = useState('');
  const[date, setDate] = useState(null);

  console.log('orderReport3432423', orderReports)

  const CategoryList = async () => {
    
  }
  const searchDateOrders = async (date) => {
    // const cat = document.getElementById('category').value;
    // let body = {
    //   "category": cat
    // }
    setDate(date)
    const Data = await getOrderReports(date);
    if(Data != null) {
      setOrderReports(Data);
    }
    // const Data = await saveCategories(body);
    // if(Data != null) {
    //   document.getElementById('category').value='';
    //   setCategories(Data);
    // }
  }

  const handleReportEdit = async (id) => {
    const cash = document.getElementById('cash')?.value;
    const Card = document.getElementById('Card')?.value;
    const UPI = document.getElementById('UPI')?.value;
    const Company = document.getElementById('Company')?.value;
    let body = [
       {
        "payment_type": "cash",
        "payment_type_slug": '',
        "amount": cash,
    },
       {
        "payment_type": "Card",
        "payment_type_slug": '',
        "amount": Card,
    },
       {
        "payment_type": "UPI",
        "payment_type_slug": '',
        "amount": UPI,
    },
       {
        "payment_type": "Company",
        "payment_type_slug": '',
        "amount": Company,
    },
    ];
    // let body = [];
    // for(let i=0;i<orderReport?.bill_distribution?.length;i++) {
    //   let ob = {
    //     "payment_type": orderReport?.bill_distribution[i].payment_type,
    //     "payment_type_slug": orderReport?.bill_distribution[i].payment_type_slug,
    //     "amount": orderReport?.bill_distribution[i].payment_type == "cash" ? cash : orderReport?.bill_distribution[i].payment_type == "Card" ? Card : orderReport?.bill_distribution[i].payment_type == "UPI" ? UPI : Company,
    // }
    // body.push(ob);
    // }  

    let formdata = {
      "data": body
    }
    console.log('body', body)    
    const Data = await updateOrderPaymentType(id, formdata);
    if(Data != null) {
      document.getElementById('cash').value = '';
      document.getElementById('Card').value = '';
      document.getElementById('UPI').value = '';
      document.getElementById('Company').value = '';
      setPaymentDetails([]);
      searchDateOrders(currentDate);
    }    
  }

  const handleDelete = async () => {
    // const data = await deleteCategories(id)
    // if(data != null) {
    //   setCategories(data);
    // }
  }

  const editValues = async (data) => {
    document.getElementById("cash").value = data.bill_distribution[0]?.payment_type == "cash" ? data.bill_distribution[0]?.amount : data.bill_distribution[1]?.payment_type == "cash" ? data.bill_distribution[1]?.amount : data.bill_distribution[2]?.payment_type == "cash" ? data.bill_distribution[2]?.amount : data.bill_distribution[3]?.amount;
    document.getElementById("Card").value = data.bill_distribution[0]?.payment_type == "Card" ? data.bill_distribution[0]?.amount : data.bill_distribution[1]?.payment_type == "Card" ? data.bill_distribution[1]?.amount : data.bill_distribution[2]?.payment_type == "Card" ? data.bill_distribution[2]?.amount : data.bill_distribution[3]?.amount;
    document.getElementById("UPI").value = data.bill_distribution[0]?.payment_type == "UPI" ? data.bill_distribution[0]?.amount : data.bill_distribution[1]?.payment_type == "UPI" ? data.bill_distribution[1]?.amount : data.bill_distribution[2]?.payment_type == "UPI" ? data.bill_distribution[2]?.amount : data.bill_distribution[3]?.amount;
    document.getElementById("Company").value = data.bill_distribution[0]?.payment_type == "Company" ? data.bill_distribution[0]?.amount : data.bill_distribution[1]?.payment_type == "Company" ? data.bill_distribution[1]?.amount : data.bill_distribution[2]?.payment_type == "Company" ? data.bill_distribution[2]?.amount : data.bill_distribution[3]?.amount;
    // for(let i=0;i<data.bill_distribution?.length;i++) {      
    //   document.getElementById("cash").value = 1000;
    //   document.getElementById("Card").value = data.bill_distribution[i]?.payment_type == "Card" ? data.bill_distribution[i]?.amount : '';
    //   document.getElementById("UPI").value = data.bill_distribution[i]?.payment_type == "UPI" ? data.bill_distribution[i]?.amount : '';
    //   document.getElementById("Company").value = data.bill_distribution[i]?.payment_type == "Company" ? data.bill_distribution[i]?.amount : '';
    // }    
  }

  const handleSetpaymentType = (payment_type) => {
    setPaymentDetails(payment_type);
    console.log('payment_type', payment_type)

  };

  const handlePayment = async (e) => {
    let array = [];
    let tempPaymentAmount = {      
      [e.target.name]: e.target.value,
    };
    array.push(tempPaymentAmount);
    setPayments([...payments,array[array.length - 1]])
    console.log('payments', payments)
  }

  const reset = () => {
    setPayments([]);
    setPaymentDetails([]);
  }
 
    useEffect(() => {
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      let currentDate = `${year}-${month}-${day}`
      document.getElementById('orderReportDate').value = currentDate;
      searchDateOrders(currentDate);
      setCurrentDate(currentDate);
      setDate(currentDate);
    }, []);

    return (
    <div className="container mt-2">
      <div className='modal fade' id={'deleteCategory'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Confirmation</h3>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-dismiss='modal'>
                      <BiX size={20}/>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <p>Are you sure want to delete?</p>
                    <div className='d-flex align-items-center justify-content-end'>
                        <button className='btn btn-sm btn-secondary mt-3 mx-3' data-dismiss='modal'>
                            No
                        </button>
                        <button className='btn btn-sm text-primary mt-3' data-dismiss='modal' 
                        onClick={(e) => handleDelete()}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='modal fade' id={'updateCategory'} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Order Report Edit</h3>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={reset} data-dismiss='modal'>
                      <BiX size={20}/>
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                <div className='row'>
                  {/* <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 py-3'>
                    <input type="text" placeholder="Category..." id='categoryUpdate' className="form-control border-0 form-control--light-1 rounded-0"/>
                  </div> */}
                  <div className="row">
                    <label className='col-lg-6 text-muted pb-2'>Customer Name</label>
                    <div className='col-lg-6 pb-2'>
                      <span className='text-dark'>{orderReport?.customer_name}</span>
                    </div>
                    <label className='col-lg-6 text-muted pb-2'>Table Name</label>
                    <div className='col-lg-6 pb-2'>
                      <span className='text-dark'>{orderReport?.table_name}</span>
                    </div>
                    <label className='col-lg-6 text-muted pb-2'>Total Guest</label>
                    <div className='col-lg-6 pb-2'>
                      <span className='text-dark'>{orderReport?.total_guest}</span>
                    </div>
                    <label className='col-lg-6 text-muted pb-2'>Branch</label>
                    <div className='col-lg-6 pb-2'>
                      <span className='text-dark'>{orderReport?.theBranch.name}</span>
                    </div>
                    <label className='col-lg-6 text-muted pb-2'>Date & Time</label>
                    <div className='col-lg-6 pb-2'>
                      <span className='text-dark'>{moment(orderReport?.created_at).format('LL LT')}</span>
                    </div>
                    <label className='col-lg-6 text-muted pb-2'>Order Bill</label>
                    <div className='col-lg-6 pb-2'>
                      <span className='text-dark'>{orderReport?.order_bill}</span>
                    </div>
                    <label className='col-lg-6 text-muted pb-2'>Tax</label>
                    <div className='col-lg-6 pb-2'>
                      <span className='text-dark'>{orderReport?.vat}</span>
                    </div>
                    <label className='col-lg-6 text-muted pb-2'>Discount</label>
                    <div className='col-lg-6 pb-2'>
                      <span className='text-dark'>{orderReport?.discount}</span>
                    </div>
                    <label className='col-lg-6 text-muted pb-2'>Advance</label>
                    <div className='col-lg-6 pb-2'>
                      <span className='text-dark'>{orderReport?.advance?.advance}</span>
                    </div>
                    <label className='col-lg-6 text-muted pb-2'>Comments</label>
                    <div className='col-lg-6 pb-2'>
                      <span className='text-dark'>{orderReport?.advance?.comments}</span>
                    </div>
                    {/* {orderReport?.bill_distribution?.map(
                        (
                          eachPaymentType,
                          i
                        ) => {
                          return (<>
                    <label className='col-lg-6 text-muted pb-2'>{eachPaymentType.payment_type}</label>
                    <div className='col-lg-6 pb-2'>
                      <input
                        type="number" min="0" step="0.01"
                        className='form-control'
                        placeholder={eachPaymentType.payment_type+"..."}
                        name={eachPaymentType.payment_type}
                        id={"amount"+eachPaymentType.payment_type}
                      />
                    </div></>)})} */}
                    <label className='col-lg-6 text-muted pb-2'>Cash</label>
                    <div className='col-lg-6 pb-2'>
                    <input
                        type="number" min="0" step="0.01"
                        className='form-control'
                        placeholder="cash..."
                        name="Cash"
                        id="cash"
                      />
                    </div>
                    <label className='col-lg-6 text-muted pb-2'>Card</label>
                    <div className='col-lg-6 pb-2'>
                    <input
                        type="number" min="0" step="0.01"
                        className='form-control'
                        placeholder="card..."
                        name="Card"
                        id="Card"
                      />
                    </div>
                    <label className='col-lg-6 text-muted pb-2'>UPI</label>
                    <div className='col-lg-6 pb-2'>
                    <input
                        type="number" min="0" step="0.01"
                        className='form-control'
                        placeholder="UPI..."
                        name="UPI"
                        id="UPI"
                      />
                    </div>
                    <label className='col-lg-6 text-muted pb-2'>Company</label>
                    <div className='col-lg-6 pb-2'>
                    <input
                        type="number" min="0" step="0.01"
                        className='form-control'
                        placeholder="company..."
                        name="Company"
                        id="Company"
                      />
                    </div>
                    {/* <div className="col-md-6 pb-3 pt-3">
                      <div
                        className={`addons-list__item mx-1 payment-type-parent`}
                      >
                        <Select
                          options={
                            paymentTypeForSearch &&
                            paymentTypeForSearch
                          }
                          components={makeAnimated()}
                          getOptionLabel={(option) =>
                            option.name
                          }
                          getOptionValue={(option) =>
                            option.name
                          }
                          classNamePrefix="select"
                          className="xsm-text"
                          onChange={handleSetpaymentType}
                          maxMenuHeight="120px"
                          isMulti
                          backspaceRemovesValue={false}
                          clearIndicator={null}
                          placeholder={
                            _t(t("Payments")) + ".."
                          }
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-md-6 pb-3 pt-3">
                      {paymentDetails?.map(
                        (
                          eachPaymentType,
                          i
                        ) => {
                          return (
                            <div className="addons-list__item mx-1 mb-1" key={i}>
                              <input
                                type="number" min="0" step="0.01"
                                className='form-control'
                                placeholder={eachPaymentType.name+'...'}
                                name={eachPaymentType.name}
                                id={"amount" + eachPaymentType.name}
                              />
                            </div>
                          );
                        }
                      )}
                    </div> */}
                  </div>
                  <div className=''>
                    <button type='button' onClick={() => handleReportEdit(orderReport.id)} className='btn btn-transparent btn-primary sm-text text-uppercase w-100' data-dismiss='modal'>Save</button>
                  </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <form>
      <div className='w-100'>
        <div className='card t-bg-white sm_shadow sm_card expense_card overflow-hidden'>
          <div className='d-flex justify-content-between container pt-3'>
            <div className='d-flex align-items-center pb-0'>
              <ul className="t-list fk-breadcrumb">
                <li className="fk-breadcrumb__list">
                  <span className="t-link fk-breadcrumb__link text-capitalize pl-3">
                    {_t(t("Order Report"))}
                  </span>
                </li>
              </ul>
            </div>
            <div className='pb-0'>
                <div className='p-3 d-flex justify-content-end'>
                  <input type="date" id='orderReportDate' value={moment(date).format('YYYY-MM-DD')} onChange={(e) => searchDateOrders(e.target.value)} className="form-control"/>
                </div>
            </div>
          </div>
          {date != null &&
          <div className='card-body pt-0'>
          <div className='row'>
          <div className='col-md-3 mb-3'>
                <div className='card h-100'>
                  <div className='bg-success card-header border-0 fw-bold d-flex justify-content-center'>
                    <span className='p-2'>Total Sale</span>
                  </div>
                    <div className='card-body p-1 d-flex justify-content-center'>
                    <div class="d-flex align-items-center">
                      {/* <div class="symbol mr-3 p-2">
                        <img className='icon icon_1' src={"/assets/img/other-payment.png"} alt="" />
                      </div> */}
                      <div class="flex-grow-1">
                        {/* <a href="#" class="text-dark text-bold text-hover-primary fs-6">Total Amount</a> */}
                        <span class="p-4 text-muted d-block fw-bolderr">₹{orderReports.total?.other ?? '0'}</span>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3 mb-3'>
                <div className='card h-100'>
                  <div className='bg-warning card-header border-0 fw-bold d-flex justify-content-center'>
                    <span className='p-2'>Net Sale</span>
                  </div>
                    <div className='card-body p-1 fw-bold d-flex justify-content-center'>
                    <div class="d-flex align-items-center">
                      {/* <div class="symbol mr-3 p-2">
                        <img className='icon icon_1' src={"/assets/img/cash-icon.png"} alt="" />
                      </div> */}
                      <div class="flex-grow-1">
                        {/* <a href="#" class="text-dark text-bold text-hover-primary fs-6">Cash</a> */}
                        <span class="p-4 text-muted d-block fw-bolderr">₹{orderReports.total?.cash ?? '0'}</span>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3 mb-3'>
                <div className='card h-100'>
                  <div className='bg-danger card-header border-0 fw-bold d-flex justify-content-center'>
                    <span className='p-2'>Total Discount</span>
                  </div>
                    <div className='card-body p-1 fw-bold d-flex justify-content-center'>
                    <div class="d-flex align-items-center">
                      {/* <div class="symbol mr-3 p-2">
                        <img className='icon icon_1' src={"/assets/img/card.png"} alt="" />
                      </div> */}
                      <div class="flex-grow-1">
                        {/* <a href="#" class="text-dark text-bold text-hover-primary fs-6">Card</a> */}
                        <span class="p-4 text-muted d-block fw-bolderr">₹{orderReports.total?.Card ?? '0'}</span>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3 mb-3'>
                <div className='card h-100'>
                  <div className='bg-info card-header border-0 fw-bold d-flex justify-content-center'>
                    <span className='p-2'>Gross Sale</span>
                  </div>
                    <div className='card-body p-1 fw-bold d-flex justify-content-center'>
                    <div class="d-flex align-items-center">
                      {/* <div class="symbol mr-3 p-2">
                        <img className='icon icon_1' src={"/assets/img/UPI-icon.png"} alt="" />
                      </div> */}
                      <div class="flex-grow-1">
                        {/* <a href="#" class="text-dark text-bold text-hover-primary fs-6">UPI</a> */}
                        <span class="p-4 text-muted d-block fw-bolderr">₹{orderReports.total?.upi ?? '0'}</span>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3 mb-3'>
                <div className='card h-100'>
                    <div className='card-body p-1'>
                    <div class="d-flex align-items-center">
                      <div class="symbol mr-3 p-2">
                        <img className='icon icon_1' src={"/assets/img/other-payment.png"} alt="" />
                      </div>
                      <div class="flex-grow-1">
                        <a href="#" class="text-dark text-bold text-hover-primary fs-6">Total Amount</a>
                        <span class="text-muted d-block fw-bolder">₹{orderReports.total?.total ?? '0'}</span>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3 mb-3'>
                <div className='card h-100'>
                    <div className='card-body p-1'>
                    <div class="d-flex align-items-center">
                      <div class="symbol mr-3 p-2">
                        <img className='icon icon_1' src={"/assets/img/cash-icon.png"} alt="" />
                      </div>
                      <div class="flex-grow-1">
                        <a href="#" class="text-dark text-bold text-hover-primary fs-6">Cash</a>
                        <span class="text-muted d-block fw-bolder">₹{orderReports.total?.cash ?? '0'}</span>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3 mb-3'>
                <div className='card h-100'>
                    <div className='card-body p-1'>
                    <div class="d-flex align-items-center">
                      <div class="symbol mr-3 p-2">
                        <img className='icon icon_1' src={"/assets/img/card.png"} alt="" />
                      </div>
                      <div class="flex-grow-1">
                        <a href="#" class="text-dark text-bold text-hover-primary fs-6">Card</a>
                        <span class="text-muted d-block fw-bolder">₹{orderReports.total?.Card ?? '0'}</span>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3 mb-3'>
                <div className='card h-100'>
                    <div className='card-body p-1'>
                    <div class="d-flex align-items-center">
                      <div class="symbol mr-3 p-2">
                        <img className='icon icon_1' src={"/assets/img/UPI-icon.png"} alt="" />
                      </div>
                      <div class="flex-grow-1">
                        <a href="#" class="text-dark text-bold text-hover-primary fs-6">UPI</a>
                        <span class="text-muted d-block fw-bolder">₹{orderReports.total?.UPI ?? '0'}</span>
                      </div>
                    </div>
                    </div>
                </div>
            </div>                     
        </div>
            <div className=" overflow-auto">
              <div>
              <table className='table table-bordered table-hover mt-4'> 
              <thead className="align-middle">
                <tr>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">No.</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Customer Name</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Branch</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Table</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Order Bill</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Tax</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Advance</th>
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Total Bill</th>
                  {date == currentDate &&
                  <th scope="col" className="sm-text align-middle text-center border-1 border">Actions</th>}
                </tr>
              </thead>
              <tbody className="align-middle">
                {orderReports.details?.map((cate, i) => {
                  return(
                <>                
                <tr>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{i+1}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.customer_name}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.theBranch?.name}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.table_name}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.order_bill - cate.vat}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.vat}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.advance?.advance}</td>
                  <td scope="col" className="sm-text align-middle text-center border-1 border">{cate.order_bill}</td>
                  {date == currentDate &&
                  <td scope="col" className="sm-text align-middle text-center border-1 border">
                  <button 
                    data-toggle="modal"
                    data-target={"#updateCategory"}
                    type="button" className="btn btn-sm btn-outline-primary border-0 mx-1"
                    onClick={() => {
                    setOrderReport(cate);
                    editValues(cate);
                    // document.getElementById('categoryUpdate').value = cate.category;
                    }}>
                      <MdEdit size={20}/>
                    </button>
                    {/* <button 
                    data-toggle="modal"
                    data-target={"#deleteCategory"}
                    type="button" className="btn btn-sm btn-outline-danger border-0 mx-1"
                    onClick={() => {}
                      // () => setId(cate.id)
                      }>
                      <MdDelete size={20}/>
                    </button> */}
                  </td>}
                </tr></>)})}
              </tbody>
              </table>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </form>
    </div>
    )
}

export default OrderReports

